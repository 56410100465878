import React from 'react'

function Iosapp_open() {
    const openApp = () => {
        const appURL = 'IDNect://'; // ここにiOSアプリのカスタムURLスキームを入力
        window.location.href = appURL;
      };
    
      return (
        <button onClick={openApp}>アプリを開く</button>
      );
    };

export default Iosapp_open;