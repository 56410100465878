// import React from 'react'
// import rogo from '../IDnest.jpeg'

// import { Link } from 'react-router-dom'
// import '../Newcss/Landingpage.css';

// import land1 from '../../img/land_1.png';
// import land2 from '../../img/land_2.png';
// import land3 from '../../img/land_3.png';
// import { useTranslation } from 'react-i18next';




// function Landingpage() {
//   const { t,i18n } = useTranslation();

//   return (
//     <>
//     <div id="header">
//         <div className='login_header_buttons'>
//         <Link to='/'><img className='profile_rogo' src={rogo}/></Link>
//         <h4 className='idnest'>IDNect</h4>
//         <Link to='/login'  className='nouser_button'>ログイン・アカウント作成</Link>
//         </div>
//     </div>
//     {/* 700以上 */}
//     <div className='Landing'>
//       <div>
//           <div className='Landing_text'>
//               <h2>ゲームIDの管理・共有・検索を簡単に</h2>
//           </div>
//           <div className='land'>
//             <div className='landtext'>
//               <h3>プレイしているゲームを管理する</h3>
//               <p>10000個以上のゲームを登録可能。登録することで、データが消えた時など、IDが必要な際にも安心。</p>
//             </div>
//             <img className='land1_img' src={land1}/>
//           </div>
//           <div className='land'>
//           <img className='' src={land2}/>

//           <div className='landtext'>
//             <h3>ゲームIDの共有</h3>
//               <p>フレンドの登録ゲーム・IDを確認可能。IDを聞く・教えるの時間を短縮。自分のURLを教えて共有も可能。</p>
//             </div>
//           </div>
//           <div className='land land3'>
//           <div className='landtext'>
//             <h3>ゲームIDで人を探す</h3>
//               <p>ゲームごとでの検索が可能。ゲーム上の名前やIDだけで人を見つけられる。</p>
//               <p>（連絡するには相手がSNSを登録している必要があります。）</p>
//             </div>
//             <img className='' src={land3}/>
//           </div>
//       </div>
//     </div>
//     <div class="land_footer">
//           <div className='land_language'>
//             <a></a>
//             <div>
//               <a className='language_select' onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}>English</a>
//               <a className='language_select' onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}>日本語</a>
//             </div>
//             <a></a>
//           </div>
//           <div>
//             <ul class="land_footer-links">
//               <div>
//                 <li>お問い合わせ</li>
//                 <li><Link to='/tos'>利用規約</Link></li>
//                 <li><Link to='/pp'>プライバシーポリシー</Link></li>
//               </div>
//             </ul>
//           </div>
//     </div>
//   </>
//   )
// }

// export default Landingpage

import React from 'react';
import { Header } from "./components_landingpage/Header"
import { Hero } from './components_landingpage/Hero';
import { Features } from './components_landingpage/Features';
import { CTA } from './components_landingpage/CTA';
import { Footer } from './components_landingpage/Footer';
import Imiage_loop from './components_landingpage/Image_loop';
import How_to_use from './components_landingpage/How_to_use';

function Landingpage() {
  return (
    
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
        <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>IDNect</title>
    
    <script src="https://cdn.tailwindcss.com"></script>

  </head>
      <Header />
      <Hero />
      <Imiage_loop/>
      <Features />
      <How_to_use/>
      <CTA />
      <Footer />

{/* <div className="w-[2850px] h-[1700px] bg-gray-900 text-white p-8">
      <div className="flex flex-col items-start">
        <h1 className="text-4xl font-bold">Hark</h1>
        <div className="flex items-center space-x-4">
          <p className="text-xl">#VRRPG0LV</p>
          <p className="text-xl">Supercell ID: FranticHark</p>
        </div>
        <p className="text-lg">クラブ名: パムおば教</p>
      </div>

      <div className="grid grid-cols-3 gap-8 mt-8">
        <div>
          <p>Ranked: 現在 43000 / 最高 45545</p>
          <p>トロフィー: 現在 43000 / 最高 45545</p>
        </div>
        <div>
          <p>平均トロフィー: 700</p>
          <p>平均レベル: 9.7</p>
          <p>解放キャラ数: 80 / 87</p>
        </div>
        <div>
          <p>3vs3 勝利数: 1200</p>
          <p>ソロバトル 勝利数: 800</p>
          <p>ディオ 勝利数: 600</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 mt-8">
        <p>ガジェット所有数: 80 / 87</p>
        <p>スターパワー所有数: 80 / 87</p>
      </div>

      <div className="grid grid-cols-10 gap-0 mt-8">
        {sampleCharacters.map((char) => (
          <div
            key={char.id}
            className="bg-black w-[273px] h-[154px] relative flex items-center justify-start"
          >
            <img
              src={char.image}
              alt={char.name}
              className="w-[197px] h-[154px] object-cover"
            />
            <img
              src={char.tierImage}
              alt="Tier"
              className="absolute top-0 right-0 w-[77px] h-[77px]"
            />
            <div className="absolute bottom-0 left-0 w-[33px] h-[33px] bg-pink-500 flex items-center justify-center text-white text-sm font-bold">
              {char.level}
            </div>
            <div className="absolute bottom-4 right-4 text-center text-white text-sm">
              <p>{char.trophies}</p>
              <p>{`Max: ${char.maxTrophies}`}</p>
            </div>
          </div>
        ))}
      </div>
    </div> */}
    </div>
  );
}

export default Landingpage;

// const sampleCharacters = Array.from({ length: 87 }, (_, i) => ({
//   id: i + 1,
//   name: `キャラ${i + 1}`,
//   image: "https://via.placeholder.com/197x154",
//   tierImage: "https://via.placeholder.com/77",
//   level: Math.floor(Math.random() * 11) + 1,
//   trophies: Math.floor(Math.random() * 600),
//   maxTrophies: Math.floor(Math.random() * 800),
// }));

