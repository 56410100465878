import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import image from '../../../img/31348857_s.jpg'
import { ReactComponent as Apple_download } from '../../../img/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import googleplay_download from "../../../img/google_play.jpeg";




export function Hero() {
    const { t,i18n } = useTranslation();
  
  return (
    // <div className="relative">
    //   {/* Background Image with Overlay */}
    //   <div 
    //     className="absolute inset-0 z-0"
    //     style={{
    //       backgroundImage: `url(../../../img/スクリーンショット 2025-02-20 154555.png)`,
    //       backgroundSize: 'cover',
    //       backgroundPosition: 'center',
    //     }}
    //   >
    //     <div className="absolute inset-0 bg-black/40"></div>
    //   </div>

    //   {/* Content */}
    //   <div className="relative z-10">
    //     <section className="container mx-auto px-6 py-20 text-center">
    //       <h1 className="text-5xl font-bold text-white mb-6">
    //         {t("smart_management")}
    //       </h1>
          
    //       <p className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto">
    //         {t("description")}
    //       </p>
          
    //       <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 items-center">
    //         <Link to="/login">
    //           <button className="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition flex items-center justify-center">
    //             {t("start_free")}
    //             <ArrowRight className="ml-2 h-5 w-5" />
    //           </button>
    //         </Link>
    //       </div>
    //     </section>
    //   </div>
    // </div>
    <div className="relative h-[650px]  mb-16 overflow-hidden">
    <img
      src={image}
      alt="高級車の洗車"
      className="w-[2000px] h-[800px] object-cover"
    />
          <div className="absolute inset-0 bg-black/30">
            <div className="container mx-auto px-4 h-full flex flex-col justify-center items-center text-white">
              <h3 className="text-5xl font-bold mb-6">
              {t("smart_management")}<br />
              </h3>

              <p className="text-xl mb-8">{t("description")}</p>
                    <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 items-center">
            <Link to="/login">
              <button className="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition flex items-center justify-center">
                {t("start_free")}
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8 text-center">
              <a href="https://apps.apple.com/jp/app/idnect/id6670595423?">
                <Apple_download style={{ width: '150px', height: 'auto' }} />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.IDNect.idnect">
                <img src={googleplay_download} style={{ width: '162px', height: 'auto' }} />
              </a>
            </div>
            </div>
          </div>
        </div>
    // <section className="container mx-auto px-6 py-20 text-center">
    //   <h1 className="text-5xl font-bold text-gray-900 mb-6">
    //     ゲームIDを<span className="text-indigo-600">スマートに</span>管理
    //   </h1>
    //   <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
    //     IDNectで、あなたのゲームIDをすべて一元管理。共有も検索も、かんたんに。
    //   </p>
    //   <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 items-center">
    //     <Link to='/login'>
    //       <button className="bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition flex items-center justify-center">
    //         無料で始める
    //         <ArrowRight className="ml-2 h-5 w-5" />
    //       </button>
    //     </Link>
    //   </div>
    // </section>
  );
}