import { GamepadIcon, Languages } from 'lucide-react';
import { Link } from 'react-router-dom'
import rogo from '../../../img/IDnest.jpeg'
import { useTranslation } from 'react-i18next';





export function Header() {
  const { t,i18n } = useTranslation();
  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'ja' : 'en'
    i18n.changeLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
  }
  
  return (
    // <header className="container mx-auto px-6 py-4">
    //   <nav className="flex items-center justify-between">
    //     <div className="flex items-center space-x-2">
    //       {/* <GamepadIcon className="h-8 w-8 text-indigo-600" /> */}
    //       <Link to='/'><img className='profile_rogo' src={rogo}/></Link>

    //       <span className="text-xl font-bold text-gray-900 md:text-2xl">IDNect</span>
    //       </div>
    //       <div className="flex items-center space-x-4 md:space-x-6">
    //         <a href="#features" className="text-sm text-gray-600 hover:text-indigo-600 md:text-base">機能</a>
    //         <a href="#about" className="text-sm text-gray-600 hover:text-indigo-600 md:text-base">概要</a>
    //         <Link to='/login'>
    //           <button className="bg-indigo-600 text-white text-sm px-3 py-1.5 rounded-lg hover:bg-indigo-700 transition md:text-base md:px-4 md:py-2">
    //             ログイン
    //           </button>
    //       </Link>
    //     </div>
    //   </nav>
    // </header>
    <header className="container mx-auto px-6 py-4">
  <nav className="flex items-center justify-between">
    <div className="flex items-center space-x-2">
      {/* <GamepadIcon className="h-8 w-8 text-indigo-600" /> */}
      <Link to="/"><img className="profile_rogo" src={rogo} /></Link>
      <span className="text-xl font-bold text-gray-900 md:text-2xl">IDNect</span>
    </div>
    <div className="flex items-center space-x-4 md:space-x-6">
      {/* <a href="#features" className="text-sm text-gray-600 hover:text-indigo-600 md:text-base">{t("features")}</a>
      <a href="#about" className="text-sm text-gray-600 hover:text-indigo-600 md:text-base">{t("overview")}</a> */}
      <button
          onClick={toggleLanguage}
          className="px-4 py-2 rounded-lg text-sm flex items-center gap-2 bg-white text-gray-700 hover:bg-gray-50 transition-all shadow-sm"
          aria-label="Toggle language"
        >
          <Languages size={20} />
          {i18n.language === 'en' ? '日本語' : 'English'}
        </button>
      <Link to="/login">
        <button className="bg-indigo-600 text-white text-sm px-3 py-1.5 rounded-lg hover:bg-indigo-700 transition md:text-base md:px-4 md:py-2">
          {t("login")}
        </button>
      </Link>
    </div>
  </nav>
</header>
  );
}