import { Database, Search, Share2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function Features() {
    const { t,i18n } = useTranslation();
  
  return (
    <section id="features" className="container mx-auto px-6 py-20">
  <h2 className="text-3xl font-bold text-center text-gray-900 mb-16">{t("main_features")}</h2>
  <div className="flex flex-col md:flex-row gap-8 md:gap-12 items-stretch">
    <div className="flex-1 bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition">
      <div className="bg-indigo-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
        <Database className="h-8 w-8 text-indigo-600" />
      </div>
      <h3 className="text-xl font-semibold mb-4">{t("easy_management")}</h3>
      <p className="text-gray-600">
        {t("easy_management_desc")}
      </p>
    </div>
    <div className="flex-1 bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition">
      <div className="bg-indigo-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
        <Share2 className="h-8 w-8 text-indigo-600" />
      </div>
      <h3 className="text-xl font-semibold mb-4">{t("easy_sharing")}</h3>
      <p className="text-gray-600">
        {t("easy_sharing_desc")}
      </p>
    </div>
    <div className="flex-1 bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition">
      <div className="bg-indigo-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
        <Search className="h-8 w-8 text-indigo-600" />
      </div>
      <h3 className="text-xl font-semibold mb-4">{t("smart_search")}</h3>
      <p className="text-gray-600">
        {t("smart_search_desc")}
      </p>
    </div>
  </div>
</section>
    // <section id="features" className="container mx-auto px-6 py-20">
    //   <h2 className="text-3xl font-bold text-center text-gray-900 mb-16">主な機能</h2>
    //   <div className="flex flex-col md:flex-row gap-8 md:gap-12 items-stretch">
    //     <div className="flex-1 bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition">
    //       <div className="bg-indigo-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
    //         <Database className="h-8 w-8 text-indigo-600" />
    //       </div>
    //       <h3 className="text-xl font-semibold mb-4">簡単管理</h3>
    //       <p className="text-gray-600">
    //         複数のゲームIDをひとつの場所で管理。ゲームごとに整理して、すっきり保存できます。
    //       </p>
    //     </div>
    //     <div className="flex-1 bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition">
    //       <div className="bg-indigo-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
    //         <Share2 className="h-8 w-8 text-indigo-600" />
    //       </div>
    //       <h3 className="text-xl font-semibold mb-4">かんたん共有</h3>
    //       <p className="text-gray-600">
    //         フレンドとのID共有がワンクリック。プロフィールページを作成して、必要なIDだけを公開できます。
    //       </p>
    //     </div>
    //     <div className="flex-1 bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition">
    //       <div className="bg-indigo-100 w-16 h-16 rounded-full flex items-center justify-center mb-6">
    //         <Search className="h-8 w-8 text-indigo-600" />
    //       </div>
    //       <h3 className="text-xl font-semibold mb-4">スマート検索</h3>
    //       <p className="text-gray-600">
    //         プレイヤーIDやプレイヤー名で検索。フレンドを見つけやすく、つながりやすく。
    //       </p>
    //     </div>
    //   </div>
    // </section>
  );
}