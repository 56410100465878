

import React from 'react';
import { Header } from "./components_landingpage/Header"
import { Hero } from './components_landingpage/Hero';
import { Features } from './components_landingpage/Features';
import { CTA } from './components_landingpage/CTA';
import { Footer } from './components_landingpage/Footer';

function Landingpage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
      <Header />
      <Hero />
      <Features />
      <CTA />
      <Footer />
    </div>
  );
}

export default Landingpage;