import React, { useState } from 'react'
import { Button, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

//icon
import EditIcon from '@mui/icons-material/Edit';
import { t } from 'i18next';
import { Pencil, X } from 'lucide-react';

//登録ゲーム編集

function Editbutton({ document_id,uid, profile_gamename, profile_gameusername, profile_gameuserid,gameimg,memo,closeDialog }) {
    const [open, setOpen] = React.useState(false);
    const [gamename,setgamename] = useState(profile_gamename);
    const [gameuserid,setgameuserid] = useState(profile_gameuserid);
    const [gameusername,setgameusername] = useState(profile_gameusername);
    const [gameusermemo,setgameusermemo] = useState(memo);


    const [user] = useAuthState(auth);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      closeDialog();
    };

    //データ更新===================================
  const handleUpdate = async(event) => {
    event.preventDefault();
    const PlayerGames_docRef = doc(db, "PlayerGames", document_id);


    const game_data = {
      'gamename':gamename,
      'gameusername':gameusername,
      'gameuserid':gameuserid,
      'uuid':user.uid,
      'memo':gameusermemo
    }

    await updateDoc((PlayerGames_docRef),game_data);

    setOpen(false);
    closeDialog();
  };

  return (
    // <React.Fragment>
    <>
                <button
                  onClick={handleClickOpen}
                  className="w-full flex items-center gap-2 px-4 py-2 text-left hover:bg-gray-100 rounded-lg"
                >
                  <Pencil className="w-5 h-5" />
                  <span>{t('edit_game')}</span>

                </button>
                            {open && (
                              <div 
                                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                                onClick={() => handleClose(false)}
                              >
                                <div 
                                  className="bg-white rounded-lg p-6 w-full max-w-md"
                                  onClick={e => e.stopPropagation()}
                                >
                                  <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl font-bold ">変更</h2>
                                    <button
                                      onClick={() => handleClose(false)}
                                      className="p-1 hover:bg-gray-100 rounded-full"
                                    >
                                      <X className="w-5 h-5" />
                                    </button>
                                  </div>
                                  <img
                                      src={gameimg}
                                      alt={gameimg}
                                      className="w-16 h-16 object-cover rounded-lg mb-2.5"
                                      />
                                                <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('gamename')}
                                  </label>
                                  <input
                                    type="text"
                                    value={gamename}
                                    onChange={(e) => setgamename(e.target.value)}
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                                    placeholder="ゲーム名を入力"
                                    disabled
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('username')}
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                                    placeholder="ゲームIDを入力"
                                    value={gameusername}
                                    onChange={(event) => setgameusername(event.target.value)}
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    ID
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg mb-2.5"
                                    placeholder="ゲームIDを入力"
                                    value={gameuserid}
                                    onChange={(event) => setgameuserid(event.target.value)}
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('memo')}
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg mb-2.5"
                                    placeholder="ゲームIDを入力"
                                    value={gameusermemo}
                                    onChange={(event) => setgameusermemo(event.target.value)}
                                  />
                                </div>
                                  <div className="flex justify-end gap-2 ">
                                    <button
                                      onClick={() => handleClose(false)}
                                      className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
                                    >
                                      キャンセル
                                    </button>
                                    <button
                                      onClick={(e) => {
                                        handleClose(false);
                                        handleUpdate(e)
                                      }}
                                      className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg"
                                    >
                                      変更
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

          {/* <EditIcon className='edit_icon' onClick={handleClickOpen} sx={{ padding: "3px 3px",margin: 0, }} /> */}
          
        {/* <form onSubmit={handleUpdate}>
        <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            handleClose();
            },
        }}
        >
        <DialogTitle className=''>{t('edit_game_title')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t('edit_game_title_text')}
            </DialogContentText>
            <img className='edit_delete_icon' alt='' src={gameimg}/>
            <TextField
            autoFocus
            disabled

            margin="dense"
            id="gamename"
            name="text"
            label={t('gametitle')}
            type="text"
            fullWidth
            variant="standard"
            value={gamename}
            onMouseDown={(event) => event.stopPropagation()}
            // onChange={(event) => setgamename(event.target.value)}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="username"
            name="text"
            label={t('username')}
            type="text"
            fullWidth
            variant="standard"
            value={gameusername} 
            onChange={(event) => setgameusername(event.target.value)}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="userid"
            name="text"
            label="ID"
            type="text"
            fullWidth
            variant="standard"
            value={gameuserid} 
            onChange={(event) => setgameuserid(event.target.value)}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="Memo"
            name="text"
            label="Memo"
            type="text"
            fullWidth
            variant="standard"
            value={gameusermemo} 
            onChange={(event) => setgameusermemo(event.target.value)}
            onMouseDown={(event) => event.stopPropagation()}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit'>{t('save')}</Button>
        </DialogActions>
        </Dialog>
        </form>
    </React.Fragment> */}
                                </>

  )
}

export default Editbutton;