import { arrayRemove, arrayUnion, doc, runTransaction, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { UserCheck, UserPlus, Users, UserX } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom'
import { db } from '../../firebase';

//tab
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import '../Newcss/NewHome.css'
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Sidebar } from './components_profile/Sidebar';
import { useNotification } from '../Notification';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function NewHome(
    {friend_user,
    follow_user,
    follower_user,
    followingImages,
    followerImages,
    friendimg,
    followinginfo,
    followerinfo,
    friendinfo,
    follow_follower_friend_ID,
    userImageUrl,
    imageKey,
    UserInfo}){
      const localuuid = localStorage.getItem('login');
      localStorage.setItem('page','home');
      const { showNotification } = useNotification();
      

      const navigate = useNavigate();
      //言語設定
    const { t } = useTranslation();
  
  
      if(localuuid === null){
        const remove_app = () => {
          navigate('/');
        }
        remove_app()
      }

      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
  //フォロー解除================================
  const cancel_following = async(event,another_uuid) => {
    event.preventDefault();
    //フォロー側（相手に通知＆自分のリストに）
    // if (follow_user.length === 0){
    const followRef = doc(db, "Friends", localuuid);
    const followerRef = doc(db, "Friends", another_uuid);
  
    try {
      await runTransaction(db, async (transaction) => {
        // followingリストにanother_uuidを追加
        transaction.update(followRef, {
          following: arrayRemove(another_uuid)
        });
  
        // followerリストにlocaluuidを追加
        transaction.update(followerRef, {
          follower: arrayRemove(localuuid)
        });
      });
  
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }

    };

  //フォロー======================================
  const following_action = async(event,another_uuid) =>{
    event.preventDefault();
    if (friendinfo.length >= 20){
      showNotification('フレンド上限に達しているため、この動作は無効になりました', 'error');
    }else{
          //フォロー側（相手に通知＆自分のリストに）
    const followRef = doc(db, "Friends", localuuid);
    await updateDoc(followRef, {
      follower: arrayRemove(another_uuid),
      friend: arrayUnion(another_uuid)
  });

    //フォロワー側
    const followerRef = doc(db, "Friends", another_uuid);
    await updateDoc(followerRef, {
      following: arrayRemove(localuuid),
      friend: arrayUnion(localuuid)
  });
  };

    }

const [activeTab, setActiveTab] = useState('friends');

const tabs = [
  { id: 'friends', label: `${t('friend')} ${friendinfo.length}/20`, icon: Users },
  { id: 'following', label: `${t('following_now')} ${followinginfo.length}/20`, icon: UserPlus },
  { id: 'followers', label: `${t('iam_followed')} ${followerinfo.length}/∞`, icon: UserCheck },
];

const mockData = {
  friends: [
    friendinfo,
  ],
  following: [
    followinginfo,
  ],
  followers: [
    followerinfo,
  ],
};

const mockData_img = {
  friends: [
    friendimg,
  ],
  following: [
    followingImages,
  ],
  followers: [
    followerImages,
  ],
};
const currentUsers = mockData[activeTab];
const currentUsers_img = mockData_img[activeTab];




return (
      <div className="min-h-screen bg-gray-100">
        <Sidebar />
        <main class="flex-1 md:ml-64 p-4 md:p-8 pb-20 md:pb-8">
        <div className="max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">{t('friend')}</h1>

<div className="flex border-b border-gray-200 mb-6">
  {tabs.map(({ id, label, icon: Icon }) => (
    <button
  key={id}
  onClick={() => setActiveTab(id)}
  className={`flex items-center px-6 py-3 border-b-2 font-medium text-sm ${ 
    activeTab === id
      ? 'border-indigo-500 text-indigo-600'
      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
  } text-xs sm:text-sm md:text-base`} // モバイルサイズでは小さく
>
  <Icon className="w-5 h-5 mr-2" />
  {label}
</button>


  ))}
</div>

<div className="bg-white rounded-lg shadow">
{currentUsers[0].length > 0 ? (
  <div>
  {currentUsers[0].map((user,index) => (
    <Link to={`/Profile/${user.uuid}`}>
    <div
      key={user.id}
      className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0"
    >

      <div className="flex items-center">
        <img
          src={currentUsers_img[0][index]}
          alt={user.name}
          className="w-12 h-12 rounded-full object-cover"
        />
        <div className="ml-4">
          <h3 className="font-medium text-gray-900 sm:truncate sm:max-w-xs">{user.Username}</h3>
          <p className="text-sm text-gray-500 sm:truncate sm:max-w-xs">@{user.UserID}</p>
        </div>
      </div>
      {activeTab === 'friends' ? (
        <button className="px-4 py-2 text-xs sm:text-sm font-medium text-indigo-600 hover:text-indigo-500 bg-indigo-50 hover:bg-indigo-100 rounded-lg transition-colors">
          {t('check_profile')}
        </button>

      ) : activeTab === 'following' ? (
        <button className="px-4 py-2 text-xs sm:text-sm font-medium text-indigo-600 hover:text-indigo-500 bg-indigo-50 hover:bg-indigo-100 rounded-lg transition-colors" 
        onClick={(event) => {
          event.stopPropagation();
          cancel_following(event,user.uuid)}
        }>
          {t('cancel_follow')}
        </button>
      ) : activeTab === 'followers' ? (
        <button className="px-4 py-2 text-xs sm:text-sm font-medium text-indigo-600 hover:text-indigo-500 bg-indigo-50 hover:bg-indigo-100 rounded-lg transition-colors"   
        onClick={(event) => {
          event.stopPropagation(); // イベントの伝播を停止
          following_action(event, user.uuid);
        }}>
          {t('do_follow')}
        </button>
      ) : null}

    </div>
    </Link>
  ))}
  </div>
):(
  <div className="text-center py-12 bg-white rounded-xl shadow-sm">
  <div className="text-gray-400 mb-4">
    <UserX className="h-12 w-12 mx-auto" />
  </div>
  <h2 className="text-xl font-semibold text-gray-900">{t('not_found_user')}</h2>
  <p className="mt-2 text-gray-600">
  </p>
</div>
)}
</div>
  </div>
  </main>
  </div>

);
};

export default NewHome