import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import {  collection, doc, getDocs, limit, query, updateDoc, where } from 'firebase/firestore';
import Cropimg from './crop_img';
import './edit_profile.css';


//sns
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord, faTwitch, faYoutube, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { Edit, IdCard, MessageCircleMore, X } from 'lucide-react';


function Editprofilebutton({imgurl,username,userid,introduction,updateState,sns_list,handleImageChange}) {
  const [open, setOpen] = React.useState(false);
  const [name,setname] = useState();
  const [userID,setuserid] = useState();
  const [Introduction,setIntroduction] = useState();
  const [sns,setsns] = useState();
  const [sameuser,set_sameuser] = useState();
  const [edit_profile_img,set_edit_profile_img] = useState();
  const [up_img,setup_img] = useState();


  const [check_userid,setcheck_userid] = useState();

  const [user] = useAuthState(auth);

  const { t} = useTranslation();




  //SNS
  const [x,setx] = useState();
  const [discord,setdiscord] = useState();
  const [twitch,settwitch] = useState();
  const [youtube,setyoutube] = useState();
  const [tiktok,settiktok] = useState();
  const [instagram,setinstagram] = useState();




  useEffect(() => {
    setname(username);
    setuserid(userid);
    setIntroduction(introduction);
    setsns(sns_list);
    setcheck_userid(userid);
    set_edit_profile_img(imgurl)

  },[username,userid,sns_list,imgurl,introduction])
  //dialogスクロール制御

  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  useEffect(() => {
    if (isEditModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // クリーンアップ関数
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isEditModalOpen]);
//画像変更

  useEffect(() => {
    try{
    if (sns){
      setx(sns['X'])
      setdiscord(sns['Discord'])
      setyoutube(sns['Youtube'])
      settwitch(sns['Twitch'])
      settiktok(sns['Tiktok'])
      setinstagram(sns['Instagram'])

    }
    }catch(error){
      console.log('error')
    }


  },[sns_list,sns])



  const handleClickOpen = () => {
    setIsEditModalOpen(true);
    setOpen(true);
  };

  const handleClose = () => {
    setIsEditModalOpen(false);
    setOpen(false);
  };

  //データ更新===================================
const handleUpdate = async(event) => {
  event.preventDefault();
  setIsEditModalOpen(false);

  if(userID !== check_userid){
    const check_id_q = query(collection(db, "UserInfo"), where("UserID", "==", userID), limit(1));
    const querySnapshot = await getDocs(check_id_q);
    
    for (const doc of querySnapshot.docs) {
      if (doc.exists()) {
        set_sameuser('このIDは使用されています');
        return;
      }
    }
  }else{
    set_sameuser('');
  }





  if (user){
    const UserInfo_docRef = doc(db, "UserInfo", user.uid);
    const SNS_docRef = doc(db, "SNS", user.uid);

    const user_data = {
      'Username':name,
      "Username_lower":name.toLowerCase(),
      // 'UserID':userID,
      "UserID_lower":userID.toLowerCase(),
      'introduction':Introduction,
      'uuid':user.uid,
    }

    const sns_data = {
      'Discord':discord,
      'Tiktok':tiktok,
      'Twitch':twitch,
      'X':x,
      'Youtube':youtube,
      'Instagram':instagram,
    }

    await updateDoc((UserInfo_docRef),user_data);
    await updateDoc((SNS_docRef),sns_data);

    if(up_img){
      const storage = getStorage();
      const storageRef = ref(storage, 'user_images/' + user.uid + '.jpg');
      
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, up_img).then((snapshot) => {
          handleImageChange()
      });
    }



    setOpen(false);
  };
  }



  return (
  // <React.Fragment>
  <>
        <button
          className="absolute top-4 right-4 px-4 py-2 bg-white/90 hover:bg-white text-gray-700 rounded-lg flex items-center gap-2 transition-colors"
          onClick={handleClickOpen}
        >
          <Edit className="w-4 h-4" />
          {t('edit_profile')}
        </button>
        {open && (
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                onClick={handleClose}
              >
                <div 
                  className="bg-white rounded-lg p-6 w-full max-w-md max-h-[85vh] overflow-y-auto"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{t('edit_profile')}</h2>
                    <button
                      onClick={handleClose}
                      className="p-1 hover:bg-gray-100 rounded-full"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                  <form  className="space-y-4">

              <div>
              <Cropimg className='edit_profile_crop_img' imgurl={edit_profile_img} updateState={updateState} handleImageChange={handleImageChange} set_edit_profile_img={set_edit_profile_img} setup_img={setup_img}/>

                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                    {t('name')}
                  </label>
                  <div className="relative">
                    <IdCard className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <input
                      type="text"
                      id="username"
                      value={name}
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={t('name')}
                      onChange={(event) => setname(event.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="introduction" className="block text-sm font-medium text-gray-700 mb-1">
                  {t('introduction')}
                  </label>
                  <div className="relative">
                    <MessageCircleMore className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <textarea
                      id="introduction"
                      value={Introduction}
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="introduction..."
                      onChange={(event) => setIntroduction(event.target.value)}
                      rows={3} // 初期表示の高さを調整
                    ></textarea>
                  </div>
                </div>
                
                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
                    SNS
                  </label>
                  <div className="relative">
                    {/* <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" /> */}
                    <FontAwesomeIcon className='r absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5' icon={faXTwitter} />

                    <input
                      type="text"
                      id="text"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={x}
                      onChange={(event) => setx(event.target.value)}
                      placeholder="https://x.com/"
                      />
                  </div>
                </div>

                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
                  </label>
                  <div className="relative">
                    {/* <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" /> */}
                    <FontAwesomeIcon className='r absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5' icon={faDiscord} />

                    <input
                      type="text"
                      id="text"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={discord}
                      onChange={(event) => setdiscord(event.target.value)}
                      placeholder="userID"

                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
                  </label>
                  <div className="relative">
                    {/* <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" /> */}
                    <FontAwesomeIcon className='r absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5' icon={faTwitch} />

                    <input
                      type="text"
                      id="text"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={twitch}
                      onChange={(event) => settwitch(event.target.value)}
                      placeholder="https://www.twitch.tv/"

                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
                  </label>
                  <div className="relative">
                    <FontAwesomeIcon className='r absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5' icon={faYoutube} />

                    <input
                      type="text"
                      id="text"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={youtube}
                      onChange={(event) => setyoutube(event.target.value)}
                      placeholder="https://www.youtube.com/channel/"

                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
                  </label>
                  <div className="relative">
                    <FontAwesomeIcon className='r absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5' icon={faTiktok} />

                    <input
                      type="text"
                      id="text"
                      required
                      placeholder="https://www.tiktok.com/"

                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={tiktok}
                      onChange={(event) => settiktok(event.target.value)}

                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="text" className="block text-sm font-medium text-gray-700 mb-1">
                  </label>
                  <div className="relative">
                    <FontAwesomeIcon className='r absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5' icon={faInstagram} />

                    <input
                      type="text"
                      id="text"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={instagram}
                      onChange={(event) => setinstagram(event.target.value)}
                      placeholder="https://www.instagram.com/"

                    />
                  </div>
                </div>
                

                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
                    onClick={handleUpdate}
                  >
                    {t('regist')}
                  </button>
                </div>
                </form>
                  </div>
                </div>
            )}
  </>
  )
}

export default Editprofilebutton;