import React from 'react'
import rogo from '../IDnest.jpeg'
import '../Newcss/Riyoukiyaku.css';
import { Link } from 'react-router-dom';
import { Languages, ScrollText } from 'lucide-react'
import { useTranslation } from 'react-i18next';
  
  function Riyoukiyaku() {

        const { t,i18n } = useTranslation();
        const toggleLanguage = () => {
          const newLanguage = i18n.language === 'en' ? 'ja' : 'en'
          i18n.changeLanguage(newLanguage)
          localStorage.setItem('language', newLanguage)
        }
    
        const language = localStorage.getItem('language')
    // return (
 
    // )
    return (
      <>
      {language === 'ja' ?(
        <div className="min-h-screen bg-gray-50">
        {/* ヘッダー */}
        <header className="bg-white shadow-sm">
                  <div className="max-w-4xl mx-auto px-4 py-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <Link to="/">
                          <img className="profile_rogo" src={rogo} alt="ロゴ" />
                        </Link>
                        <h1 className="text-2xl font-bold text-gray-900">利用規約</h1>
                      </div>
                      <button
                        onClick={toggleLanguage}
                        className="px-4 py-2 rounded-lg text-sm flex items-center gap-2 bg-white text-gray-700 hover:bg-gray-50 transition-all shadow-sm"
                        aria-label="Toggle language"
                      >
                        <Languages size={20} />
                        {language === 'en' ? '日本語' : 'English'}
                      </button>
                    </div>
                  </div>
                </header>
  
        {/* メインコンテンツ */}
        <main className="max-w-4xl mx-auto px-4 py-8">
          <div className="bg-white rounded-lg shadow-md p-8">
            <div className="space-y-8">
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">受け入れと同意</h2>
                <p className="text-gray-700 leading-relaxed">
                  IDNectアプリケーション（以下、「アプリ」と称します）をご利用いただく際には、以下の利用規約（以下、「規約」と称します）に同意する必要があります。規約は、IDNect（以下、「事業者」と称します）とユーザー（以下、「利用者」と称します）の間の法的な契約を構成します。アプリの利用を続けることで、利用者は規約に同意したものとみなされます。規約に同意できない場合、アプリの利用を中止してください。
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">アカウント</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    2.1 利用者は、アプリを利用するために、事業者が提供する登録プロセスを完了し、アカウントを作成する必要があります。アカウント情報は正確かつ最新のものでなければなりません。
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    2.2 利用者は、アカウント情報の機密性を維持し、第三者と共有しない責任を負います。アカウントが不正に利用された場合、利用者は直ちに事業者に通報しなければなりません。
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">アプリの利用</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    3.1 利用者は、アプリを利用する際に、全ての適用法令と規制に従わなければなりません。アプリの利用は、合法的な目的のみに制限されます。
                  </p>
                  <p className="text-gray-700 leading-relaxed">3.2 利用者は、アプリを以下の目的で利用してはなりません：</p>
                  <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                    <li>不正行為、スパム、詐欺行為、または他の利用者への迷惑行為</li>
                    <li>アプリのセキュリティや機能に対する不正アクセスまたは攻撃</li>
                    <li>事業者の著作権、商標、プライバシー権などの権利の侵害、スクレイピング等の大量のデータを取得する行為</li>
                  </ul>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">プライバシー</h2>
                <p className="text-gray-700 leading-relaxed">
                  4.1 事業者は、利用者のプライバシーを尊重し、プライバシーポリシーに従って利用者の個人情報を取り扱います。プライバシーポリシーについては、事業者のウェブサイトで確認できます。
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">サポートと連絡先</h2>
                <p className="text-gray-700 leading-relaxed">
                  5.1 利用者がアプリに関する質問、提案、または問題を報告したい場合、事業者のサポートチームにご連絡いただけます。連絡先情報は事業者ウェブサイトに掲載されています。
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">変更と解除</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    6.1 事業者は、規約を変更する権利を有します。変更内容は本ウェブサイトに掲示され、有効となる日付が明示されます。変更内容に同意できない場合、利用者はアプリの利用を中止するか、アカウントを削除できます。
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    6.2 事業者は、違反行為や規約に違反した利用者に対して、通告なしにアクセス権の停止やアカウントの解除を行う権利を有します。
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">免責事項</h2>
                <p className="text-gray-700 leading-relaxed">
                  7.1 アプリは明示または黙示を問わず、いかなる保証も行いません。事業者は、利用者がアプリを利用する際に、安全かつ効果的なサービス提供を目指し最大限の努力を行いますが、それに関して一切の保証を行いません。また、利用者はアプリを自己責任で利用するものとし、事業者は利用者の損害について一切の責任を負いません。なお、より安全で最適なサービスを提供するために、可能な限り利用者のデータを収集・分析します。
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">サブスクリプションと永久会員</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    8.1 サブスクリプションや永久会員の内容は、予告なく変更または終了する場合があります。これらの変更や終了に関して、利用者は異議を唱えることはできません。
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    8.2 サブスクリプションや永久会員の変更や終了に伴う返金には応じかねます。
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">その他の規定</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    9.1 本規約に関する一切の紛争は、日本国の法律に従って解決されます。
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    9.2 本規約のいずれかの規定が無効とされた場合、それ以外の規定は有効とし、継続して適用されます。
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    9.3 規約に関するすべての紛争の裁判管轄は、福岡地方裁判所とします。ただし、特定の状況により変更が必要な場合があります。その際は、事前に利用者に連絡し、合意を得るよう努めます。
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">お問い合わせ</h2>
                <p className="text-gray-700 leading-relaxed">
                  本利用規約に関するお問い合わせは、以下の連絡先にお願いいたします。
                </p>
                <p className="text-gray-700 leading-relaxed mt-2">
                  <a href="mailto:kosukesh34@gmail.com" className="text-blue-600 hover:underline">
                    kosukesh34@gmail.com
                  </a>
                </p>
              </section>
            </div>
  
            {/* 最終更新日 */}
            <div className="mt-12 pt-8 border-t border-gray-200">
              <p className="text-sm text-gray-500 text-right">
                最終更新日: 2025年1月10日
              </p>
            </div>
          </div>
        </main>
      </div>

      ):(
        <div className="min-h-screen bg-gray-50">
        {/* Header */}
        {/* <header className="bg-white shadow-sm">
          <div className="max-w-4xl mx-auto px-4 py-6">
            <div className="flex items-center gap-3">
              <Link to="/"><img className="profile_rogo" src={rogo} /></Link>
              <h1 className="text-2xl font-bold text-gray-900">Terms of Service</h1>
            </div>
          </div>
        </header> */}
                              <header className="bg-white shadow-sm">
                  <div className="max-w-4xl mx-auto px-4 py-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <Link to="/">
                          <img className="profile_rogo" src={rogo} alt="ロゴ" />
                        </Link>
                        <h1 className="text-2xl font-bold text-gray-900">Terms of Service</h1>
                      </div>
                      <button
                        onClick={toggleLanguage}
                        className="px-4 py-2 rounded-lg text-sm flex items-center gap-2 bg-white text-gray-700 hover:bg-gray-50 transition-all shadow-sm"
                        aria-label="Toggle language"
                      >
                        <Languages size={20} />
                        {language === 'en' ? '日本語' : 'English'}
                      </button>
                    </div>
                  </div>
                </header>
  
        {/* Main Content */}
        <main className="max-w-4xl mx-auto px-4 py-8">
          <div className="bg-white rounded-lg shadow-md p-8">
            <div className="space-y-8">
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Acceptance and Agreement</h2>
                <p className="text-gray-700 leading-relaxed">
                  By using the IDNect application (hereinafter referred to as "the App"), you must agree to the following terms of service (hereinafter referred to as "Terms"). These Terms constitute a legal agreement between IDNect (hereinafter referred to as "the Company") and the user (hereinafter referred to as "the User"). By continuing to use the App, the User is deemed to have agreed to the Terms. If you do not agree to the Terms, please discontinue use of the App.
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Account</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    2.1 The User must complete the registration process provided by the Company and create an account to use the App. The account information must be accurate and up-to-date.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    2.2 The User is responsible for maintaining the confidentiality of their account information and not sharing it with third parties. If the account is used fraudulently, the User must immediately notify the Company.
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Use of the App</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    3.1 The User must comply with all applicable laws and regulations when using the App. The use of the App is limited to legal purposes only.
                  </p>
                  <p className="text-gray-700 leading-relaxed">3.2 The User must not use the App for the following purposes:</p>
                  <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                    <li>Fraudulent activities, spamming, scams, or causing trouble to other users</li>
                    <li>Unauthorized access or attacks on the security or functionality of the App</li>
                    <li>Infringement of the Company’s copyright, trademarks, privacy rights, or scraping large amounts of data</li>
                  </ul>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Privacy</h2>
                <p className="text-gray-700 leading-relaxed">
                  4.1 The Company respects the User’s privacy and handles personal information in accordance with its privacy policy. The privacy policy can be reviewed on the Company’s website.
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Support and Contact</h2>
                <p className="text-gray-700 leading-relaxed">
                  5.1 If the User has questions, suggestions, or issues regarding the App, they may contact the Company’s support team. Contact information can be found on the Company’s website.
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Changes and Termination</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    6.1 The Company has the right to change the Terms. Changes will be posted on this website with the effective date clearly indicated. If the User does not agree with the changes, they may stop using the App or delete their account.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    6.2 The Company has the right to suspend access or terminate the account of users who violate the Terms, without prior notice.
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Disclaimer</h2>
                <p className="text-gray-700 leading-relaxed">
                  7.1 The App makes no warranties, either express or implied. The Company makes every effort to provide safe and effective service, but makes no guarantees in this regard. The User agrees to use the App at their own risk, and the Company is not responsible for any damage incurred by the User. In order to provide a safer and better service, the Company may collect and analyze the User’s data.
                </p>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Subscriptions and Lifetime Membership</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    8.1 The contents of subscriptions and lifetime memberships may change or be terminated without notice. The User cannot object to these changes or terminations.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    8.2 No refunds will be provided for changes or termination of subscriptions or lifetime memberships.
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Other Provisions</h2>
                <div className="space-y-4">
                  <p className="text-gray-700 leading-relaxed">
                    9.1 Any disputes regarding these Terms will be resolved in accordance with the laws of Japan.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    9.2 If any provision of these Terms is deemed invalid, the remaining provisions will remain valid and continue to apply.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    9.3 The court of jurisdiction for all disputes related to the Terms will be the Fukuoka District Court. However, if necessary, this may change depending on the specific circumstances. In such cases, the Company will attempt to contact the User in advance and seek their agreement.
                  </p>
                </div>
              </section>
  
              <section>
                <h2 className="text-xl font-bold text-gray-900 mb-4">Contact</h2>
                <p className="text-gray-700 leading-relaxed">
                  For inquiries regarding these Terms of Service, please contact us at the following:
                </p>
                <p className="text-gray-700 leading-relaxed mt-2">
                  <a href="mailto:kosukesh34@gmail.com" className="text-blue-600 hover:underline">
                    kosukesh34@gmail.com
                  </a>
                </p>
              </section>
            </div>
  
            {/* Last Updated */}
            <div className="mt-12 pt-8 border-t border-gray-200">
              <p className="text-sm text-gray-500 text-right">
                Last updated: January 10, 2025
              </p>
            </div>
          </div>
        </main>
      </div>
      )}
      </>
    )
  }
  
  export default Riyoukiyaku