import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react'
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { Mail, X } from 'lucide-react';
import { useNotification } from './Notification';

function Login_Reset_password() {
    const [open, setOpen] = useState(false);
    const [email, setemail] = useState();
    const [password, setpassword] = useState();
    const { showNotification } = useNotification();


    const [massage, setmassage] = useState();
    const { t,i18n } = useTranslation();






    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      const reset_password_send_mail = () => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
            // setmassage('パスワード変更用メールを送信しました。')
            showNotification('login_reset_pass_text', 'success');
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }


  return (
    <div>
            {/* <React.Fragment> */}
        <div className='forget_password'>

        <p className='forget_password_text' onClick={handleClickOpen}>
          
          <a href="#" className="text-indigo-600 hover:text-indigo-500">
          {t('forget_password')}
              </a>
          </p>
        </div>
        {open && (
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                onClick={handleClose}
              >
                <div 
                  className="bg-white rounded-lg p-6 w-full max-w-md"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{t('login_reset_pass')}</h2>
                    <button
                      onClick={handleClose}
                      className="p-1 hover:bg-gray-100 rounded-full"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                  <form  className="space-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      {t('mailaddress')}
                    </label>
                    <div className="relative">
                      <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                      <input
                        type="email"
                        id="email"
                        required
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="example@email.com"
                        onChange={(event) => setemail(event.target.value)}
                      />
                    </div>
                  </div>
                
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
                    onClick={reset_password_send_mail}
                  >
                    {t('send')}
                  </button>
                </div>
                </form>
                  </div>
                </div>
            )}
        {/* <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            // handleClose();
            },
        }}
        >
        <DialogTitle className='popname'>{t('forget_password')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t('required-fields')}
            </DialogContentText>

            <div className="w-[320px]">
        <h2 className="text-2xl font-bold mb-6 text-gray-800"></h2>
        <form  className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              メールアドレス
            </label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="email"
                id="email"
                required
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="example@email.com"
                onChange={(event) => setemail(event.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
            >
              {t('close')}
            </button>
            <button
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
              onClick={reset_password_send_mail}
            >
              {t('send')}
            </button>
          </div>
        </form>
      </div>
            {massage}

        </DialogContent>
        </Dialog>
    </React.Fragment> */}

    </div>
  )
}

export default Login_Reset_password