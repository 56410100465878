import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { deleteUser, sendPasswordResetEmail } from "firebase/auth";
//dialog

import { auth, db, storage } from '../../firebase';

import '../Newcss/NewSetting.css';

import { useTranslation } from 'react-i18next';
import { arrayRemove, collection, deleteDoc, doc, getDocs, limit, query, updateDoc, where } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { Helmet } from 'react-helmet-async';
import { 
  LogOut, 
  Globe2, 
  KeyRound, 
  UserCog, 
  Trash2,
  ChevronRight,
  X,
  LockKeyhole
} from 'lucide-react';
import { Sidebar } from './components_profile/Sidebar';



function NewSetting({
  userImageUrl,
  friend_user,
  follow_user,
  follower_user,
  UserInfo}) {

const [user] = useAuthState(auth);
const localuuid = localStorage.getItem('login')
localStorage.setItem('page','setting');
const [showLanguageDialog, setShowLanguageDialog] = useState(false);
const [showIdDialog, setShowIdDialog] = useState(false);
const [showDeleteDialog, setShowDeleteDialog] = useState(false);
const [showlogoutDialog, setlogoutDialog] = useState(false);
const [showrepasswordDialog, setrepasswordDialog] = useState(false);
const [hiddenDialog, sethiddenDialog] = useState(false);
const [isHidden, setIsHidden] = useState();

console.log(UserInfo[0])

  useEffect(() => {
    if(user){
      try{
        if (UserInfo){
          setIsHidden(UserInfo[0]['hidden'])
        }
        }catch(error){
          console.log('era-')
        }
    }
  },[user,UserInfo])




const [newId, setNewId] = useState('');
const [language, setLanguage] = useState('ja');
const [idError, setIdError] = useState('');
const { t,i18n } = useTranslation();

//設定一覧
// const validateId = (id) => {
//   if (id.length < 3) {
//     setIdError(t('change_error_text'));
//     return false;
//   }
//   if (!/^[a-zA-Z0-9]+$/.test(id)) {
//     setIdError(t('change_error_text2'));
//     return false;
//   }
//   setIdError('');
//   return true;
// };

const validateId = () => {
  if (userID.length < 3) {
    setIdError(t('change_error_text'));
    return false;
  }
  if (!/^[a-zA-Z0-9]+$/.test(userID)) {
    setIdError(t('change_error_text2'));
    return false;
  }
  setIdError('');
  return true;
};

const handleIdSubmit = () => {
  if (validateId(newId)) {
    setShowIdDialog(false);
    setNewId('');
  }
};

const closeIdDialog = () => {
  setShowIdDialog(false);
  setNewId('');
  setIdError('');
};

const settingsItems = [
  {
    icon: Globe2,
    title: t('language_settings'),
    description: t('language_settings_text'),
    action: () => setShowLanguageDialog(true),
  },
  {
    icon: KeyRound,
    title: t('resetting_password'),
    description: t('resetting_passwords_text'),
    action: (event) => {
      setrepasswordDialog(true);
    }
  },
  {
    icon: UserCog,
    title: t('changeid'),
    description: t('changeid_text'),
    action: () => setShowIdDialog(true),
  },
  {
    icon: UserCog,
    title: t('Profile_Public_Settings'),
    description: t('Profile_Public_Settings_text'),
    action: () => sethiddenDialog(true),
  },
  {
    icon: Trash2,
    title: t("delete_account"),
    description: t('delete_account_text2'),
    action: () => setShowDeleteDialog(true),
    danger: true,
  },

];

const handleLogout = () => {
};
//言語設定

// const auth = getAuth();
const navigate = useNavigate();
//dialog(再設定)==================
const [open, setOpen] = React.useState(false);



const handleClose = () => {
  setOpen(false);
};
//dialog(アカウント削除)
const [open_delete, setOpen_delete] = React.useState(false);

const handleClickOpen_delete = () => {
  setOpen_delete(true);
};

const handleClose_delete = () => {
  setOpen_delete(false);
};

if(! localuuid){
  const remove_app = () => {
    navigate('/');
  }
  remove_app()
}
//言語設定
const [open_language, setOpen_language] = React.useState(false);

const handleClickOpen_language = () => {
  setOpen_language(true);
};

const handleClose_language = () => {
  setOpen_language(false);
};
//ID変更＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
const [open_change_id, setOpen_change_id] = React.useState(false);
const [check_userid,setcheck_userid] = useState();
const [sameuser,set_sameuser] = useState();



const handleClickOpen_change_id = () => {
  setuserID(UserInfo[0].UserID); // 保存しない場合、元のUserIDに戻す

  setOpen_change_id(true);
};

const handleClose_change_id = () => {
  setOpen_change_id(false);

};
const [userID, setuserID] = useState();
const [error, setError] = useState('');
const hasError = error ? true : false;

useEffect(() => {
  try{
    setuserID(UserInfo[0].UserID)
    setcheck_userid(UserInfo[0].UserID)
  }catch(error){
    console.log(error)
  }

},[UserInfo])

const handleChange_userID = (e) => {
  const value = e.target.value;
  setuserID(value);

  if (value.length <= 2) {
    setError(t('3_character'));
  } else if (!/^[a-zA-Z0-9_]*$/.test(value)) {
    setError(t('sinigle_byte'));
  } else {
    setError('');
  }
};

const setting_changeID = async(event) => {
  event.preventDefault();
  if(userID !== check_userid){

    const check_id_q = query(collection(db, "UserInfo"), where("UserID_lower", "==", userID.toLowerCase()), limit(1));
    const querySnapshot = await getDocs(check_id_q);
    
    for (const doc of querySnapshot.docs) {
      if (doc.exists()) {
        set_sameuser(t('change_error_text3'));
        return;
      }
    }
  }else{
    set_sameuser('');
  }





  if (localuuid){
    const UserInfo_docRef = doc(db, "UserInfo", localuuid);

    const user_data = {
      'UserID':userID,
      "UserID_lower":userID.toLowerCase(),

    }

    await updateDoc((UserInfo_docRef),user_data);


    // handleClose_change_id()
    closeIdDialog()
  }}

//========================================
//公開範囲＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
const hidden_action = async(event) => {
  event.preventDefault();
    const Userinfo_docRef = doc(db, "UserInfo", user.uid);
    const hidden_data = {
      hidden:isHidden
    }
    await updateDoc((Userinfo_docRef),hidden_data);
}

//パスワード再設定
const reset_password_send_mail = async(event) => {

  event.preventDefault();
  sendPasswordResetEmail(auth, user.email)
  .then(() => {
    setOpen(true)
      // Password reset email sent!
      // ..
  })
  .catch((error) => {
      console.log(error.message)
  });
}
//＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
//アカウントを削除＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
const delete_user = async(event) => {
  event.preventDefault();
  //フォローしている人のフォロワーから削除＝＝＝＝＝＝＝＝＝＝＝＝
  try{
  for (const item of follow_user) {
      const followRef = doc(db, "Friends", item);
      await updateDoc(followRef, {
          follower: arrayRemove(localuuid),
      });
  }}catch(error){
  }
  //フォロワーのフォロー削除
  try{
    for (const item of follower_user) {
      const followerRef = doc(db, "Friends", item);
      await updateDoc(followerRef, {
        following: arrayRemove(localuuid),
      });
  }
  }catch(error){
    console.log(error)
  }
  //フレンドのフレンドから削除
  try{
    for (const item of friend_user) {
      const friendRef = doc(db, "Friends", item);
      await updateDoc(friendRef, {
        friend: arrayRemove(localuuid),
      });
  }
  }catch(error){
    console.log(error)
  }
  //自分のUserInfo削除===================================
  try{
    await deleteDoc(doc(db, "UserInfo", localuuid));
  }catch(error){
    console.log(error)
  }
  //SNSを削除=======================
  try{
    await deleteDoc(doc(db, "SNS", localuuid));
  }catch(error){
    console.log(error)
  }
  //PlayerGamse===========================
  try {
    const q = query(collection(db,'PlayerGames'),where('uuid', '==', localuuid));
    const querySnapshot = await getDocs(q);
    const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    console.log('delete all data');
  } catch (error) {
    console.error('Error deleting matching documents: ', error);
  }
  localStorage.clear(); // ローカルストレージを削除
  auth.signOut(); // サインアウト

  const storageRef = ref(storage, 'user_images/' + localuuid + '.jpg');

  deleteObject(storageRef).then(() => {
    console.log('delete img')
    // File deleted successfully
  }).catch((error) => {
    // Uh-oh, an error occurred!
    console.log('not delete img')
  });



  deleteUser(user)
  .then(() => {
    console.log("User deleted successfully.");
  })
  .catch((error) => {
    console.error("An error occurred while deleting the user:", error);
  });
}

//＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝


  return (
    <div className="min-h-screen bg-gray-100">
    <Sidebar />
    <main class="flex-1 md:ml-64 p-4 md:p-8 pb-20 md:pb-8">
    <div className="max-w-3xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">{t('setting')}</h1>
      
      <div className="bg-white rounded-lg shadow">
        {settingsItems.map((item, index) => {
          const Icon = item.icon;
          return (
            <div
              key={item.title}
              className={`flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50 transition-colors ${
                index !== settingsItems.length - 1 ? 'border-b border-gray-200' : ''
              }`}
              onClick={item.action}
            >
              <div className="flex items-center">
                <div className={`p-2 rounded-full ${
                  item.danger ? 'bg-red-100' : 'bg-indigo-100'
                }`}>
                  <Icon className={`w-5 h-5 ${
                    item.danger ? 'text-red-600' : 'text-indigo-600'
                  }`} />
                </div>
                <div className="ml-4">
                  <h3 className={`font-medium ${
                    item.danger ? 'text-red-600' : 'text-gray-900'
                  }`}>
                    {item.title}
                  </h3>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
              <ChevronRight className="w-5 h-5 text-gray-400" />
            </div>
          );
        })}
      </div>

      <button
          onClick={() => { 
            setlogoutDialog(true)

          }}
        className="mt-6 w-full flex items-center justify-center gap-2 px-4 py-3 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors"
      >
        <LogOut className="w-5 h-5" />
        <span className="font-medium">{t('sign_out')}</span>
      </button>

      {/* 言語設定ダイアログ */}
      {showLanguageDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => setShowLanguageDialog(false)}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{t('language_settings')}</h2>
              <button
                onClick={() => setShowLanguageDialog(false)}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-2">
              <button
                onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}
                className={`w-full p-3 text-left rounded-lg ${
                  localStorage.getItem('language') === 'ja' ? 'bg-indigo-50 text-indigo-600' : 'hover:bg-gray-50'
                }`}
              >
                日本語
              </button>
              <button
                onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}
                className={`w-full p-3 text-left rounded-lg ${
                  localStorage.getItem('language') === 'en' ? 'bg-indigo-50 text-indigo-600' : 'hover:bg-gray-50'
                }`}
              >
                English
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ID変更ダイアログ */}
      {showIdDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={closeIdDialog}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{t('changeid')}</h2>
              <button
                onClick={closeIdDialog}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label htmlFor="newId" className="block text-sm font-medium text-gray-700 mb-1">
                  {t('newid')}
                </label>
                <input
                  type="text"
                  id="newId"
                 label="ID"
                  value={userID}
                  onChange={handleChange_userID}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder={t('newid')}
                />

                {error && (
                  <p className="mt-1 text-sm text-red-600">{error}</p>
                )}
                <p>{sameuser}</p>

              </div>
              <div className="flex justify-end gap-2">
                <button
                  onClick={closeIdDialog}
                  className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
                >
                  {t('cancel')}
                </button>
                <button
                disabled={!!error}
                  onClick={setting_changeID}
                  className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg"
                >
                  {t('save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 公開範囲指定 */}
      {hiddenDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => sethiddenDialog(false)}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">{t('Profile_Public_Settings')}</h2>
              <button
                onClick={() => sethiddenDialog(false)}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="flex justify-between items-center mb-6">
              <p className="text-gray-700">{t('Profile_Public_Settings_text2')}</p>
              <button
                onClick={() => setIsHidden(!isHidden)}
                className={`relative w-12 h-6 rounded-full transition-colors duration-300 ml-4 ${
                  isHidden ? 'bg-blue-600' : 'bg-gray-300'
                }`}
                aria-label="Toggle visibility"
              >
                <span
                  className={`absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                    isHidden ? 'translate-x-6' : 'translate-x-0'
                  }`}
                />
                <span className="sr-only">{isHidden ? 'ON' : 'OFF'}</span>
              </button>
            </div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => sethiddenDialog(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
              >
                {t('cancel')}
              </button>
              <button
                onClick={(event) => {
                  hidden_action(event)
                  sethiddenDialog(false);
                }}
                className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg"
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* アカウント削除ダイアログ */}
      {showDeleteDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => setShowDeleteDialog(false)}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-red-600">{t('delete_account')}</h2>
              <button
                onClick={() => setShowDeleteDialog(false)}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-700 mb-6">{t('delete_account_text')}</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowDeleteDialog(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
              >
                t{'cancel'}
              </button>
              <button
                onClick={(e) => {
                  console.log('アカウント削除実行');
                  setShowDeleteDialog(false);
                  delete_user(e);
                }}
                className="px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg"
              >
                {t('delete')}
              </button>
            </div>
          </div>
        </div>
      )}
            {/* ログアウトダイアログ */}
            {showlogoutDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => setlogoutDialog(false)}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold ">{t('logout')}</h2>
              <button
                onClick={() => setlogoutDialog(false)}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-700 mb-6">{t('logout_message')}</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setlogoutDialog(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
              >
                {t('cancel')}
              </button>
              <button
                onClick={() => {
                  setlogoutDialog(false);
                  localStorage.clear(); // ローカルストレージを削除
                  auth.signOut(); // サインアウト
                }}
                className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg"
              >
                {t('yes')}
              </button>
            </div>
          </div>
        </div>
      )}
{/* パスワード変更 */}
{showrepasswordDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => setrepasswordDialog(false)}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold ">{t('resetting_password')}</h2>
              <button
                onClick={() => setrepasswordDialog(false)}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-700 mb-6">{t('send_mail')}</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setrepasswordDialog(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
              >
                {t('cancel')}
              </button>
              <button
                onClick={(event) => {
                  reset_password_send_mail(event);
                  setrepasswordDialog(false);
                }}
                className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg"
              >
                {t('yes')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    </main>
    </div>
  )
};

export default NewSetting;