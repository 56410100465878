import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { Cancel } from '@mui/icons-material'
import { Box, Slider, Typography } from '@mui/material'
import Cropper from 'react-easy-crop'
import getCroppedImg from './image_crop/utils/cropimage';

import Resizer from "react-image-file-resizer";

import React, { useState } from 'react'



function Cropimg({imgurl,updateState,set_edit_profile_img,setup_img }) {
    const [crop, setCrop] = useState({x:0, y:0})
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null);
    const [open, setOpen] = React.useState(false);




    const handleClickOpen = () => {
    setOpen(true);
    };

    const handleClose = () => {
    setOpen(false);
    };




    const cropComplete = (cropedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleInputFile = (event) => {
    const file = event.target.files[0];
    if (file) {
    const reader = new FileReader();
    reader.onload = () => {
        setSelectedImage(reader.result);
        handleClickOpen()
    };
    reader.readAsDataURL(file);
    }
    };

    const cropimage = async () => {
    try {
        //画像切り取り
        const { file } = await getCroppedImg(
        selectedImage,
        croppedAreaPixels,
        rotation
        );
        //画像サイズ変更
        const resized_img = resizeFile(file).then(resize => {
        // リサイズ後の画像データを使って行いたい処理をここに記述する
        setup_img(resize)
        // const storage = getStorage();
        // const storageRef = ref(storage, 'user_images/' + user.uid + '.jpg');
        
        // // 'file' comes from the Blob or File API
        // uploadBytes(storageRef, resize).then((snapshot) => {
        //     console.log('Uploaded a blob or file!');
        // });

        }).catch(error => {
        // エラーハンドリング
        console.error('画像のリサイズ中にエラーが発生しました:', error);
        });

        //firebaseに保存


        //fileからurlを作成
        const imageUrl = URL.createObjectURL(file);
        await set_edit_profile_img(imageUrl);
        localStorage.removeItem('sidebar_profile_img'); // 'yourItemKey'を削除



    } catch (error) {
        console.log(error)
    }
    handleClose();
    };
    //==========================================
    const zoomPercent = (value) => {
    return `${Math.round(value * 100)}`
    }
    //画像サイズ変更
    const resizeFile = (file) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        80,
        0,
        (uri) => {
        resolve(uri);
        },
        "file"
    );
    });
  return (
    <div>
    <React.Fragment>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">
            {"画像を編集"}
        </DialogTitle>
        <DialogContent dividers
        sx={{
            background:'#333',
            position:'relative',
            height:400,
            width:'auto',
            minWidth: {sm:500}
        }}>
        <Cropper
        image = {selectedImage}
        crop={crop}
        zoom={zoom}
        rotation={rotation}
        aspect={1}
        onZoomChange={setZoom}
        onRotationChange={setRotation}
        onCropChange={setCrop}
        onCropComplete={cropComplete}
        />
        </DialogContent>
        <DialogActions sx={{flexDirection:'column', mx:3,my:2}}>
        <Box sx={{width:'100%', mb:1}}>
            {/* <Typography>Zoom:{zoomPercent(zoom)}</Typography> */}
            <Typography></Typography>
            <Slider
            valueLabelDisplay='auto'
            valueLabelFormat={zoomPercent}
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            onChange={(e,zoom) => setZoom(zoom)}
            />
        </Box>

        {/* <Box sx={{width:'80%', mb:1}}>
            <Typography>Rotation:{rotation}</Typography>
            <Slider
            valueLabelDisplay='auto'
            min={0}
            max={360}
            value={rotation}
            onChange={(e,rotation) => setRotation(rotation)}
            />
        </Box> */}
        <Box
            sx={{
                display:'flex',
                gap:2,
                flexWrap: 'wrap'
            }}>
            <Button
            variant='outlined'
            starticon={<Cancel />}
            onClick={()=>handleClose(false)}
            >
                cancel
            </Button>
            <Button
            variant='outlined'
            // starticon={<CropIcon />}
            onClick={cropimage}
            >
                変更
            </Button>
        </Box>
        </DialogActions>
            </Dialog>
        </React.Fragment>
          {/* ====================================== */}


        <div className='change_img'>
          <label>
            <span className="filelabel" title="ファイルを選択">
              <img className='crop_img_img' alt='' src={imgurl} />
            </span>
              <input
              type="file"
              accept="image/jpeg, image/png"
              onChange={handleInputFile}
              hidden
              />          
          </label>
        </div>

        {/* <img id="cropped-image" src={resize_image} /> */}
    </div>
  )
}

export default Cropimg
