import React, { useState } from 'react';
import { Home, User, Bell, LogOut, Settings, Search, X } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import rogo from '../../../img/IDnest.jpeg'
import { auth } from '../../../firebase';
import { useTranslation } from 'react-i18next';
import { useAuthState } from 'react-firebase-hooks/auth';
import Adsence from '../../Adsence';


export function Sidebar() {
  const localuuid = localStorage.getItem('login');
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  const [showlogoutDialog, setlogoutDialog] = useState(false);
  const [user] = useAuthState(auth);


  const navigate = useNavigate();
      const { t,i18n } = useTranslation();

      if (!localuuid) {
        return (
          <div className="w-full p-4 bg-white shadow-sm">
            <div className="max-w-7xl mx-auto flex justify-between items-center">
              <div className="flex items-center space-x-4">
              <Link to='/'>
                <img className='profile_rogo' src={rogo} alt="Logo" />
              </Link>
                <h3 className="text-m font-bold text-gray-900">IDNect</h3>
              </div>
              <Link 
                to="/" 
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t('regist_game')}
              </Link>
            </div>
          </div>
        );
      }

  return (
    <>
<div className="fixed left-0 h-screen w-64 bg-gray-50 text-gray-800 p-4 border-r border-gray-200 md:block hidden">
  <div className="flex flex-col h-full">
    <div className="mb-8 flex items-center space-x-4">
      <Link to='/login'>
        <img className='profile_rogo' src={rogo} alt="Logo" />
      </Link>
      <h1 className="text-2xl font-bold text-gray-900">IDNect</h1>
    </div>

    <nav className="flex-1">
      <ul className="space-y-4">
        <li>
          <Link to="/Home">
            <a
              href="#"
              className={`flex items-center space-x-3 p-2 rounded-lg ${
                isActive('/Home') ? 'bg-gray-200 text-indigo-600' : 'hover:bg-gray-100'
              }`}
            >
              <Home size={20} />
              <span>{t('home')}</span>
            </a>
          </Link>
        </li>
        <li>
          <Link to={`/Profile/${localuuid}`}>
            <a
              href="#"
              className={`flex items-center space-x-3 p-2 rounded-lg ${
                isActive(`/Profile/${localuuid}`) ? 'bg-gray-200 text-indigo-600' : 'hover:bg-gray-100'
              }`}
            >
              <User size={20} />
              <span>{t('profile')}</span>
            </a>
          </Link>
        </li>
        <li>
          <Link to="/Search">
            <a
              href="#"
              className={`flex items-center space-x-3 p-2 rounded-lg ${
                isActive('/Search') ? 'bg-gray-200 text-indigo-600' : 'hover:bg-gray-100'
              }`}
            >
              <Search size={20} />
              <span>{t('search')}</span>
            </a>
          </Link>
        </li>
        <li>
          <Link to="/Setting">
            <a
              href="#"
              className={`flex items-center space-x-3 p-2 rounded-lg ${
                isActive('/Setting') ? 'bg-gray-200 text-indigo-600' : 'hover:bg-gray-100'
              }`}
            >
              <Settings size={20} />
              <span>{t('setting')}</span>
            </a>
          </Link>
        </li>
      </ul>
    </nav>
    {/* 広告 */}
    {/* <div className="my-4 p-3 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg border border-indigo-100">
      <Adsence/>
    </div> */}

    <div className="mt-auto">
      <button className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 w-full text-red-600" 
      onClick={() => {
        setlogoutDialog(true);
      }}

      >
        <LogOut size={20} />
        <span>{t('logout')}</span>
      </button>
    </div>
  </div>
</div>
    {/* mobile */}
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
    <nav className="flex justify-around items-center h-16">
      <Link to="/Home">
        <a
          className={`flex flex-col items-center justify-center w-16 py-1 ${
            isActive('/Home') ? 'text-indigo-600' : 'text-gray-600'
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-home w-6 h-6"
          >
            <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span className="text-xs mt-1">{t('home')}</span>
        </a>
      </Link>
      <Link to={`/Profile/${localuuid}`}>
        <a
          className={`flex flex-col items-center justify-center w-16 py-1 ${
            isActive(`/Profile/${localuuid}`) ? 'text-indigo-600' : 'text-gray-600'
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-users w-6 h-6"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          <span className="text-xs mt-1">{t('profile')}</span>
        </a>
      </Link>
      <Link to="/Search">
        <a
          className={`flex flex-col items-center justify-center w-16 py-1 ${
            isActive('/Search') ? 'text-indigo-600' : 'text-gray-600'
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-search w-6 h-6"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <path d="m21 21-4.3-4.3"></path>
          </svg>
          <span className="text-xs mt-1">{t('search')}</span>
        </a>
      </Link>
      <Link to="/Setting">
        <a
          className={`flex flex-col items-center justify-center w-16 py-1 ${
            isActive('/Setting') ? 'text-indigo-600' : 'text-gray-600'
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-settings w-6 h-6"
          >
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
          <span className="text-xs mt-1">{t('setting')}</span>
        </a>
      </Link>
    </nav>
      </div>
      {showlogoutDialog && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          onClick={() => setlogoutDialog(false)}
        >
          <div 
            className="bg-white rounded-lg p-6 w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold ">{t('logout')}</h2>
              <button
                onClick={() => setlogoutDialog(false)}
                className="p-1 hover:bg-gray-100 rounded-full"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-700 mb-6">{t('logout_message')}</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setlogoutDialog(false)}
                className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
              >
                {t('cancel')}
              </button>
              <button
                onClick={() => {
                  setlogoutDialog(false);
                  localStorage.clear(); // ローカルストレージを削除
                  auth.signOut(); // サインアウト
                  navigate('/');

                }}
                className="px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg"
              >
                {t('yes')}
              </button>
            </div>
          </div>
        </div>
      )}

    </>
  );
}