import React, { useState } from 'react';
import {getAdditionalUserInfo, signInWithPopup, signInWithRedirect} from 'firebase/auth';
import { auth, db, provider } from '../firebase';
import './google_login_button.css'
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const GoogleLoginButton = () => {
    const { t } = useTranslation();
    const [first_username_lower,setfirst_username_lower] = useState()
  

  const svgStyle = {
    display: 'block',
  };

  const signInWithGoogle = () => {
    // firebaseでサインイン
    signInWithPopup(auth, provider).then((result) => {

      // signInWithRedirect(auth, provider).then((result) => {
      // ログインするとコンソールにresultが表示される
      const isNewUser = getAdditionalUserInfo(result)?.isNewUser;
      localStorage.setItem('isNewUser',isNewUser)


      const uid = result.user.uid
      const first_username = result.user.displayName
      const first_username_lower = first_username.toLowerCase()


      localStorage.setItem('login',result.user.uid)

      if(isNewUser){
        const UserInfoRef = doc(db, 'UserInfo',uid);
        setDoc(UserInfoRef,{
          Username: first_username ,
          Username_lower:first_username_lower,
          UserID: uid ,
          UserID_lower:uid.toLowerCase(),
          introduction : '' ,
          uuid: uid ,
          hidden:false,
          createdAt: serverTimestamp()
          
        });
        //SNS
        const SNSRef = doc(db, 'SNS',uid);
        setDoc(SNSRef,{
          'Discord':'',
          'Tiktok':'',
          'Twitch':'',
          'X':'',
          'Youtube':'',
          'Instagram':'',
          'Facebook':'',
          "uuid": uid ,
        });
        //Friends
        const FriendsRef = doc(db, 'Friends',uid);
        setDoc(FriendsRef,{
          follower:[],
          following: [],
          friend: [],
          uuid: uid,
        });
        const blockRef = doc(db,'block',uid);
        setDoc(blockRef,{
          blocked:[],
          blocking: [],
          "uuid": uid ,
        });
        const deviceRef = doc(db,'Device',uid);
        setDoc(deviceRef,{
          device:'pc',
        });
      }

    }).catch((error) => {
      if (error.code === 'auth/popup-closed-by-user') {
        console.log('エラーが起きました')
      }else{
        console.error("ログインエラー:", error);

      }
    })
  };

  return (
    // <button className="gsi-material-button" onClick={signInWithGoogle}>
    //   <div className="gsi-material-button-state"></div>
    //   <div className="gsi-material-button-content-wrapper">
    //     <div className="gsi-material-button-icon">
    //       <svg version="1.1" viewBox="0 0 48 48" style={svgStyle}>
    //         {/* ... SVG paths here ... */}
    //         <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
    //         <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
    //         <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
    //         <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
    //       </svg>
    //     </div>
    //     <span className="gsi-material-button-contents">{t('Google_login')}</span>
    //     <span style={{ display: 'none' }}>{t('Google_login')}</span>
    //   </div>
    // </button>
    <button
    onClick={signInWithGoogle}
    className="mt-6 w-full flex items-center justify-center gap-3 px-4 py-2 border border-gray-300 shadow-sm rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    <img
      src="https://www.google.com/favicon.ico"
      alt="Google"
      className="w-5 h-5"
    />
    {t('google_login')}
  </button>
  );
};

export default GoogleLoginButton;