import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { createUserWithEmailAndPassword, getAdditionalUserInfo, sendEmailVerification, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { useState } from 'react'
import { auth, SigninWithEmailAndPassword } from '../firebase';
import { t } from 'i18next';
import { Lock, Mail, X } from 'lucide-react';
import { useNotification } from './Notification';

function Remail() {
    const [open, setOpen] = useState(false);
    const [email, setemail] = useState();
    const [password, setpassword] = useState();

    const [massage, setmassage] = useState();
    const { showNotification } = useNotification();








    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      const re_mail_send = async() => {

        try{
            // const user = await SigninWithEmailAndPassword(email, password)
            // const isNewUser = getAdditionalUserInfo(user)
            // console.log(isNewUser)
            // await sendEmailVerification(user.user)
            // setmassage('認証メールを送信しました。')
            const userCredential = await signInWithEmailAndPassword(auth, email, password);

            const user = userCredential.user;

            // 認証メールを再送信
            await sendEmailVerification(user);
            // setmassage('認証メールを送信しました。');
            showNotification('resend_mail_text', 'success');
            try{
                if(userCredential.user.emailVerified){
                    
                }else{
                    await signOut(auth);
                }
            }catch(error){
                showNotification('error', 'error');
            }
        }catch(error){
            console.log(error)
            switch (error.code) {
              case 'auth/invalid-email':
                setmassage('auth/invalid-email');
                showNotification('auth/invalid-email', 'error');

                break;
              case 'auth/user-disabled':
                setmassage('auth/user-disabled');
                showNotification('auth/user-disabled', 'error');

                break;
              case 'auth/user-not-found':
                setmassage('auth/user-not-found');
                showNotification('auth/user-not-found', 'error');

                break;
              case 'auth/wrong-password':
                setmassage('auth/wrong-password');
                showNotification('auth/wrong-password', 'error');

                break;
              case 'auth/email-already-in-use':
                setmassage('auth/email-already-in-use');
                showNotification('auth/email-already-in-use', 'error');

                break;
              case 'auth/operation-not-allowed':
                setmassage('auth/operation-not-allowed');
                showNotification('auth/operation-not-allowed', 'error');

                break;
              case 'auth/weak-password':
                setmassage('auth/weak-password');
                showNotification('auth/weak-password', 'error');

                break;
              case 'auth/network-request-failed':
                setmassage('auth/network-request-failed');
                showNotification('auth/network-request-failed', 'error');

                break;
              default:
                setmassage('default');
                                showNotification('default', 'error');

            }}



      }
  return (
    <div>
            {/* <React.Fragment> */}
        <div className='remail'>

        <p className='remail_text' onClick={handleClickOpen}>
            <a href="#" className="text-sm text-indigo-600 hover:text-indigo-500 block">
                {t('resend_mail')}
            </a>
        </p>
        </div>

        {open && (
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                onClick={handleClose}
              >
                <div 
                  className="bg-white rounded-lg p-6 w-full max-w-md"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{t('Resend_confirmation_email')}</h2>
                    <button
                      onClick={handleClose}
                      className="p-1 hover:bg-gray-100 rounded-full"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                  <form  className="space-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      {t('mailaddress')}
                    </label>
                    <div className="relative">
                      <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                      <input
                        type="email"
                        id="email"
                        required
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="example@email.com"
                        onChange={(event) => setemail(event.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                      {t('password')}
                    </label>
                    <div className="relative">
                      <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                      <input
                        type="password"
                        id="password"
                        required
                        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="••••••••"
                        onChange={(event) => setpassword(event.target.value)}
                      />
                    </div>
                  </div>
                
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
                    onClick={re_mail_send}
                  >
                    {t('send')}
                  </button>
                </div>
                </form>
                  </div>
                </div>
            )}
        
        
        {/* <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            // handleClose();
            },
        }}
        >
        <DialogTitle className='popname'>

        </DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t("required-fields")}
            </DialogContentText>
                  <div className="w-[320px]">
        <h2 className="text-2xl font-bold mb-6 text-gray-800"></h2>
        <form  className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              メールアドレス
            </label>
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="email"
                id="email"
                required
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="example@email.com"
                onChange={(event) => setemail(event.target.value)}
              />
            </div>
          </div>
          
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              パスワード
            </label>
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="password"
                id="password"
                required
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="••••••••"
                onChange={(event) => setpassword(event.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
            >
              {t('close')}
            </button>
            <button
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
              onClick={re_mail_send}
            >
              {t('send')}
            </button>
          </div>
        </form>
      </div>
            {t(massage)}

        </DialogContent>
        </Dialog>
    </React.Fragment> */}

    </div>
  )
}

export default Remail;