import React, { memo, useEffect,  useRef,  useState } from 'react'
import { Autocomplete, Box, Button, CircularProgress, Divider, IconButton, InputBase, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

//icon
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import './regist_button.css'

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlaystation, faSteam, faXbox } from '@fortawesome/free-brands-svg-icons';
import { ArrowLeft, Gamepad, Gamepad2Icon, Monitor, Search, Smartphone, X } from 'lucide-react';



//スマホとスマホ外でくくっているが、スマホ外っていうのが曖昧なのでpcとする。

const Registbutton = memo(({suggest}) => {
  //dialog
    const [open, setOpen] = React.useState(false);
    //モバイル
    const [mobile_regist_open, setmobile_regist_open] = React.useState(false);
    const [mobile_regist_open_2, setmobile_regist_open_2] = React.useState(false);
    //pcとか
    const [pc_regist_open, setpc_regist_open] = React.useState(false);
    const [pc_regist_open_2, setpc_regist_open_2] = React.useState(false);
    //playstation(id)
    const [id_playstation, setid_playstation] = React.useState(false);
    const [id_name, setid_name] = React.useState(false);
    const [consumer_id_url, setconsumer_id_url] = React.useState(false);

    const [consumer_id, setconsumer_id] = React.useState();
    const [consumer_id_number, setconsumer_id_number] = React.useState();

    





    //suggest関係

    // const [suggestions, setSuggestions] = useState([]);
    const [display_suggest, setdisplay_suggest] = useState(' ');



    const [appName,setappName] = useState('');
    const [AppImage,setAppImage] = useState([]);
    // const [GameName,setGameName] = useState([]);boltnewと同じ変数だったため一時的にコメントアウト
    const [appid,setappId] = useState([]);
    const [igdb_url,setigdb_url] = useState([]);





    // const [gamename,setgamename] = useState('');
    const [gameuserid,setgameuserid] = useState('');
    const [gameusername,setgameusername] = useState('');
    const [gamememo,setgamememo] = useState('');


    //mobile_game選択
    const [serach_result_gamename,setserach_result_gamename] = useState([]);
    const [serach_result_gameimg,setserach_result_gameimg] = useState([]);
    const [serach_result_gameid,setserach_result_gameid] = useState([]);


    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();

    const[loading,setloading] = useState(false)
    // Bolt.new===========

    const [dialogState, setDialogState] = useState('closed')
    const [selectedType, setSelectedType] = useState(null)
    const [GameName, setGameName] = useState('')
    const [gameId, setGameId] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
  
    const handleRegister = () => {
      if (GameName && gameId) {
        setDialogState('closed')
        setSelectedType(null)
        setGameName('')
        setGameId('')
      }
    }
  
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        setDialogState('closed')
      }
    }

    // Bolt.new サジェスト
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const wrapperRef = useRef(null);
  
    useEffect(() => {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShowSuggestions(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    useEffect(() => {
      if (query.trim() === '') {
        setSuggestions([]);
        return;
      }
  
      const filteredGames = suggest.filter(game => 
        game.name.toLowerCase().includes(query.toLowerCase()) ||
        game.japan_name.includes(query)
      ).slice(0, 30);
  
      setSuggestions(filteredGames);
      setShowSuggestions(true);
    }, [query]);
  
    const handleSelect = (game) => {
      setQuery('');
      setSelectedGame(game.name);
      setShowSuggestions(false);
    };



//     //サジェスト関係============================================
    //データをTodolistに入れる。
    // useEffect(() => {
    //   if(got_suggest.current === false){
    //     (async () => {
    //       const list = await getToDoList();
    //       setTodoList(list);
    //       got_suggest.current = true;
    //     })();
    //   }
    //    }, []);

      const handleInputChange = (event) => {
        const inputValue = event.target.value;
        // サジェストを生成
        const newSuggestions = generateSuggestions(inputValue).slice(0, 30); 
        setSuggestions(newSuggestions);
        setGameName(inputValue)

      };
    
      // const generateSuggestions = (input) => {
      //   // ここでサジェストのロジックを実装する
      //   // 例えば、入力された文字列と一致する可能性のあるサジェストを取得するAPIを呼び出すなど
      //     if (Array.isArray(todoList)) {
      //         return todoList.filter(suggestion =>
      //             suggestion.name.toLowerCase().includes(input.toLowerCase())
      //         );
      //  }
      // };

      const generateSuggestions = (input) => {
        if (Array.isArray(suggest)) {
          return suggest
            .filter(suggestion =>
              suggestion.name.toLowerCase().includes(input.toLowerCase()) ||
              suggestion.japan_name.toLowerCase().includes(input.toLowerCase())
            )
        }
      };

      useEffect(() => {
        if (i18n.language === 'ja'){
          const mappedSuggestions = suggestions.map(item => ({
            label: item['japan_name'],
            image_url: item['image_url'], // ラベルをvalueとして使う例
            url: item['url'] 
          }));
          setdisplay_suggest(mappedSuggestions)

        }else if(i18n.language === 'en'){
          const mappedSuggestions = suggestions.map(item => ({
            label: item['name'],
            image_url: item['image_url'], // ラベルをvalueとして使う例
            url: item['url'] 
          }));
          setdisplay_suggest(mappedSuggestions)
        }
        // const mappedSuggestions = suggestions.map(item => ({
        //   label: item['japan_name'],
        //   image_url: item['image_url'] // ラベルをvalueとして使う例
        // }));
        // setdisplay_suggest(mappedSuggestions)
      },[suggestions,i18n.language])

      //ゲーム選択のの次の登録のための画像を準備
      // useEffect(() => {
      //   const cover = todoList.filter(suggestion =>
      //     suggestion.japan_name.toLowerCase() === GameName.toLowerCase()
      //   )[0];
      //   if (cover){
      //     setAppImage(cover['image_url'])
      //   }
      // },[GameName])
      //autocompleteのフィルタリングを無効に
      const noFilterOptions = (options) => options;
      //===============================================================
    const GameRegist = async(event) => {
      event.preventDefault();


      const docRef = await addDoc(collection(db, "PlayerGames"), {
        uuid: user.uid,
        gamename: GameName,
        gameuserid:gameuserid,
        gameusername:gameusername,
        gameimgurl:AppImage,
        gameid: appid,
        igdb_url:igdb_url,
        memo:gamememo,
        createdAt: serverTimestamp(),
        editedAt:serverTimestamp()

      });
      navigate('/Profile/'+user.uid);
      setmobile_regist_open_2(false);
      setpc_regist_open_2(false);
      setGameName('')
      setAppImage('')
      setappName('')
      setappId('');
      setgamememo('')
      setDialogState('closed')
      setSelectedType(null)
      
      const local_PlayerGames = localStorage.getItem('PlayerGames');
      const dataArray = local_PlayerGames ? JSON.parse(local_PlayerGames) : [];
      const newdata = {
        uuid: user.uid,
        gamename: GameName,
        gameuserid:gameuserid,
        gameusername:gameusername,
        gameimgurl:AppImage,
        gameid: appid,
        createdAt: serverTimestamp(),
        editedAt:serverTimestamp()
      }
      dataArray.push(newdata);
      localStorage.setItem('PlayerGames', JSON.stringify(dataArray));
      setgameusername('')
      setgameuserid('')
    };
//家庭用ゲーム機登録
    const Consumer_id_Regist = async(event) => {
      event.preventDefault();


      const docRef = await addDoc(collection(db, "PlayerGames"), {
        uuid: user.uid,
        gamename: id_name,
        gameuserid:consumer_id,
        gameusername:"",
        gameimgurl:consumer_id_url,
        gameid: consumer_id_number,
        createdAt: serverTimestamp(),
        editedAt:serverTimestamp()
      });
      navigate('/Profile/'+user.uid);
      handleClose_id_playstation()

    };

    //dialog開くためのもの＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    //モバイルアプリ登録画面
    const mobiledialobClose = () => {
      setmobile_regist_open(false);
    };
    const mobileopen = () => {
      handleClose();
      setmobile_regist_open(true);
    }
    const mobileback = () => {
      handleClickOpen();
      setmobile_regist_open(false);
    }
    //モバイル登録画面２
    const mobiledialobClose_2 = () => {
      setmobile_regist_open_2(false);
    };
    const mobileopen_2 = () => {
      setmobile_regist_open(false);
      setmobile_regist_open_2(true);
      setigdb_url('')
    }
    const mobileopen_2_back = () => {
      setmobile_regist_open(true);
      setmobile_regist_open_2(false);
    }
    //pcゲーム等登録画面
    const pcdialobClose = () => {
      setpc_regist_open(false);
    };
    const pcopen = () => {
      handleClose();
      setpc_regist_open(true);
    }
    const pcopen_back = () => {
      handleClickOpen();
      setpc_regist_open(false);
    }
    //pcゲーム等登録画面２
    const pcdialobClose_2 = () => {
      setpc_regist_open_2(false);
    };
    const pcopen_2 = () => {
      const substring = '//images.igdb.com/igdb/image/upload/t_thumb/';
      if(AppImage.startsWith('/')){

        setappId(AppImage.replace(substring, '').toString())
        setAppImage('https:'+AppImage)
      }
      setpc_regist_open(false);
      setpc_regist_open_2(true);
    }
    const pcopen_2_back = () => {
      setpc_regist_open(true);
      setpc_regist_open_2(false);
    }

    const click_mobile_game = (event,img,name,id) => {
      event.preventDefault();
      setAppImage(img)
      setGameName(name)
      setappId(id)
      mobileopen_2()
    }

    //playstation_id
    const handleClickOpen_id_playstation = () => {
      setid_name('PlayStation')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fplaystation_nologo.png?alt=media&token=97325951-070c-42eb-9566-eda41e82cb4a")
      setconsumer_id_number("2")
      handleClose()
      setid_playstation(true);
    };

    const handleClickOpen_id_xbox = () => {
      setid_name('Xbox')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fxbox_nologo.png?alt=media&token=9e927e43-a636-49bb-9534-d2bceb8d4b13")
      setconsumer_id_number("3")
      handleClose()
      setid_playstation(true);
    };
    const handleClickOpen_id_steam = () => {
      setid_name('Steam')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fsteam_nologo.png?alt=media&token=fbb7cdb9-7a2f-4325-b316-2bfb01964676")
      setconsumer_id_number("1")
      handleClose()
      setid_playstation(true);
    };
    const handleClickOpen_id_switch = () => {
      setid_name('Nintendo Switch')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fnitendoswitch_nologo.png?alt=media&token=525418a7-a443-4935-b85b-93c4ef89ba8b")
      setconsumer_id_number("0")
      handleClose()
      setid_playstation(true);
    };
  
    const handleClose_id_playstation = () => {
      setid_playstation(false);
    };

    const idback = () => {
      handleClickOpen();
      setid_playstation(false);
    }

    

      //itunes関係＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const handleChange = (e) => {
        setappName(e.target.value);
      };

      const handleSubmit = async (event) => {
        // event.preventDefault();
        setloading(true)
        let itunes_url;
        if (i18n.language === 'ja'){
          itunes_url = `https://itunes.apple.com/search?term=${appName}&entity=software&country=jp&genreId=6014&limit=4`;

        }else if(i18n.language === 'en'){

          itunes_url = `https://itunes.apple.com/search?term=${appName}&entity=software&country=us&genreId=6014&limit=4`;
        }
        try {
            const response = await fetch(itunes_url);
            const data = await response.json();
            const results = data.results
            
            if (results.length > 0) {

                const imageUrl = results.map(item => item.artworkUrl100);
                const appName = results.map(item => item.trackName);
                const str_appid = results.map(item => item.trackId.toString());


                if (imageUrl) {
                    setserach_result_gameimg(imageUrl);
                    setserach_result_gamename(appName);
                    setserach_result_gameid(str_appid)
                    // Firestoreへの書き込みはここに追加
                } else {
                    console.error('Failed to get image from URL');
                }
            } else {
                console.error('No results found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setloading(false)
        };
    const handleKeyDown = async(event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // デフォルトのEnterキー動作を防止する

        handleSubmit();
      }
    };

    const Loadingdiaplay = () => {
      if(loading){
        return (
          <CircularProgress className='kurukuru'/>
        )
      }else{
        return null;
      }
    }
  return (

    <div>
      {/* メインボタン */}
      <button
        onClick={() => setDialogState('main')}
        className="mx-auto block bg-indigo-600 hover:bg-indigo-700 text-white px-6 py-3 rounded-lg shadow-lg transition-colors flex items-center gap-2"
      >
        <Gamepad className="w-5 h-5" />
        {t('regist_game')}
      </button>

      {/* オーバーレイ */}
      {dialogState !== 'closed' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" onClick={handleOverlayClick}>
          <div className="bg-white rounded-xl shadow-2xl p-6 w-full max-w-md relative">
            {/* 閉じるボタン */}
            <button
              onClick={() => setDialogState('closed')}
              className="absolute right-4 top-4 p-1 hover:bg-gray-100 rounded-full"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>

            {/* メインダイアログ */}
            {dialogState === 'main' && (
              <div className="space-y-4">
                <h2 className="text-2xl font-bold text-gray-800 text-center">{t('Select_game_type')}</h2>
                <h4 className=" font-bold text-gray-800 ml-3">GAME</h4>

                <div className="grid grid-cols-2 gap-4">
                  <button
                    onClick={() => {
                      setSelectedType('mobile')
                      setDialogState('search')
                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                    <Smartphone className="w-8 h-8 text-indigo-600" />
                    <span className="font-medium">{t('Mobilegame')}</span>
                  </button>
                  <button
                    onClick={() => {
                      setSelectedType('pc')
                      setDialogState('search')
                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                    <Monitor className="w-8 h-8 text-indigo-600" />
                    <span className="font-medium">{t('PC_playstation_switch')}</span>
                  </button>

                  <button
                    onClick={() => {
                      // setSelectedType('pc')
                      setDialogState('other_registration')
                      setAppImage('https://firebasestorage.googleapis.com/v0/b/idnect-com.firebasestorage.app/o/user_images%2Fnoimage.jpg?alt=media&amp;token=064458d4-3c61-4173-96a9-aabf4ce57d4e')

                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                    <Gamepad2Icon className="w-8 h-8 text-indigo-600" />
                    <span className="font-medium">{t('other')}</span>
                  </button>
                </div>
                <h4 className=" font-bold text-gray-800 ml-3">ID</h4>

                <div className="grid grid-cols-2 gap-4">

                  <button
                      onClick={() => {
                        setGameName("Nintendo Switch")
                        setAppImage('https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fnitendoswitch_nologo.png?alt=media&token=525418a7-a443-4935-b85b-93c4ef89ba8b')
                        setDialogState('registration')
                        setappId("0")
                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                  <div className='text-red-600 text-4xl'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.136 4.136 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979Zm4.675.269a1.621 1.621 0 0 0-1.113-1.034 1.609 1.609 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.632 1.632 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053ZM3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256Z" />
                      <path d="M3.425.053a4.136 4.136 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.845 2.845 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z" />
                    </svg>
                  </div>
                    <span className="font-medium">Nitendo Switch</span>
                  </button>
                  <button
                    onClick={() => {
                      setGameName("Steam")
                      setAppImage("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fsteam_nologo.png?alt=media&token=fbb7cdb9-7a2f-4325-b316-2bfb01964676")
                      setDialogState('registration')
                      setappId("1")
                      
                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                    <FontAwesomeIcon icon={faSteam} className="text-4xl" />
                    <span className="font-medium">Steam</span>
                  </button>
                  <button
                    onClick={() => {
                      setGameName("PlayStation")
                      setAppImage("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fplaystation_nologo.png?alt=media&token=97325951-070c-42eb-9566-eda41e82cb4a")
                      setDialogState('registration')
                      setappId("2")
                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                    <FontAwesomeIcon icon={faPlaystation} className='text-blue-700 text-4xl'/>
                    <span className="font-medium">PlayStation</span>
                  </button>
                  <button
                    onClick={() => {
                      setGameName("Xbox")
                      setAppImage("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fxbox_nologo.png?alt=media&token=9e927e43-a636-49bb-9534-d2bceb8d4b13")
                      setDialogState('registration')
                      setappId("3")
                    }}
                    className="flex flex-col items-center gap-2 p-4 border-2 border-indigo-200 rounded-lg hover:bg-indigo-50 transition-colors"
                  >
                    <FontAwesomeIcon icon={faXbox} className='text-green-700 text-4xl'/>
                    <span className="font-medium">Xbox</span>
                  </button>
                </div>
                <button
                  onClick={() => setDialogState('closed')}
                  className="w-full mt-4 px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  {t('cancel')}
                </button>
              </div>
            )}

            {/* 検索画面 */}
            {dialogState === 'search' && (
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setDialogState('main')}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <ArrowLeft className="w-5 h-5" />
                  </button>
                  <h2 className="text-xl font-bold text-gray-800">
                    {selectedType === 'mobile' ? t('Mobilegame') : t('PC_playstation_switch')}
                  </h2>
                </div>
                {selectedType === 'mobile' ? (
                <>
                <a>{t('language_result_change')}</a>
                <div className="relative">
                  <input
                  type="text"
                  placeholder={`${t('search_game')}...`}
                  value={appName} 
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg pl-10"
                />


                  <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                </div>

                {/* 検索結果 */}
                <div className="space-y-2 max-h-[400px] overflow-y-auto h-96">
                  {serach_result_gameimg.map((img,index) => (             
                    <div
                      key={serach_result_gamename[index]}
                      className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                      onClick={() => {
                        setGameName(serach_result_gamename[index])
                        setAppImage(img)
                        setDialogState('registration')
                        setappId(serach_result_gameid[index])
                      }}
                    >
                      <img
                        src={img}
                        alt={img}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                      <span className="font-medium">{serach_result_gamename[index]}</span>
                    </div>
                  ))}
                </div>
                </>
                      ) : (
                        <>
                {/* <div className="relative">
                  <input
                  type="text"
                  placeholder="ゲームを検索..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg pl-10"
                />


                  <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                </div> */}
                    <div className="w-full max-w-xl mx-auto p-4 max-h-[40vh]" ref={wrapperRef}>
      <div className="relative">
        <div className="relative">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={`${t('search_game')}...`}
            className="w-full px-4 py-2 pl-10 pr-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <div className='h-96'>
                    {showSuggestions && suggestions.length > 0 && (
                      <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg z-10 overflow-y-auto max-h-[30vh]">
                        {suggestions.map((game) => (
                   <div
                      key={game.name}
                      className="flex items-center gap-3 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
                      onClick={() => {
                        setGameName(i18n.language === 'ja' ? game.japan_name : game.name);
                        setAppImage(game.image_url)
                        setDialogState('registration')
                        setigdb_url(game.url)
                        const match = game.image_url.match(/t_thumb\/(.*?)\.jpg/);

                        if (match) {
                          setappId(match[1]); // 拡張子を除いたキャプチャグループの値を設定
                        } else {
                          console.error("No match found in the URL");
                        }

                      }}
                    >
                            {/* <span className="block text-gray-700">{game.nameEn}</span> */}

                            <img src={game.image_url}
                            className="w-10 h-10 object-cover rounded-lg"
                            />
                            
                            <span className="font-medium">
                              {i18n.language === 'ja' ? game.japan_name : game.name}
                            </span>                          
                            </div>
                        ))}
                                </div>
                              )}
                            </div>
                            </div>

                            {selectedGame && (
                              <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                                <p className="text-blue-800">{t('game_name')}: {selectedGame}</p>
                              </div>
                            )}
                          </div>
                        </>
                      )}

                {/* <button
                  onClick={() => setDialogState('registration')}
                  className="w-full bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  新規登録
                </button> */}
              </div>
            )}

            {/* 登録画面 */}
            {dialogState === 'registration' && (
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      const platforms = ['Nintendo Switch', 'Steam', 'PlayStation', 'Xbox'];
                      if (platforms.includes(GameName)) {
                        setDialogState('main');
                      } else {
                        setDialogState('search');
                      }
                    }}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <ArrowLeft className="w-5 h-5" />
                  </button>
                  <h2 className="text-xl font-bold text-gray-800">{t('Register_game_information')}</h2>
                </div>
                <div className="space-y-3">
                <img
                        src={AppImage}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('game_name')}
                    </label>
                    <input
                      type="text"
                      value={GameName}
                      // onChange={(e) => setGameName(e.target.value)}
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder={`${t('game_name')}...`}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t('name')}
                    </label>
                    <input
                      type="text"
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder={`${t('name')}...`}
                      value={gameusername}
                      onChange={(event) => setgameusername(event.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      ID
                    </label>
                    <input
                      type="text"
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder="ID..."
                      value={gameuserid}
                      onChange={(event) => setgameuserid(event.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t('memo')}
                    </label>
                    {/* <input
                      type="text"
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder= {t('memo') + '...'}
                      value={gamememo}
                      onChange={(event) => setgamememo(event.target.value)}
                    /> */}
                    <textarea
                      id="introduction"
                      value={gamememo}
                      required
                      className="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder= {t('memo') + '...'}
                      onChange={(event) => setgamememo(event.target.value)}
                      rows={3} // 初期表示の高さを調整
                    ></textarea>
                  </div>
                </div>
                <button
                  onClick={GameRegist}
                  className="w-full bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-lg transition-colors"
                  // disabled={!GameName}
                >
                  {t('regist')}
                </button>
              </div>
            )}


                        {/* 登録画面 */}
            {dialogState === 'other_registration' && (
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => {
                      const platforms = ['Nintendo Switch', 'Steam', 'PlayStation', 'Xbox'];
                      if (platforms.includes(GameName)) {
                        setDialogState('main');
                      } else {
                        setDialogState('search');
                      }
                    }}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <ArrowLeft className="w-5 h-5" />
                  </button>
                  <h2 className="text-xl font-bold text-gray-800">{t('Register_game_information')}</h2>
                </div>
                <div className="space-y-3">
                <img
                        src={AppImage}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('game_name')}
                    </label>
                    <input
                      type="text"
                      value={GameName}
                      onChange={(e) => setGameName(e.target.value)}
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder={`${t('game_name')}...`}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t('name')}
                    </label>
                    <input
                      type="text"
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder={`${t('name')}...`}
                      value={gameusername}
                      onChange={(event) => setgameusername(event.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      ID
                    </label>
                    <input
                      type="text"
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder="ID..."
                      value={gameuserid}
                      onChange={(event) => setgameuserid(event.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {t('memo')}
                    </label>
                    {/* <input
                      type="text"
                      className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                      placeholder= {t('memo') + '...'}
                      value={gamememo}
                      onChange={(event) => setgamememo(event.target.value)}
                    /> */}
                    <textarea
                      id="introduction"
                      value={gamememo}
                      required
                      className="w-full pl-4 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder= {t('memo') + '...'}
                      onChange={(event) => setgamememo(event.target.value)}
                      rows={3} // 初期表示の高さを調整
                    ></textarea>
                  </div>
                </div>
                <button
                  onClick={GameRegist}
                  className="w-full bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-lg transition-colors"
                  // disabled={!GameName}
                >
                  {t('regist')}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>

  )}
)

export default Registbutton;