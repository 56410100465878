// import React from 'react'

// export default function Pcgamelist_data() {

//   return (
//     <div>1.0.0</div>
//   )
// }

import React, { useState, useEffect } from 'react';

const Pcgamelist_data = () => {
  const [csvData, setCsvData] = useState('');

  // 初期CSVファイルを読み込む関数
  // useEffect(() => {
  //   const loadCsv = async () => {
  //     try {
  //       const response = await fetch('../../../public/multiplay_rating_notnull(null補完済み)_0902.csv');

  //       const text = await response.text();
  //       setCsvData(text);
  //     } catch (error) {
  //       console.error('CSVファイルの読み込みに失敗しました:', error);
  //     }
  //   };

  //   loadCsv();
  // }, []);


  // // CSVデータをダウンロードする関数
  // const handleDownload = () => {
  //   if (!csvData) {
  //     alert('CSVデータが読み込まれていません。');
  //     return;
  //   }

  //   const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'download.csv'; // ダウンロードするファイル名
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   window.URL.revokeObjectURL(url);
  // };

  return (
    <div>
      <h1 className='version'>1.0.0</h1>

      {/* CSVファイルダウンロードボタン */}

    </div>
  );
};

export default Pcgamelist_data;



