import React from 'react'
import { Button, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import {  collection, deleteDoc, getDocs, query, where } from 'firebase/firestore';

//icon
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';
import { Trash2, X } from 'lucide-react';

//登録ゲーム編集

function Deletegamebutton({ uuid,profile_gamename, profile_gameusername, profile_gameuserid,gameimg,memo,closeDialog}) {
    const [open, setOpen] = React.useState(false);
    const [user] = useAuthState(auth);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      closeDialog();
    };

    const handleDialogClick = (event) => {
      event.stopPropagation(); // Dialog内のクリックイベントを止める
    };

    //データ更新===================================
  //削除ボタン＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    //削除
    const gamedelete = async (uuid,gamename, gameusername, gameuserid) => {
      const q = query(collection(db,'PlayerGames')
      ,where('gamename', '==', gamename)
      ,where('gameusername', '==', gameusername)
      ,where('gameuserid', '==', gameuserid)
      ,where('uuid', '==', uuid));

      //監視
      // const unsubscribe = onSnapshot(q, (querySnapshot) => {
      //   querySnapshot.docChanges().forEach(async (doc) => {
      //     await deleteDoc(doc.ref);
      //   });
      // });

      try {
        const querySnapshot = await getDocs(q);
        const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
      } catch (error) {
        console.error('Error deleting matching documents: ', error);
      }
      // menuhandleClose();
      handleClose(false)
    };

  return (
    // <React.Fragment>
    <div>
    <button
                  onClick={handleClickOpen}
                  className="w-full flex items-center gap-2 px-4 py-2 text-left text-red-600 hover:bg-red-50 rounded-lg"
                >
                  <Trash2 className="w-5 h-5" />
                  <span>{t('delete')}</span>                
                  </button>
                  {open && (
                              <div 
                                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                                onClick={() => handleClose(false)}
                              >
                                <div 
                                  className="bg-white rounded-lg p-6 w-full max-w-md"
                                  onClick={e => e.stopPropagation()}
                                >
                                  <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl font-bold ">変更</h2>
                                    <button
                                      onClick={() => handleClose(false)}
                                      className="p-1 hover:bg-gray-100 rounded-full"
                                    >
                                      <X className="w-5 h-5" />
                                    </button>
                                  </div>
                                  <img
                                      src={gameimg}
                                      alt={gameimg}
                                      className="w-16 h-16 object-cover rounded-lg mb-2.5"
                                      />
                                                <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('gamename')}
                                  </label>
                                  <input
                                    type="text"
                                    value={profile_gamename}
                                    // onChange={(e) => setgamename(e.target.value)}
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                                    disabled
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('username')}
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg"
                                    value={profile_gameusername}
                                    // onChange={(event) => setgameusername(event.target.value)}
                                    disabled
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    ID
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg mb-2.5"
                                    value={profile_gameuserid}
                                    // onChange={(event) => setgameuserid(event.target.value)}
                                    disabled
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('memo')}
                                  </label>
                                  <input
                                    type="text"
                                    className="w-full px-4 py-2 border-2 border-gray-200 rounded-lg mb-2.5"
                                    placeholder="memo"
                                    value={memo}
                                    // onChange={(event) => setgameuserid(event.target.value)}
                                    disabled
                                  />
                                </div>
                                  <div className="flex justify-end gap-2 ">
                                    <button
                                      onClick={() => handleClose(false)}
                                      className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg"
                                    >
                                      キャンセル
                                    </button>
                                    <button
                                        onClick={() => gamedelete(user.uid, profile_gamename, profile_gameusername, profile_gameuserid)}
                                      className="px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg"
                                    >
                                      {t('delete')}    
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={handleDialogClick}
      >
        <DialogTitle id="alert-dialog-title">
          {t('delete_game_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img className='edit_delete_icon' src={gameimg}/>
            <TextField
          disabled

            margin="dense"
            id="gamename"
            name="text"
            label={t('gametitle')}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={profile_gamename}
            onMouseDown={(event) => event.stopPropagation()}
            // onChange={(event) => setgamename(event.target.value)}
            />
            <TextField
          disabled

            margin="dense"
            id="username"
            name="text"
            label={t('username')}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={profile_gameusername}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <TextField
          disabled

            margin="dense"
            id="userid"
            name="text"
            label="ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={profile_gameuserid}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <p> </p>
            {t('delete_game_confirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('close')}</Button>
          <Button onClick={() => gamedelete(user.uid, profile_gamename, profile_gameusername, profile_gameuserid)}>{t('delete')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment> */}
        </div>

  )
}

export default Deletegamebutton;