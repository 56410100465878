import React, { useState,useEffect,  memo } from 'react';
import { collection, endAt, getDocs, limit, orderBy, query, startAt } from "firebase/firestore"; 


import { Box, CircularProgress } from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { auth, db, storage } from '../../firebase';

import '../Newcss/NewSearch.css';
import { useTranslation } from 'react-i18next';
import NewSidebar from './NewSidebar';
import { Helmet } from 'react-helmet-async';
import { Sidebar } from './components_profile/Sidebar';
import { Gamepad, Search, UserCheck, UserPlus, Users } from 'lucide-react';






//タブのためのもの========ゲーム============================
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


//タブ=================大本（アプリｏｒゲーム）=====================
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps_select(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


//==========================================================




function NewSearch({PlayerGames,gameImages,userImageUrl}) {

  const [user] = useAuthState(auth);
  const [isSearching, setIsSearching] = useState(false)
  

  //ゲーム選択css用

  localStorage.setItem('page','search');
  //言語設定
const { t } = useTranslation();

//タブ
const [isLoading, setIsLoading] = useState(false);
const [activeTab, setActiveTab] = useState('followers');
const tabs = [
  { id: 'followers', label: t('game'), icon: Gamepad },
  { id: 'friends', label: t('username'), icon: Users },
  { id: 'following', label: t('userid'), icon: Users },
];



const navigate = useNavigate();


//ログイン中華どうか
const [localuuid,setlocaluuid] = useState(0);

useEffect(() => {
  const uuid = localStorage.getItem('login')
  setlocaluuid(uuid)
},[])


if(localuuid === null){
  const remove_app = () => {
    navigate('/');
  }
  remove_app()
}

  //アプリ内検索用
  const [appusername, setappusername] = useState([]);
  const [appuser, setappuser] = useState([]);
  const [appuserImages, setappUserImages] = useState([]);

  const [appiduser, setappiduser] = useState([]);
  const [appIDUserImages, setappIDUserImages] = useState([]);


  const [loading, setloading] = useState(false);


  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tab_2',newValue)

  };

  useEffect(() => {
    //タブ１
    const tab_1 = localStorage.getItem('tab_1')
    if(tab_1 !== null){
    }
    //タブ2
    const tab_2 = localStorage.getItem('tab_2')
    if(tab_2 !== null){
    setValue(Number(tab_2))
    }
    //検索テキスト
    const search_text = localStorage.getItem('search_text')
    setappusername(search_text)
    //アプリ名前検索＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    const appuser_json = localStorage.getItem('appuser');
    if(appuser_json !== null){
      const appuser = JSON.parse(appuser_json)
      setappuser(appuser)
    }
    const appUserImages_json = localStorage.getItem('appUserImages');
    if(appUserImages_json !== null){
    const appUserImages = JSON.parse(appUserImages_json)
    setappUserImages(appUserImages)
   }
    //アプリID検索＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    const appiduser_json = localStorage.getItem('appiduser');
    if(appiduser_json !== null){
    const appiduser = JSON.parse(appiduser_json)
    setappiduser(appiduser)
   }
    const appIDUserImages_json = localStorage.getItem('appIDUserImages');
    if(appIDUserImages_json !== null){
    const appIDUserImages = JSON.parse(appIDUserImages_json)
    setappIDUserImages(appIDUserImages)
    }
    //ゲーム検索＿名前
    const UserInfo_json = localStorage.getItem('UserInfo');
    if(UserInfo_json !== null){
    }
    const UserImages_json = localStorage.getItem('UserImages');
    if(UserImages_json !== null){
   }
   //ゲーム検索＿ID
   const IDuser_json = localStorage.getItem('IDuser');
   if(IDuser_json !== null){
    //  setIDuser(IDuser)
   }
   const IDUserImages_json = localStorage.getItem('IDUserImages');
   if(IDUserImages_json !== null){
  //  setIDUserImages(IDUserImages)
  }
  },[])

  const Gamelistview = memo(() => {
    if (Array.isArray(PlayerGames)) {
      return (
          <div>
          {PlayerGames.length > 0 ? (
        <div>
          {PlayerGames.map((game,index) => (
          <Link to={`/GameSearch/${game.gameid}`} key={game.gamename+index}>
            <div
              key={game.gamename+index}
              className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0 hover:bg-gray-100"
              onClick={() => {clickgamename(index,game.gamename,game.gameid)}}
            >
              <div className="flex items-center">
                <img
                  src={game.gameimgurl}
                  alt={user.name}
                  className="w-12 h-12 rounded-md object-cover"
                />
                <div className="ml-4">
                  <h3 className="font-medium text-gray-900">{game.gamename}</h3>
                </div>
              </div>
            </div>
            </Link>
          ))}
          </div>
                  ):(
          <div className="text-center py-12 bg-white rounded-xl shadow-sm">
          <div className="text-gray-400 mb-4">
            <Search className="h-12 w-12 mx-auto" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900">{t('notgame')}</h2>
          <p className="mt-2 text-gray-600">
            {t('ifregestgame')}
          </p>
        </div>
        )}
        </div>
      )
  }})
  //=======================アプリユーザー検索======================
  const SearchappUser = async () => {
    const appusername_lower = appusername.toLowerCase()
    try {
        const userQuery = query(
            collection(db, 'UserInfo'),
            orderBy('Username_lower'),
            startAt(appusername_lower),
            endAt(appusername_lower + '\uf8ff'),
            limit(30)
        );
        const userSnapshot = await getDocs(userQuery);
        setappuser(userSnapshot.docs.map(doc => ({ ...doc.data() })));
        localStorage.setItem('appuser',JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))


        const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));
        const images = await Promise.all(
            userlist.map(async (uuid) => {
                try {
                    const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
                    return gameUrl;
                } catch (error) {
                    const gameUrl = await getDownloadURL(ref(storage, '/user_images/noimage.jpg'));
                    return gameUrl;
                }
            })
        );
        setappUserImages(images);
        localStorage.setItem('appUserImages',JSON.stringify(images));
    } catch (error) {
        console.error(error);
    }
};

const SearchappIDUser = async () => {
  const appusername_lower = appusername.toLowerCase()
  try {
      const userQuery = query(
          collection(db, 'UserInfo'),
          orderBy('UserID_lower'),
          startAt(appusername_lower),
          endAt(appusername_lower + '\uf8ff'),
          limit(30)
      );
      const userSnapshot = await getDocs(userQuery);
      setappiduser(userSnapshot.docs.map(doc => ({ ...doc.data() })));
      localStorage.setItem('appiduser',JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))


      const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));
      const images = await Promise.all(
          userlist.map(async (uuid) => {
              try {
                  const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
                  return gameUrl;
              } catch (error) {
                  const gameUrl = await getDownloadURL(ref(storage, '/user_images/noimage.jpg'));
                  return gameUrl;
              }
          })
      );
      setappIDUserImages(images);
      localStorage.setItem('appIDUserImages',JSON.stringify(images));

      


  } catch (error) {
      console.error(error);
  }
};

//検索ボタンを押してデータを取ってくる
const search_appuser = async (event) => {
  // 文字が入ってなかったらエラーになるためのtry catch
  try{
    setIsSearching(true)
    await SearchappUser();
    await SearchappIDUser();
    localStorage.setItem('search_text',appusername)
    setIsSearching(false)
  }catch{
    return
  } 
};

//クリックしたゲーム名をusestateに格納
const clickgamename = async (index,click_gamename,gameid) => {
}
//ユーザー検索
const handleKeyDown = async(event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); // デフォルトのEnterキー動作を防止する
    search_appuser();

  }
};




//================================================================
  return (
    <div className="min-h-screen bg-gray-100">
    <Sidebar />
    <main class="flex-1 md:ml-64 p-4 md:p-8 pb-20 md:pb-8">
      <div className="max-w-3xl mx-auto">
        <div className="mb-6">
          <div className="relative">
            <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder={t('search')}
              value={appusername} 
              onChange={(event) => setappusername(event.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              onClick={search_appuser}
              // disabled={isLoading}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 px-4 py-1 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:bg-indigo-300"
            >
              {t('search')}
              {/* {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                '検索'
              )} */}
            </button>
          </div>
        </div>
        <div className="flex border-b border-gray-200 mb-6">
          {/* タブ */}
          {tabs.map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`flex items-center px-6 py-3 border-b-2 font-medium text-sm ${
                activeTab === id
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Icon className="w-5 h-5 mr-2" />
              {label}
            </button>
          ))}
        </div>
        
        {activeTab === 'friends' ? (
        <div className="bg-white rounded-lg shadow">
          {isSearching ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">{t('searching')}...</p>
          </div>
        ) :  (
          <div>
          {appuser.length > 0 ? (
            <div>
          {appuser.map((user,index) => (
            <Link to={`/Profile/${user.uuid}`}>
            <div
              key={user.id}
              className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0 hover:bg-gray-100"
            >
              <div className="flex items-center">
                <img
                  src={appuserImages[index]}
                  alt={user.name}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div className="ml-4">
                  <h3 className="font-medium text-gray-900">{user.Username}</h3>
                  <p className="text-sm text-gray-500">@{user.UserID}</p>
                </div>
              </div>
            </div>
            </Link>
          ))}
          </div>
        ):(
          <div className="text-center py-12 bg-white rounded-xl shadow-sm">
          <div className="text-gray-400 mb-4">
            <Search className="h-12 w-12 mx-auto" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900">{t('not_found_user')}</h2>
          <p className="mt-2 text-gray-600">
            {t('No_matching_user_found')}
          </p>
        </div>
        )}
        </div>
        )}
        </div>
      ) : activeTab === 'following' ? (
        <div className="bg-white rounded-lg shadow">
                    {isSearching ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">{t('searching')}...</p>
          </div>
        ) :  (
          <div>
          {appiduser.length > 0 ? (
            <div>
          {appiduser.map((user,index) => (
            <Link to={`/Profile/${user.uuid}`}>
            <div
              key={user.id}
              className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0 hover:bg-gray-100"
            >
              <div className="flex items-center">
                <img
                  src={appIDUserImages[index]}
                  alt={user.name}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div className="ml-4">
                  <h3 className="font-medium text-gray-900">{user.Username}</h3>
                  <p className="text-sm text-gray-500">@{user.UserID}</p>
                </div>
              </div>
            </div>
            </Link>
          ))}
          </div>
          ):(
          <div className="text-center py-12 bg-white rounded-xl shadow-sm">
          <div className="text-gray-400 mb-4">
            <Search className="h-12 w-12 mx-auto" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900">{t('not_found_user')}</h2>
          <p className="mt-2 text-gray-600">
            {t('No_matching_user_found')}
          </p>
        </div>
        )}
                </div>
        )}
        
        </div>
              ) : activeTab === 'followers' ? (
                <div className="bg-white rounded-lg shadow">

                <Gamelistview/>
                </div>


      ) : null}

      </div>
    </main>
    </div>
  )
};

export default NewSearch;