import React, { useState } from 'react'
import { SigninWithEmailAndPassword } from '../firebase';
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAdditionalUserInfo } from 'firebase/auth';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import { Lock, LogIn, Mail } from 'lucide-react';
import { useNotification } from './Notification';

function Mail_Login() {
    const [email2,setEmail] = useState('');
    const [password2,setPassword] = useState('');

    const [mail_error,setmail_error] = useState("");
    const { showNotification } = useNotification();

    
    // const [user] = useAuthState(auth);

    // const Navigate_profile = () => {
    //   if (user && user.emailVerified) {
    //     const pk = user.uid;
    //     localStorage.setItem('login',user.uid)
    //     return <Navigate to={`/Profile/${pk}`} />;
    //   }
    //   return null; // 条件に一致しない場合は null を返す
    // };


    const login = async(event) => {
        event.preventDefault();
        try{
          const userCredential  = await SigninWithEmailAndPassword(email2,password2);
          // showNotification('ログイン成功!', 'success');
          return <Navigate to='/login' />

          
        }catch(error){
          switch (error.code) {
            case 'auth/invalid-email':
              setmail_error('auth/invalid-email');
              showNotification('auth/invalid-email', 'error');
              break;
            case 'auth/user-disabled':
              setmail_error('auth/user-disabled');
              showNotification('auth/user-disabled', 'error');
              break;
            case 'auth/user-not-found':
              setmail_error('auth/user-not-found');
              showNotification('auth/user-not-found', 'error');
              break;
            case 'auth/wrong-password':
              setmail_error('auth/wrong-password');
              showNotification('auth/wrong-password', 'error');
              break;
            case 'auth/email-already-in-use':
              setmail_error('auth/email-already-in-use');
              showNotification('auth/email-already-in-use', 'error');
              break;
            case 'auth/operation-not-allowed':
              setmail_error('auth/operation-not-allowed');
              showNotification('auth/operation-not-allowed', 'error');
              break;
            case 'auth/weak-password':
              setmail_error('auth/weak-password');
              showNotification('auth/weak-password', 'error');
              break;
            case 'auth/network-request-failed':
              setmail_error('auth/network-request-failed');
              showNotification('auth/network-request-failed', 'error');
              break;
            default:
              setmail_error('default');
              showNotification('default', 'error');
          }}
        }

  return (
    <div>
      {/* <h2>{t('Login')}</h2> */}
      {/* {mail_error} */}
      {/* {t(mail_error)} */}
        {/* <form onSubmit={login}> */}
        {/* <div>
          <TextField sx={{width: '100%'}} type='text'  variant="outlined" value={email2} placeholder={t('mailaddress')} onChange={(event) => setEmail(event.target.value)}/>
        </div>
        <div>
          <TextField sx={{width: '100%'}} type='password'  variant="outlined" value={password2} placeholder={t('password')} onChange={(event) => setPassword(event.target.value)}/>
        </div>
        <p 
  className='bg-[rgb(79,70,229)] text-white w-[100%] text-center py-2 mx-auto cursor-pointer mt-[10px] transition duration-200 ease-in-out hover:bg-[rgb(59,50,189)]' 
  onClick={login}
>
  {t('Login')} */}
{/* </p>    */}
 {/* </form> */}
 <form  className="space-y-6">
            <div>
              {/* <Navigate_profile/> */}
              <label className="block text-sm font-medium text-gray-700">
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  required
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="your@email.com"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
              </label>
              <div className="mt-1 relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="password"
                  required
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="••••••••"
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={login}
              >
                {t('Login')}
              </button>
            </div>
          </form>
    </div>
  )
};

export default Mail_Login;