import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export function CTA() {
    const { t,i18n } = useTranslation();
  
  return (
    <section className="bg-indigo-600 py-20 mt-20">
  <div className="container mx-auto px-6 text-center">
    <h2 className="text-3xl font-bold text-white mb-8">
      {t("start_now")}
    </h2>
    <p className="text-indigo-100 mb-8 max-w-2xl mx-auto">
      {t("start_now_desc")}
    </p>
    <Link to="/login">
      <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg hover:bg-indigo-50 transition">
        {t("create_account")}
      </button>
    </Link>
  </div>
</section>
    // <section className="bg-indigo-600 py-20 mt-20">
    //   <div className="container mx-auto px-6 text-center">
    //     <h2 className="text-3xl font-bold text-white mb-8">
    //       今すぐIDNectを始めましょう
    //     </h2>
    //     <p className="text-indigo-100 mb-8 max-w-2xl mx-auto">
    //       無料で始められます。面倒なゲームID管理から解放されて、
    //       もっとゲームを楽しみましょう。
    //     </p>
    //     <Link to='/login'>
    //       <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg hover:bg-indigo-50 transition">
    //         アカウントを作成
    //       </button>
    //     </Link>
    //   </div>
    // </section>
  );
}