import React, { memo, useMemo, useState } from 'react'
import { SignInButton, SignOutButton } from '../Home';
import Google_login_button from './google_login_button'
import Mail_Login from './Mail_Login';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import './login.css';
import rogo from '../img/IDnest.jpeg'

import { BrowserRouter as Router, Navigate, Route, RouteProps, Switch, Link } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { createUserWithEmailAndPassword, getAdditionalUserInfo, sendEmailVerification, signOut } from 'firebase/auth';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import Remail from './Remail';
import Login_Reset_password from './Login_Reset_password';
import { useTranslation } from 'react-i18next';
import { SignInWithApple } from './SignInWithApple';
import Iosapp_open from './Iosapp_open';
import { Eye, EyeOff, KeyRound, Lock, LogIn, Mail, User, X } from 'lucide-react';
import appimage from '../img/web_design2.001.jpeg';
import googleplay_download from "../img/google_play.jpeg";
import { ReactComponent as Apple_download } from '../img/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import { AlertCircle, CheckCircle2 } from 'lucide-react';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [email, setemail] = React.useState();
  const [password, setpassword] = React.useState();
  const [username, setUsername] = React.useState();
  const [message, setmessage] = React.useState();
  const [user, loading, error] = useAuthState(auth);
  const [mail_user_create_error,setmail_user_create_error] = useState()

  // パスワードを見る目
  const [showPassword, setShowPassword] = useState(false)
  const [Password, setPassword] = useState('')

  localStorage.setItem('page','login');


  const { t,i18n } = useTranslation();

  const Navigate_profile = () => {
    
    if (user && user.emailVerified) {
      const pk = user.uid;
      localStorage.setItem('login',user.uid)
      return <Navigate to={`/Profile/${pk}`} />;
    }
    return null; // 条件に一致しない場合は null を返す
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // 通知アニメーション
    const [notifications, setNotifications] = useState([]);
  
    const showNotification = (message,type) => {
      const newNotification = {
        id: Date.now(),
        message: type === 'success' 
          ? t(message)
          : t(message),
        type
      };
      setNotifications((prev) => [...prev, newNotification]);
  
      // 5秒後に消えるアニメーションを開始
      setTimeout(() => {
        setNotifications((prev) => 
          prev.map((n) => 
            n.id === newNotification.id 
              ? { ...n, isExiting: true }
              : n
          )
        );
        
        // アニメーション完了後に実際に削除
        setTimeout(() => {
          setNotifications((prev) => prev.filter((n) => n.id !== newNotification.id));
        }, 300); // アニメーションの時間と同じ
      }, 5000);
    };
  
    const removeNotification = (id) => {
      // まずアニメーションを開始
      setNotifications((prev) => 
        prev.map((n) => 
          n.id === id 
            ? { ...n, isExiting: true }
            : n
        )
      );
      
      // アニメーション完了後に実際に削除
      setTimeout(() => {
        setNotifications((prev) => prev.filter((n) => n.id !== id));
      }, 300);
    };
    // ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
  const account_regist = async(event) => {
    event.preventDefault();
    // パスワードが一緒ではない
    if (password !== Password) {
      await setmail_user_create_error('not_the_same_password')
      showNotification('not_the_same_password','error')
    } else {
      const user = await SignupWithEmailAndPassword(email,password,username);
      if(user){
        setOpen(false);
      }
    }
    }

      const SignupWithEmailAndPassword = async(email,password,username) => {
        // const [error,seterror] = useState()

        if (email.trim().length > 0 && password.trim().length > 0 && username.trim().length > 0) {
        try {
          const user = await createUserWithEmailAndPassword(auth, email, password)
          const isNewUser = getAdditionalUserInfo(user)
          const uid = user.user.uid
          await sendEmailVerification(user.user);
      
          if(user && isNewUser){
            //UserInfo
            const UserInfoRef = doc(db, 'UserInfo',uid);
            setDoc(UserInfoRef,{
              Username: username ,
              Username_lower:username.toLowerCase(),
              UserID: uid ,
              UserID_lower:uid.toLowerCase(),
              introduction : '' ,
              uuid: uid ,
              hidden:false,
              createdAt: serverTimestamp()
            });
            //SNS
            const SNSRef = doc(db, 'SNS',uid);
            setDoc(SNSRef,{
              'Discord':'',
              'Tiktok':'',
              'Twitch':'',
              'X':'',
              'Youtube':'',
              'Instagram':'',
              'Facebook':'',
              "uuid": uid ,
            });
            //Friends
            const FriendsRef = doc(db, 'Friends',uid);
            setDoc(FriendsRef,{
              follower:[],
              following: [],
              friend: [],
              uuid: uid,
            });
            const blockRef = doc(db,'block',uid);
            setDoc(blockRef,{
              blocked:[],
              blocking: [],
              "uuid": uid ,
            });
            const deviceRef = doc(db,'Device',uid);
            setDoc(deviceRef,{
              device:'pc',
            });
          }
          const sendmailmassage = email+'に認証メールを送信しました。メール認証完了後、ログインをすることが可能になります。'
          setmail_user_create_error(sendmailmassage)
          showNotification("認証メールを送信しました。",'success')
      
          return user;
        } catch (error) {
          // alert('エラーが発生しました');
          switch (error.code) {
            case 'auth/invalid-email':
              await setmail_user_create_error('auth/invalid-email');
              showNotification('auth/invalid-email','error')
              break;
            case 'auth/user-disabled':
              await setmail_user_create_error('auth/user-disabled');
              showNotification('auth/user-disabled','error')
              break;
            case 'auth/user-not-found':
              await setmail_user_create_error('auth/user-not-found');
              showNotification('auth/user-not-found','error')
              break;
            case 'auth/wrong-password':
              await setmail_user_create_error('auth/wrong-password');
              showNotification('auth/wrong-password','error')
              break;
            case 'auth/email-already-in-use':
              await setmail_user_create_error('auth/email-already-in-use');
              showNotification('auth/email-already-in-use','error')
              break;
            case 'auth/operation-not-allowed':
              await setmail_user_create_error('auth/operation-not-allowed');
              showNotification('auth/operation-not-allowed','error')
              break;
            case 'auth/weak-password':
              await setmail_user_create_error('auth/weak-password');
              showNotification('auth/weak-password','error')
              break;
            case 'auth/network-request-failed':
              await setmail_user_create_error('auth/network-request-failed');
              showNotification('auth/network-request-failed','error')
              break;
            default:
              await setmail_user_create_error('default');
              showNotification('default','error')
          }

          return;
        }

      }else{
          setmail_user_create_error('必要事項を入力してください')
          showNotification('必要事項を入力してください','error')

        }

        try{
          await signOut(auth);
        }catch(error){
          console.log('logouterror')
        }

      };

  return (
<div className="min-h-screen flex">
        {/* 通知 - レスポンシブ対応 */}
        <div className="fixed top-0 right-0 z-50 p-4 space-y-2 max-w-full sm:top-4 sm:right-4 sm:p-0">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`
                p-3 sm:p-4 w-full sm:w-80 rounded shadow-lg mx-auto
                ${notification.isExiting ? 'animate-slide-out' : 'animate-slide-in'}
                ${notification.type === 'success' 
                  ? 'bg-green-50 border-l-4 border-green-500' 
                  : 'bg-red-50 border-l-4 border-red-500'}
              `}
            >
              <div className="flex items-start gap-2 sm:gap-3">
                <div className="flex-shrink-0">
                  {notification.type === 'success' ? (
                    <CheckCircle2 className="h-4 w-4 sm:h-5 sm:w-5 text-green-500" />
                  ) : (
                    <AlertCircle className="h-4 w-4 sm:h-5 sm:w-5 text-red-500" />
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <p className={`text-xs sm:text-sm break-words
                    ${notification.type === 'success' ? 'text-green-700' : 'text-red-700'}
                  `}>
                    {notification.message}
                  </p>
                </div>
                <button
                  onClick={() => removeNotification(notification.id)}
                  className={`
                    flex-shrink-0 inline-flex text-gray-400 hover:text-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full p-0.5
                    ${notification.type === 'success' 
                      ? 'focus:ring-green-500' 
                      : 'focus:ring-red-500'}
                  `}
                  aria-label="閉じる"
                >
                  <X className="h-4 w-4 sm:h-5 sm:w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      {/* 左側: ロゴとブランディング */}
      <div className="hidden lg:flex lg:w-1/2  items-center justify-center">
        <div className="text-center">

          <img src={appimage} alt='appimage'/>
        </div>
      </div>
      {/* 右側: ログインフォーム */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
      <div className="w-full max-w-md">
        <div className="text-center mb-8">
          <Navigate_profile />

          {/* <Logo /> */}
          <Link to='/'><img className='profile_rogo' src={rogo}/></Link>

          <h2 className="text-2xl font-semibold text-gray-900 mt-3">{t('Login')}</h2>
          <p className="text-gray-600 mt-2">{t('Login_text')}</p>
          {/* <div className='error_message'>{t(mail_user_create_error)}</div> */}

        </div>

        <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
          <form  className="space-y-6">

            <Mail_Login />
            
            <div className="flex items-center justify-between text-sm">
              <Login_Reset_password/>
            </div>
          </form>

          <div className="relative my-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">{t('or')}</span>
            </div>
          </div>
          <Google_login_button />


          <div className="mt-6  space-y-2">
            <p className="text-sm text-gray-600">
              {t('not_have_account')}{' '}
              <a href="#" className="text-indigo-600 hover:text-indigo-500 font-medium" onClick={handleClickOpen}>
                {t('New_Registration')}
              </a>
            </p>
            {open && (
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                onClick={handleClose}
              >
                <div 
                  className="bg-white rounded-lg p-6 w-full max-w-md"
                  onClick={e => e.stopPropagation()}
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{t('New_Registration')}</h2>
                    <button
                      onClick={handleClose}
                      className="p-1 hover:bg-gray-100 rounded-full"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                  <form  className="space-y-4">
              <div>
                  <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">
                    {t('username')}
                  </label>
                  <div className="relative">
                    <User className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <input
                      type="text"
                      id="username"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={t('username')}
                      onChange={(event) => setUsername(event.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    {t('mailaddress')}
                  </label>
                  <div className="relative">
                    <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <input
                      type="email"
                      id="email"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="example@email.com"
                      onChange={(event) => setemail(event.target.value)}
                    />
                  </div>
                </div>
                
                {/* <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                    {t('password')}
                  </label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 h-5 w-5" />
                    <input
                      type="password"
                      id="password"
                      required
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="••••••••"
                      onChange={(event) => setpassword(event.target.value)}
                    />
                  </div>
                </div> */}
                <>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                    {t('password')}
                  </label>
                  <div className="relative">
                    <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                      <Lock className="w-5 h-5" />
                    </div>
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      value={Password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full pl-11 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                      placeholder={t('password')}
                      autoComplete="new-password"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 transition-colors"
                      aria-label={showPassword ? 'パスワードを隠す' : 'パスワードを表示'}
                    >
                      {showPassword ? (
                        <EyeOff className="w-5 h-5" />
                      ) : (
                        <Eye className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  </>

                  <>
                  <div className="relative">
                    <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                      <Lock className="w-5 h-5" />
                    </div>
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      required
                      onChange={(e) => setpassword(e.target.value)}
                      className="w-full pl-11 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                      placeholder={t('re_password')}
                      autoComplete="new-password"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 transition-colors"
                      aria-label={showPassword ? 'パスワードを隠す' : 'パスワードを表示'}
                    >
                      {showPassword ? (
                        <EyeOff className="w-5 h-5" />
                      ) : (
                        <Eye className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  </>

                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
                    onClick={account_regist}
                  >
                    {t('regist')}
                  </button>
                </div>
                </form>
                  </div>
                </div>
            )}
            <Remail/>
            <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8 text-center">
              <a href="https://apps.apple.com/jp/app/idnect/id6670595423?">
                <Apple_download style={{ width: '150px', height: 'auto' }} />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.IDNect.idnect">
                <img src={googleplay_download} style={{ width: '162px', height: 'auto' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Login;
