import React, { createContext, useContext, useState } from 'react';
import { AlertCircle, CheckCircle2, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
    const { t,i18n } = useTranslation();
  

  const showNotification = (message, type) => {
    const newNotification = {
      id: Date.now(),
      message: type === 'success' 
        ? t(message)
        : t(message),
      type
    };
    setNotifications((prev) => [...prev, newNotification]);

    // 5秒後に消えるアニメーションを開始
    setTimeout(() => {
      setNotifications((prev) => 
        prev.map((n) => 
          n.id === newNotification.id 
            ? { ...n, isExiting: true }
            : n
        )
      );
      
      // アニメーション完了後に実際に削除
      setTimeout(() => {
        setNotifications((prev) => prev.filter((n) => n.id !== newNotification.id));
      }, 300); // アニメーションの時間と同じ
    }, 5000);
  };

  const removeNotification = (id) => {
    // まずアニメーションを開始
    setNotifications((prev) => 
      prev.map((n) => 
        n.id === id 
          ? { ...n, isExiting: true }
          : n
      )
    );
    
    // アニメーション完了後に実際に削除
    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 300);
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <div className="fixed top-0 right-0 z-50 p-4 space-y-2 max-w-full sm:top-4 sm:right-4 sm:p-0">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className={`
              p-3 sm:p-4 w-full sm:w-80 rounded shadow-lg mx-auto
              ${notification.isExiting ? 'animate-slide-out' : 'animate-slide-in'}
              ${notification.type === 'success' 
                ? 'bg-green-50 border-l-4 border-green-500' 
                : 'bg-red-50 border-l-4 border-red-500'}
            `}
          >
            <div className="flex items-start gap-2 sm:gap-3">
              <div className="flex-shrink-0">
                {notification.type === 'success' ? (
                  <CheckCircle2 className="h-4 w-4 sm:h-5 sm:w-5 text-green-500" />
                ) : (
                  <AlertCircle className="h-4 w-4 sm:h-5 sm:w-5 text-red-500" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <p className={`text-xs sm:text-sm break-words
                  ${notification.type === 'success' ? 'text-green-700' : 'text-red-700'}
                `}>
                  {notification.message}
                </p>
              </div>
              <button
                onClick={() => removeNotification(notification.id)}
                className={`
                  flex-shrink-0 inline-flex text-gray-400 hover:text-gray-500 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-full p-0.5
                  ${notification.type === 'success' 
                    ? 'focus:ring-green-500' 
                    : 'focus:ring-red-500'}
                `}
                aria-label="閉じる"
              >
                <X className="h-4 w-4 sm:h-5 sm:w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </NotificationContext.Provider>
  );
}
