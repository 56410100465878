import React from 'react';
// import { useTranslation } from 'react-i18next';
import image1 from '../../../img/スクリーンショット 2025-02-20 035758.png';
import image2 from '../../../img/スクリーンショット 2025-02-20 174156.png';
import image3 from '../../../img/スクリーンショット 2025-02-20 153816.png';
import image4 from '../../../img/スクリーンショット 2025-02-20 154555.png';
import { useTranslation } from 'react-i18next';

function How_to_use() {
  const { t } = useTranslation();

  return (
    <div className="bg-white py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-16">
          {t('how_to_use')}
        </h2>

        <div className="max-w-6xl mx-auto">
          {/* Step 1 - Image Left */}
          <div className="flex flex-col md:flex-row items-center mb-24 gap-12">
            <div className="w-full md:w-1/2 flex justify-center">
              <div className="w-full max-w-[370px] aspect-square">
                <img
                  src={image3}
                  alt={t('step_1_alt')}
                  className="rounded-lg shadow-xl w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="text-5xl font-bold text-blue-600 mb-4">01</div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {t('step_1_title')}
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_1_description_1')}
              </p>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_1_description_2')}
              </p>
            </div>
          </div>

          {/* Step 2 - Image Right */}
          <div className="flex flex-col-reverse md:flex-row items-center mb-24 gap-12">
            <div className="w-full md:w-1/2">
              <div className="text-5xl font-bold text-blue-600 mb-4">02</div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {t('step_2_title')}
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_2_description_1')}
              </p>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_2_description_2')}
              </p>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_2_description_3')}
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <div className="w-full max-w-[500px] aspect-[5/3.7]">
                <img
                  src={image1}
                  alt={t('step_2_alt')}
                  className="rounded-lg shadow-xl w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          {/* Step 3 - Image Left */}
          <div className="flex flex-col md:flex-row items-center gap-12 mb-24">
            <div className="w-full md:w-1/2 flex justify-center">
              <div className="w-full max-w-[500px] aspect-[5/2]">
                <img
                  src={image4}
                  alt={t('step_3_alt')}
                  className="rounded-lg shadow-xl w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="text-5xl font-bold text-blue-600 mb-4">03</div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {t('step_3_title')}
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_3_description_1')}
              </p>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_3_description_2')}
              </p>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_3_description_3')}
              </p>
            </div>
          </div>

          {/* Step 4 - Image Right */}
          <div className="flex flex-col-reverse md:flex-row items-center mb-24 gap-12">
            <div className="w-full md:w-1/2">
              <div className="text-5xl font-bold text-blue-600 mb-4">04</div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {t('step_4_title')}
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_4_description_1')}
              </p>
              <p className="text-gray-600 text-lg leading-relaxed">
                {t('step_4_description_2')}
              </p>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <div className="w-full max-w-[500px] aspect-[7/3]">
                <img
                  src={image2}
                  alt={t('step_4_alt')}
                  className="rounded-lg shadow-xl w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default How_to_use;
