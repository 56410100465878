import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { collection, endAt, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import '../Newcss/NewGameSearch.css';
import { Search, Users } from 'lucide-react';
import { Sidebar } from './components_profile/Sidebar';
import { useTranslation } from 'react-i18next';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({userImageUrl}) {
    const { pk } = useParams();
    const { t } = useTranslation();
    

  const [value, setValue] = useState(0);
  const [gameinfo, setgameinfo] = useState([]);
  const [appusername, setappusername] = useState([]);

  const [UserInfo,setUserInfo] = useState([]);
  const [userImages, setUserImages] = useState([]);
  const [IDuser, setIDuser] = useState([]);
  const [IDuserImages, setIDUserImages] = useState([]);

  console.log('kensaku')
  console.log(UserInfo)
  console.log(IDuser)

  const [loading, setloading] = useState(false);
//タブ
  const [activeTab, setActiveTab] = useState('friends');
const tabs = [
  { id: 'friends', label: t('username'), icon: Users },
  { id: 'following', label: t('userid'), icon: Users },
];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('gamesearch_tab',newValue)
  };

  useEffect(() => {
    //タブ１
    const gamesearch_tab = localStorage.getItem('gamesearch_tab')
    if(gamesearch_tab !== null){
      setValue(Number(gamesearch_tab))
    }

    const gamenameuser_json = localStorage.getItem(`gamenameuser_${pk}`);
    if(gamenameuser_json !== null){
      const gamenameuser = JSON.parse(gamenameuser_json)
      setUserInfo(gamenameuser)
    }
    const gamenameuser_img_json = localStorage.getItem(`gamenameuser_img_${pk}`);
    if(gamenameuser_img_json !== null){
      const gamenameuser_img = JSON.parse(gamenameuser_img_json)
      setUserImages(gamenameuser_img)
    }

    const gameiduser_json = localStorage.getItem(`gameiduser_${pk}`);
    if(gameiduser_json !== null){
      const gameiduser = JSON.parse(gameiduser_json)
      setIDuser(gameiduser)
    }
    const gameiduser_img_json = localStorage.getItem(`gameiduser_img_${pk}`);
    if(gameiduser_img_json !== null){
      const gameiduser_img = JSON.parse(gameiduser_img_json)
      setIDUserImages(gameiduser_img)
    }
  },[])

  useEffect(() => {
    const fetchData = async () => {
      //プロフィール
      const userinfoQuery = query(collection(db, 'PlayerGames'), where('gameid', '==', pk), orderBy("createdAt"),limit(1));
      const userinfoQuerySnapshot = await getDocs(userinfoQuery);
      setgameinfo(userinfoQuerySnapshot.docs.map(doc => ({ ...doc.data() })));
    }
    fetchData();
  },[])



    //=======================ゲームユーザー検索======================
    const SearchUser = async () => {
      try {
        const userQuery = query(
          collection(db, 'PlayerGames'),
          where('gameid', '==', pk), 
          orderBy('gameusername'),startAt(appusername),endAt(appusername + '\uf8ff'),limit(50)
        );  
        const userSnapshot = await getDocs(userQuery);
        setUserInfo(userSnapshot.docs.map(doc => ({ ...doc.data() })));
        localStorage.setItem(`gamenameuser_${pk}`,JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))
  
        const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));
  
        const images = await Promise.all(
          userlist.map(async (uuid) => {
            try {
              const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
              return gameUrl;
            } catch (error) {
              const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + 'noimage' + '.jpg'));
              return gameUrl;
            }
          })
        );
  
        setUserImages(images);
        localStorage.setItem(`gamenameuser_img_${pk}`,JSON.stringify(images));
  
        
        //ゲーム画像
      //   const gameUrl = await getDownloadURL(ref(storage, '/ゲームイメージ/' + SearcGame + '.jpeg'));
      //   setGameImages(gameUrl);
  
      } catch (error) {
        console.error('ユーザーデータの取得エラー:', error);
      }
    };
    //================================ゲームID検索===================================================

const SearchIDUser = async () => {
  try {
    const userQuery = query(
      collection(db, 'PlayerGames'),
      where('gameid', '==', pk), 
      orderBy('gameuserid'),
      startAt(appusername),
      endAt(appusername + '\uf8ff'),
      limit(30)
    );  
    const userSnapshot = await getDocs(userQuery);
    setIDuser(userSnapshot.docs.map(doc => ({ ...doc.data() })));
    localStorage.setItem(`gameiduser_${pk}`,JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))

    const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));

    const images = await Promise.all(
      userlist.map(async (uuid) => {
        try {
          const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
          return gameUrl;
        } catch (error) {
          const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + 'noimage' + '.jpg'));
          return gameUrl;
        }
      })
    );

    setIDUserImages(images);
    localStorage.setItem(`gameiduser_img_${pk}`,JSON.stringify(images));


    
    //ゲーム画像
    // const gameUrl = await getDownloadURL(ref(storage, '/ゲームイメージ/' + SearcGame + '.jpeg'));
    // setGameImages(gameUrl);

  } catch (error) {
    console.error('ユーザーデータの取得エラー:', error);
  }
};
const search_user = async(event) => {
  // event.preventDefault();
  setloading(true)
  await SearchUser();
  await SearchIDUser();
  setloading(false)
  };

  const handleKeyDown_gameuser = async(event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // デフォルトのEnterキー動作を防止する
      search_user();
    }
  };
    //================================================================================
  return (
    <div className="min-h-screen bg-gray-100">
    <Sidebar />
    <main class="flex-1 md:ml-64 p-4 md:p-8 pb-20 md:pb-8">
      <div className="max-w-3xl mx-auto">
      <div className="mb-6">
        <div className="relative flex items-center">
          <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder={t('search')}
            value={appusername} 
            onChange={(event) => setappusername(event.target.value)}
            onKeyDown={handleKeyDown_gameuser}
            className="w-full pl-10 pr-[200px] py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          
          {/* 検索ボタンの左側にゲーム画像を表示 */}
          <div className="absolute right-[100px] top-1/2 transform -translate-y-1/2 flex items-center">
          {gameinfo.map((game,index) => (
              <img className='w-10 h-10 rounded-lg object-cover border border-gray-200' src={game.gameimgurl}/>
            ))}
          </div>

          <button
            className="absolute right-3 top-1/2 transform -translate-y-1/2 px-4 py-1 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:bg-indigo-300"
            onClick={search_user}
          >
            {t('search')}
          </button>
        </div>
      </div>
        <div className="flex border-b border-gray-200 mb-6">
          {/* タブ */}
          {tabs.map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`flex items-center px-6 py-3 border-b-2 font-medium text-sm ${
                activeTab === id
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              <Icon className="w-5 h-5 mr-2" />
              {label}
            </button>
          ))}
        </div>
        {activeTab === 'friends' ? (
          <div className="bg-white rounded-lg shadow">
          {loading ? (
            <div className="text-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
              <p className="mt-4 text-gray-600">{t('searching')}...</p>
            </div>
          ) : UserInfo.length > 0 ? (
            <div>
              {UserInfo.map((user, index) => (
                <Link to={`/Profile/${user.uuid}`} key={user.id}>
                  <div className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0 hover:bg-gray-100"
                  >
                    <div className="flex items-center">
                      <img
                        src={userImages[index]}
                        alt={user.name}
                        className="w-12 h-12 rounded-full object-cover"
                      />
                      <div className="ml-4">
                        <h3 className="font-medium text-gray-900">{user.gameusername}</h3>
                        <p className="text-sm text-gray-500">@{user.gameuserid}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="text-center py-12 bg-white rounded-xl shadow-sm">
              <div className="text-gray-400 mb-4">
                <Search className="h-12 w-12 mx-auto" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900">{t('not_found_user')}</h2>
              <p className="mt-2 text-gray-600">{t('No_matching_user_found')}</p>
            </div>
          )}
        </div>
      ) : activeTab === 'following' ? (
        <div className="bg-white rounded-lg shadow">
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">{t('searching')}...</p>
          </div>
        ) : IDuser.length > 0 ? (
          <div>
            {IDuser.map((user, index) => (
              <Link to={`/Profile/${user.uuid}`} key={user.id}>
                <div className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0 hover:bg-gray-100"
                >
                  <div className="flex items-center">
                    <img
                      src={IDuserImages[index]}
                      alt={IDuserImages[index]}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                    <div className="ml-4">
                      <h3 className="font-medium text-gray-900">{user.gameusername}</h3>
                      <p className="text-sm text-gray-500">@{user.gameuserid}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white rounded-xl shadow-sm">
            <div className="text-gray-400 mb-4">
              <Search className="h-12 w-12 mx-auto" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">{t('not_found_user')}</h2>
            <p className="mt-2 text-gray-600">{t('No_matching_user_found')}</p>
          </div>
        )}
      </div>


      ) : null}

      </div>
    </main>
    </div>

  );
}