import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function Image_loop() {
    const { t,i18n } = useTranslation();
  
  const images = [
    "https://images.igdb.com/igdb/image/upload/t_thumb/co7j43.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co2ekt.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co49wj.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co1wzo.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co904o.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co49x5.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co6996.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co480t.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co3cwt.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co4eiu.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co3uzk.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co5zky.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co7n02.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co2mvt.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co5vst.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co75h8.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co3wid.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co20xa.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co4w4j.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co24nf.jpg",
    "https://images.igdb.com/igdb/image/upload/t_thumb/co6ene.jpg",
  ];

  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    let scrollAmount = 0;
    const scrollSpeed = 0.5; // スクロールの速さ（数値を調整可能）

    const scrollImages = () => {
      scrollAmount += scrollSpeed;
      if (scrollAmount >= carousel.scrollWidth / 2) {
        scrollAmount = 0; // 最初に戻る
      }
      carousel.style.transform = `translateX(${-scrollAmount}px)`;
      requestAnimationFrame(scrollImages);
    };

    const clonedContent = carousel.innerHTML; // 子要素を複製
    carousel.innerHTML += clonedContent;

    requestAnimationFrame(scrollImages);

    return () => {
      cancelAnimationFrame(scrollImages);
    };
  }, []);

  return (
    <div>
<h1 className="text-center text-2xl font-bold mb-10">
  {t("register_games")}
</h1>
      <div className="flex items-center justify-center p-8 m-0">
        <div className="w-full max-w-6xl overflow-hidden rounded-xl">
          <div
            ref={carouselRef}
            style={{
              display: "flex",
              willChange: "transform",
            }}
          >
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  flexShrink: 0,
                  width: "80px",
                  height: "80px",
                  margin: "0 5px",
                }}
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Image_loop;
