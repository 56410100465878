import { arrayRemove, arrayUnion, collection, doc, getDocs, orderBy, query, runTransaction,  serverTimestamp,  Timestamp,  updateDoc,  where, writeBatch } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link,  useNavigate,  useParams } from 'react-router-dom'
import { auth, db, storage } from '../../firebase';
import Registbutton from '../regist_button';
import Deletegamebutton from '../delete_game_button';
import Editbutton from '../edit_button';
import Editprofilebutton from '../edit_profile';
import { getDownloadURL, ref } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord, faTwitch, faYoutube, faTiktok, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

import '../Newcss/NewProfile.css';
import '../Newcss/GameRegist_id.css';

import SNS_2 from '../SNS_2';


import { CircularProgress, ClickAwayListener, Skeleton, Tooltip } from '@mui/material';

import Linkify from 'react-linkify';
import { useTranslation } from 'react-i18next';

import rogo from '../IDnest.jpeg'
import { Helmet } from 'react-helmet-async';

import { Sidebar } from '../New/components_profile/Sidebar';

import { Gamepad2, ChevronDown, ChevronUp, Copy, Check, Ellipsis, GripVertical, Play, MessageCircleMore, IdCard, X, Trash2, Search, Pencil, UserX } from 'lucide-react';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { useSensors, useSensor, PointerSensor, KeyboardSensor } from '@dnd-kit/core';
import { sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './components_profile/SortableItem';
import { useNotification } from '../Notification';
import { useAuthState } from 'react-firebase-hooks/auth';




//改行のため
function formatTextWithLineBreaks(text) {
  return text.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}

function NewProfile({
  UserInfo,
  profile_sns,
  profile_block,
  PlayerGames,
  GameImages,
  userImageUrl,
  suggest,
  follow_user,
  follower_user,
  friend_user,
  common,
  handleImageChange,
  imageKey}) {

    const { pk } = useParams();
    const localuuid = localStorage.getItem('login');
    localStorage.setItem('page','profile');
    //言語設定
    const { t } = useTranslation();
    const { showNotification } = useNotification();
    const [user] = useAuthState(auth);









    // if(localuuid === null){
    //   const remove_app = () => {
    //     navigate('/');
    //   }
    //   remove_app()
    // }
    

    //他のユーザーのもの
    const [another_userinfo,setanother_userinfo] = useState([]);
    const [another_PlayerGames,setanother_PlayerGames] = useState([]);
    const [another_sns,setanother_sns] = useState([]);
    const [another_userImageUrl, setanother_userImageUrl] = useState('');
    const [loading,setloading] = useState(true)

    const [Nouser_check,setNouser_check] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);
    const [truepk, settruepk] = useState(null);
    const navigate = useNavigate();


    const isNewUser = localStorage.getItem('isNewUser')



const [expandedGame, setExpandedGame] = useState(null);
const [copiedField, setCopiedField] = useState(null);

const handleCopy = (text,field) => {
  navigator.clipboard.writeText(text);
  setCopiedField(field);
  setTimeout(() => setCopiedField(null), 2000);
};

const toggleExpand = (gameId) => {
  setExpandedGame(expandedGame === gameId ? null : gameId);
};


  
    const handleClick = (event, index) => {
      setAnchorEl(event.currentTarget);
      setOpenIndex(index === openIndex ? null : index); // 既に開いている場合は閉じる
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setOpenIndex(null);
    };
      //dialogスクロール制御
          const [isEditModalOpen, setIsEditModalOpen] = useState(false);
      useEffect(() => {
        if (isEditModalOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
    
        // クリーンアップ関数
        return () => {
          document.body.style.overflow = 'unset';
        };
      }, [isEditModalOpen]);
    //メニューダイアログ
    const [posts, setPosts] = useState()

  
    const [newPost, setNewPost] = useState("")
    const [selectedPost, setSelectedPost] = useState(null)
    const [menuDialogOpen, setmenuDialogOpen] = useState(false)
  
    const handleSubmit = (e) => {
      e.preventDefault()
      if (!newPost.trim()) return
  
      const post = {
        id: posts.length + 1,
        author: "ユーザー",
        content: newPost,
        timestamp: new Date().toLocaleString()
      }
  
      setPosts([post, ...posts])
      setNewPost("")
    }
  
    const handleDelete = (id) => {
      setPosts(posts.filter(post => post.id !== id))
      setmenuDialogOpen(false)
    }
  
    const handleEdit = (post) => {
      // 編集機能の実装（今回は省略）
      setmenuDialogOpen(false)
    }
  
    const closeDialog = () => {
      setmenuDialogOpen(false)
      setSelectedPost(null)
    }

    console.log('aaaaaaaaaazyoho')
    console.log(selectedPost)



    //コピーしました。====名前======================
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };

          //読み込み時画像
          const Anotherimg = () => {
            if(loading){
              return(
                <Skeleton variant="circular" className='newprofile_userimage' width={80} height={80} />
              )
            }else{
              return(
                <img className='newprofile_userimage' alt='' src={another_userImageUrl}/>
              )
            }
          }

    //コピー
    const copyToClipboard = async (text) => {
      
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Clipboard APIを使用（モダンブラウザ用）
        navigator.clipboard.writeText(text).then(() => {
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // フォールバック方法（古いブラウザやSafari用）
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // スクロールを防ぐために必要
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = "0";
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
          const successful = document.execCommand('copy');
          if (successful) {
          } else {

          }
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
    
        document.body.removeChild(textArea);
      }
      handleTooltipOpen();
    };
    //並び替え
    const [tempTodos, setTempTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    const handleOpenDialog = () => {
      setTempTodos([...PlayerGames]);
      setIsDialogOpen(true);
      setIsEditModalOpen(true)
    };
  
    const handleCloseDialog = (save) => {
      if (save) {
        setNewTodo([...tempTodos]);
      }

      setIsDialogOpen(false);
      setIsEditModalOpen(false)

    };
  
    const handleDragEnd = (event) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        setTempTodos((items) => {
          const oldIndex = items.findIndex((item) => item.id === active.id);
          const newIndex = items.findIndex((item) => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      }
    };



      const handleUpdate = async(event) => {
        event.preventDefault();
        setIsEditModalOpen(false)
        handleCloseDialog(true);
          // Firestore のバッチを作成
          const batch = writeBatch(db);

          // newTodo の各ゲームに対して更新処理を追加
          tempTodos.map((game, index) => {
            const PlayerGames_docRef = doc(db, "PlayerGames", game.id);

            // 現在の時刻を取得して index 秒加算
            const now = Timestamp.now().toDate();
            const futureTimestamp = new Date(now.getTime() + index * 1000); // index 秒を加算
        
            const game_data = {
              editedAt: futureTimestamp, // カスタムタイムスタンプを設定
            };
        
            // バッチに更新操作を追加
            batch.update(PlayerGames_docRef, game_data);
          });

          // 一括コミット
          try {
            await batch.commit();
          } catch (error) {
            console.error("バッチ更新中にエラーが発生しました: ", error);
          }
      };
    //==============ID======================
    // const [open_2, setOpen_2] = React.useState(false);
    // const handleTooltipClose_2 = () => {
    //   setOpen_2(false);
    // };
  
    // const handleTooltipOpen_2 = () => {
    //   setOpen_2(true);
    // };


    //コピー
    // const copyToClipboard_2 = async (text) => {
    //   if (navigator.clipboard && navigator.clipboard.writeText) {
    //     // Clipboard APIを使用（モダンブラウザ用）
    //     navigator.clipboard.writeText(text).then(() => {
    //     }).catch(err => {
    //       console.error('Could not copy text: ', err);
    //     });
    //   } else {
    //     // フォールバック方法（古いブラウザやSafari用）
    //     const textArea = document.createElement("textarea");
    //     textArea.value = text;
    //     // スクロールを防ぐために必要
    //     textArea.style.position = "fixed";
    //     textArea.style.top = "0";
    //     textArea.style.left = "0";
    //     textArea.style.width = "2em";
    //     textArea.style.height = "2em";
    //     textArea.style.padding = "0";
    //     textArea.style.border = "none";
    //     textArea.style.outline = "none";
    //     textArea.style.boxShadow = "none";
    //     textArea.style.background = "transparent";
    
    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();
    
    //     try {
    //       const successful = document.execCommand('copy');
    //       if (successful) {
    //       } else {
    //         console.error('Fallback: Copy command was unsuccessful');
    //       }
    //     } catch (err) {
    //       console.error('Fallback: Oops, unable to copy', err);
    //     }
    
    //     document.body.removeChild(textArea);
    //   }
    //   handleTooltipOpen_2();
    // };
    //=====================================================


    useEffect(() => {
      const fetchData = async () => {
        // 自分
      if(pk === localuuid){
      }else{
        if(pk[0] === '@'){
          const userinfoQuery = query(collection(db, 'UserInfo'), where('UserID_lower', '==', pk.slice(1).toLowerCase()));
          const userinfoQuerySnapshot = await getDocs(userinfoQuery);
          if(userinfoQuerySnapshot){
            settruepk(userinfoQuerySnapshot.docs.map(doc => doc.data().uuid))
          }else{
            settruepk(false)
          }
        }else{

        // 自分以外
        setloading(true)
        //自分以外の時、その人のデータを取得＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
        //画像
        try{
          const imageUrl = await getDownloadURL(ref(storage, '/user_images/'+pk+'.jpg'));
          setanother_userImageUrl(imageUrl);
        } catch (error) {
          const imageUrl = await getDownloadURL(ref(storage, '/user_images/noimage.jpg'));
          setanother_userImageUrl(imageUrl);
        }
        //プロフィール
        const userinfoQuery = query(collection(db, 'UserInfo'), where('uuid', '==', pk));
  

        const userinfoQuerySnapshot = await getDocs(userinfoQuery);
        if (userinfoQuerySnapshot.empty) {
          setNouser_check(false)
        } else {
          setNouser_check(true)
          userinfoQuerySnapshot.forEach((doc) => {
          });
        }
        setanother_userinfo(userinfoQuerySnapshot.docs.map(doc => ({ ...doc.data() })));
        //SNS
        const snsQuery = query(collection(db, 'SNS'), where('uuid', '==', pk));
        const snsQuerySnapshot = await getDocs(snsQuery);
        setanother_sns(snsQuerySnapshot.docs.map(doc => ({ ...doc.data() }))[0]);
        //ゲーム情報
        const gamesQuery = query(collection(db, 'PlayerGames'), where('uuid', '==', pk), orderBy("editedAt"));
        const gamesQuerySnapshot = await getDocs(gamesQuery);
        console.log(gamesQuerySnapshot)
        setanother_PlayerGames(gamesQuerySnapshot.docs.map(doc => ({ ...doc.data() })));

        setloading(false)

      }}}
      fetchData();
    },[pk])
    // if(loading){
    //   return(
    //     <CircularProgress />
    //   )
    // }

    if(truepk){
      navigate(`/Profile/${truepk}`); // テンプレートリテラルで文字列を生成
    }



    // useEffect(() => {
    //   if(Nouser_check === null){
    //     return(
    //       <NewNotUser  
    //       userImageUrl={userImageUrl}/>
    //           )
    //   }
    // },[Nouser_check])




    
    //===================友達登録関係=======================
    const following_action = async(event) =>{
      event.preventDefault();
      //フォロー
      //フォロー側（相手に通知＆自分のリストに）
      if(friend_user.length >= 20){
        showNotification('フレンド上限に達しているため、この動作は無効になりました', 'error');
      }else{
        const followRef = doc(db, "Friends", localuuid);
        const followerRef = doc(db, "Friends", pk);
      
        try {
          await runTransaction(db, async (transaction) => {
            // followingリストにpkを追加
            transaction.update(followRef, {
              following: arrayUnion(pk)
            });
      
            // followerリストにlocaluuidを追加
            transaction.update(followerRef, {
              follower: arrayUnion(localuuid)
            });
          });
      
        } catch (e) {
          console.log("Transaction failed: ", e);
        }}
      }

    //フォロー解除
      const cancel_following = async(event) => {
        event.preventDefault();
        const followRef = doc(db, "Friends", localuuid);
        const followerRef = doc(db, "Friends", pk);
        try {
          await runTransaction(db, async (transaction) => {
            // followingリストからpkを削除
            transaction.update(followRef, {
              following: arrayRemove(pk)
            });
      
            // followerリストからlocaluuidを削除
            transaction.update(followerRef, {
              follower: arrayRemove(localuuid)
            });
          });
        } catch (error) {
          console.error('トランザクションの実行中にエラーが発生しました: ', error);
        }
        };
              //フレンドから削除＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const delete_friend = async(event) => {
        event.preventDefault();
        //フォロー側（相手に通知＆自分のリストに）
        const followRef = doc(db, "Friends", localuuid);
        const followerRef = doc(db, "Friends", pk);

        try {
          await runTransaction(db, async (transaction) => {
            // followRefの更新
            transaction.update(followRef, {
              friend: arrayRemove(pk),
              follower: arrayUnion(pk)
            });
      
            // followerRefの更新
            transaction.update(followerRef, {
              friend: arrayRemove(localuuid),
              following: arrayUnion(localuuid)
            });
      
          });
        } catch (error) {
          console.error('トランザクションの実行中にエラーが発生しました: ', error);
        }
      }

      const following_action_nofollower = async(event) =>{
        event.preventDefault();
        //フォロー
        //フォロー側（相手に通知＆自分のリストに）
          const followRef = doc(db, "Friends", localuuid);
          const followerRef = doc(db, "Friends", pk);
        
          try {
            await runTransaction(db, async (transaction) => {
              // followingリストにpkを追加
              transaction.update(followRef, {
                following: arrayUnion(pk)
              });
        
              // followerリストにlocaluuidを追加
              transaction.update(followerRef, {
                follower: arrayUnion(localuuid)
              });
            });
        
          } catch (e) {
            console.log("Transaction failed: ", e);
          }
        }

      //ブロックボタン＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const Block_button = () => {
        if(user){
          if (profile_block['blocking'].includes(pk)) {
            return(
              <button
              class="absolute top-4 right-4 px-4 py-2 bg-red-500 hover:bg-red-400 text-white rounded-lg flex items-center gap-2 transition-colors mr-32"
              onClick={cancel_blocking}
                    >
              {t('unblock')}
            </button>
            )
        }else{
            return(
              <button
              class="absolute top-4 right-4 px-4 py-2 bg-white/90 hover:bg-white text-gray-700 rounded-lg flex items-center gap-2 transition-colors mr-32" 
              onClick={blocking_action}
                    >
              {t('blocking')}
            </button>
            )}
        }else{
          return
        }
      }

      const blocking_action = async(event) =>{
        event.preventDefault();
        //フォロー
        //フォロー側（相手に通知＆自分のリストに）
        const blockRef = doc(db, "block", localuuid);
        const blockedRef = doc(db, "block", pk);
      
        try {
          await runTransaction(db, async (transaction) => {
            // followingリストにpkを追加
            transaction.update(blockRef, {
              blocking: arrayUnion(pk)
            });
      
            // followerリストにlocaluuidを追加
            transaction.update(blockedRef, {
              blocked: arrayUnion(localuuid)
            });
          });
      
        } catch (e) {
          console.log("Transaction failed: ", e);
        }}

            //フォロー解除
      const cancel_blocking = async(event) => {
        event.preventDefault();
        const blockRef = doc(db, "block", localuuid);
        const blockedRef = doc(db, "block", pk);
        try {
          await runTransaction(db, async (transaction) => {
            // followingリストからpkを削除
            transaction.update(blockRef, {
              blocking: arrayRemove(pk)
            });
      
            // followerリストからlocaluuidを削除
            transaction.update(blockedRef, {
              blocked: arrayRemove(localuuid)
            });
          });
        } catch (error) {
          console.error('トランザクションの実行中にエラーが発生しました: ', error);
        }
        };

      //フォローボタン表示＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const Followbutton = () =>{
        if(user){
          if (follower_user.includes(pk)) {
            return(
              <>
              {/* <div className='profile_followbutton'>
                <span onClick={following_action}>{t('do_follow')}</span>
              </div> */}
              <button
          className="absolute top-4 right-4 px-4 py-2 bg-white/90 hover:bg-white text-gray-700 rounded-lg flex items-center gap-2 transition-colors"
          onClick={following_action}
        >
          {/* <Edit className="w-4 h-4" /> */}
          {t('do_follow')}
        </button>
              </>
          );
          } else if (follow_user.includes(pk)) {
            return(
              // <div className='profile_followbutton'>
              // <span onClick={cancel_following}>{t('following_now')}</span>
              // </div>
                <button
                className="absolute top-4 right-4 px-4 py-2 bg-white/90 hover:bg-white text-gray-700 rounded-lg flex items-center gap-2 transition-colors"
                onClick={cancel_following}
              >
                {/* <Edit className="w-4 h-4" /> */}
                {t('following_now')}
              </button>
          );
          } else if (friend_user.includes(pk)) {
            return(
              <>
              {/* <div className='profile_followbutton'>
              <span onClick={delete_friend}>{t('cancel_follow')}</span>
              <p>{t('we_are_friend')}</p>
              </div> */}
              <button
                className="absolute top-4 right-4 px-4 py-2 bg-white/90 hover:bg-white text-gray-700 rounded-lg flex items-center gap-2 transition-colors"
                onClick={delete_friend}
              >
                {/* <Edit className="w-4 h-4" /> */}
                {t('cancel_follow')}
              </button>
              </>
          );
          }else{
            return(
              // <div className='profile_followbutton'>
              // <span onClick={following_action}>{t('do_follow')}</span>
              // </div>
              <button
              className="absolute top-4 right-4 px-4 py-2 bg-white/90 hover:bg-white text-gray-700 rounded-lg flex items-center gap-2 transition-colors"
              onClick={following_action_nofollower}
            >
              {/* <Edit className="w-4 h-4" /> */}
              {t('do_follow')}
            </button>
            )
          }
        }else{
          return ;
        }
}
//================= フォローかフレンドか==============================
const User_check = () =>{
  if(user){
    if (follower_user.includes(pk)) {
      return(
      <span
        style={{ backgroundColor: 'rgb(72, 163, 216)' }}
        className="inline-block px-3 py-1 text-sm font-medium text-white rounded-full mb-2"
      >
        {t('iam_followed')}
      </span>
    );
    } else if (follow_user.includes(pk)) {
      return(
        <span className="inline-block px-3 py-1 text-sm font-medium text-white bg-orange-500 rounded-full mb-2">
          {t('following_now')}
        </span>
    );
    } else if (friend_user.includes(pk)) {
      return(
<span
  style={{ backgroundColor: 'rgb(82, 201, 82)' }}
  className="inline-block px-3 py-1 text-sm font-medium text-white rounded-full mb-2"
>
  {t('we_are_friend')}
</span>

    );
    }else{
      return(
        <></>

      )
    }
  }else{
    return ;
  }
}

      //自己紹介欄
      const IntroductionComponent = ({ UserInfo }) => {
        const introduction = UserInfo.length > 0 ? UserInfo[0].introduction : '';
      
        // リンクにスタイルを適用する関数
        const customLinkDecorator = (decoratedHref, decoratedText, key) => (
          <a href={decoratedHref} key={key} style={{ color: '#4682B4'}}>
            {decoratedText}
          </a>
        );
      
        return (
          <Linkify componentDecorator={customLinkDecorator}>
            <p className="introduction">
              {formatTextWithLineBreaks(introduction)}
            </p>
          </Linkify>
        );
      };


      const Anotheruser_profile = () => {
        if(another_userinfo[0]?.hidden){
          if(friend_user.includes(pk)){
            return(
              <>
              {another_userinfo.map((user, index) => (
                <div key={index} className="bg-white rounded-lg shadow mb-6">
                  <div className="relative">
                    <div className="h-32 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-t-lg" />
                    <img
                      src={another_userImageUrl}
                      alt={user.Username}
                      className="absolute bottom-0 left-6 transform translate-y-1/2 w-24 h-24 rounded-full border-4 border-white object-cover"
                    />
                    <Followbutton/>
                    <Block_button />
        
                  </div>
                  <div className="pt-16 p-6">
                  <User_check/>
                    <h1 className="text-2xl font-bold text-gray-900">{user.Username}</h1>
                    <p className="text-gray-500">@{user.UserID}</p>
                    <IntroductionComponent UserInfo={another_userinfo} />
                  </div>
                  <div className='flex justify-center items-center pb-5'>
                    {/* <SNS_2 sns_list={another_sns} /> */}
                        <div className='sns' key={pk}>
                          {/* X-twitter */}
                          {another_sns.X === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faXTwitter} />
                          ) : (
                            <a target="_blank" href={`https://twitter.com/${another_sns.X}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon x-twitter' icon={faXTwitter} />
                            </a>
                          )}
                    
                          {another_sns.Discord === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faDiscord} />
                          ) : (
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('copyed')}
                              >
                                <span>
                                  <FontAwesomeIcon className='icon discord' onClick={() => copyToClipboard(another_sns.Discord)} icon={faDiscord} />
                                </span>
                              </Tooltip>
                            </ClickAwayListener>
                          )}
                    
                          {another_sns.Twitch === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faTwitch} />
                          ) : (
                            <a target="_blank" href={`https://www.twitch.tv/${another_sns.Twitch}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon twitch' icon={faTwitch} />
                            </a>
                          )}
                    
                          {another_sns.Youtube === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faYoutube} />
                          ) : (
                            <a target="_blank" href={`https://www.youtube.com/channel/${another_sns.Youtube}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon youtube' icon={faYoutube} />
                            </a>
                          )}
                    
                          {another_sns.Tiktok === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faTiktok} />
                          ) : (
                            <a target="_blank" href={`https://www.tiktok.com/@${another_sns.Tiktok}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon tiktok' icon={faTiktok} />
                            </a>
                          )}
                          {another_sns.Instagram === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faInstagram} />
                          ) : (
                            <a target="_blank" href={`https://www.instagram.com/${another_sns.Instagram}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon instagram' icon={faInstagram} />
                            </a>
                          )}
                        </div>
                  </div>
                </div>
              ))}
                  {/* ゲームIDリスト */}
                 <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">{t('gamelist')}</h2>
          <div className="space-y-4">
            {another_PlayerGames.map((game,index) => {
              const Icon = game.icon;
              const isExpanded = expandedGame === index;
  
              return (
                <div
                  key={game.gamename}
                  className="border border-gray-100 rounded-lg hover:border-gray-200 transition-colors"
                >
                  <div
                    className="flex items-center justify-between p-4 cursor-pointer"
                    onClick={() => toggleExpand(index)}
                  >
                    <div className="flex items-center">
                    <div className="flex items-center">
                    <img
                    className="gameimg w-10 h-10 rounded-[8px]"
                    alt=""
                      src={game.gameimgurl}
                    />
                      <div className="ml-4">
                        <h3 className="font-medium text-gray-900">{game.gamename}</h3>
                       </div>
                     </div>
                    </div>
                    <div className="flex items-center gap-2">
                  {game.igdb_url === '' ? (
                    <>
                    </>
                ) : (
                  <a
                  className='a'
                  onClick={(event) => {
                    event.stopPropagation(); // これを追加
                    handleClick(event, index);
                    setSelectedPost(another_PlayerGames[index])
                    setmenuDialogOpen(true)
                    
                  }}
                >
                  <Ellipsis />
                </a>
                )}
                      {isExpanded ? (
                        <ChevronUp className="w-5 h-5 text-gray-400" />
                      ) : (
                        <ChevronDown className="w-5 h-5 text-gray-400" />
                      )}
                    </div>
                  </div>
                  {isExpanded && (
                    <div className="px-4 pb-4 pt-0">
                      <div className="ml-12 space-y-2">
                        <div className="flex items-center justify-between">
                          <p className="text-sm text-gray-500">
                            {t('username')}: <span className="text-gray-700">{game.gameusername}</span>
                          </p>
                          <button
                            className="p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(game.gameusername, `username-${index}`);
                            }}
                          >
                            {copiedField === `username-${index}` ? (
                              <Check className="w-4 h-4 text-green-500" />
                            ) : (
                              <Copy className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-sm text-gray-500">
                            ID: <span className="text-gray-700">{game.gameuserid}</span>
                          </p>
                          <button
                            className="p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(game.gameuserid, `id-${index}`);
                            }}
                          >
                            {copiedField === `id-${index}` ? (
                              <Check className="w-4 h-4 text-green-500" />
                            ) : (
                              <Copy className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
              </>
            )

          }
          return(
            <>
            {another_userinfo.map((user, index) => (
                <>
                <div key={index} className="bg-white rounded-lg shadow mb-6">
                  <div className="relative">
                    <div className="h-32 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-t-lg" />
                    <img
                      src={another_userImageUrl}
                      alt={user.Username}
                      className="absolute bottom-0 left-6 transform translate-y-1/2 w-24 h-24 rounded-full border-4 border-white object-cover"
                    />
                    <Followbutton/>
                    <Block_button />
        
                  </div>
                  <div className="pt-16 p-6">
                  <User_check/>
                    <h1 className="text-2xl font-bold text-gray-900">{user.Username}</h1>
                    <p className="text-gray-500">@{user.UserID}</p>
                  </div>
                </div>
                <div className="bg-white px-6 py-12 rounded-b-lg shadow-sm text-center">
                  <h1 className="text-xl font-bold text-gray-900 mb-3">
                    @{user.UserID} {t('nopublick')}
                  </h1>
  
                  <p className="text-gray-600 mb-8 text-sm leading-relaxed">
                    {t('cant_watch')}
                    <br />
                    {t('be_friend')}
                  </p>

                  </div>
                </>
              ))}
            </>
          )
        }else{
          if(profile_block?.blocked?.includes(pk)){
            return(
              <>
              {another_userinfo.map((user, index) => (
                <>
                <div key={index} className="bg-white rounded-lg shadow mb-6">
                  <div className="relative">
                    <div className="h-32 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-t-lg" />
                    <img
                      src={another_userImageUrl}
                      alt={user.Username}
                      className="absolute bottom-0 left-6 transform translate-y-1/2 w-24 h-24 rounded-full border-4 border-white object-cover"
                    />
                    <Followbutton/>
                    <Block_button />
        
                  </div>
                  <div className="pt-16 p-6">
                  <User_check/>
                    <h1 className="text-2xl font-bold text-gray-900">{user.Username}</h1>
                    <p className="text-gray-500">@{user.UserID}</p>
                  </div>
                </div>
                <div className="bg-white px-6 py-12 rounded-b-lg shadow-sm text-center">
                  <h1 className="text-xl font-bold text-gray-900 mb-3">
                    @{user.UserID} {t('block_you')}
                  </h1>
  
                  <p className="text-gray-600 mb-8 text-sm leading-relaxed">
                    {t('cant_watch')}
                  </p>
                  </div>
                </>
              ))}
  
              </>
            )
          }else{
            return(
              <>
              {another_userinfo.map((user, index) => (
                <div key={index} className="bg-white rounded-lg shadow mb-6">
                  <div className="relative">
                    <div className="h-32 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-t-lg" />
                    <img
                      src={another_userImageUrl}
                      alt={user.Username}
                      className="absolute bottom-0 left-6 transform translate-y-1/2 w-24 h-24 rounded-full border-4 border-white object-cover"
                    />
                    <Followbutton/>
                    <Block_button />
        
                  </div>
                  <div className="pt-16 p-6">
                  <User_check/>
                    <h1 className="text-2xl font-bold text-gray-900">{user.Username}</h1>
                    <p className="text-gray-500">@{user.UserID}</p>
                    <IntroductionComponent UserInfo={another_userinfo} />
                  </div>
                  <div className='flex justify-center items-center pb-5'>
                    {/* <SNS_2 sns_list={another_sns} /> */}
                    <div className='sns' key={pk}>
                          {/* X-twitter */}
                          {another_sns.X === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faXTwitter} />
                          ) : (
                            <a target="_blank" href={`https://twitter.com/${another_sns.X}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon x-twitter' icon={faXTwitter} />
                            </a>
                          )}
                    
                          {another_sns.Discord === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faDiscord} />
                          ) : (
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('copyed')}
                              >
                                <span>
                                  <FontAwesomeIcon className='icon discord' onClick={() => copyToClipboard(another_sns.Discord)} icon={faDiscord} />
                                </span>
                              </Tooltip>
                            </ClickAwayListener>
                          )}
                    
                          {another_sns.Twitch === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faTwitch} />
                          ) : (
                            <a target="_blank" href={`https://www.twitch.tv/${another_sns.Twitch}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon twitch' icon={faTwitch} />
                            </a>
                          )}
                    
                          {another_sns.Youtube === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faYoutube} />
                          ) : (
                            <a target="_blank" href={`https://www.youtube.com/channel/${another_sns.Youtube}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon youtube' icon={faYoutube} />
                            </a>
                          )}
                    
                          {another_sns.Tiktok === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faTiktok} />
                          ) : (
                            <a target="_blank" href={`https://www.tiktok.com/@${another_sns.Tiktok}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon tiktok' icon={faTiktok} />
                            </a>
                          )}
                          {another_sns.Instagram === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faInstagram} />
                          ) : (
                            <a target="_blank" href={`https://www.instagram.com/${another_sns.Instagram}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon instagram' icon={faInstagram} />
                            </a>
                          )}
                          
                        </div>
                  </div>
                </div>
              ))}
                  {/* ゲームIDリスト */}
                 <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">{t('gamelist')}</h2>
          <div className="space-y-4">
            {another_PlayerGames.map((game,index) => {
              const Icon = game.icon;
              const isExpanded = expandedGame === index;
  
              return (
                <div
                  key={game.gamename}
                  className="border border-gray-100 rounded-lg hover:border-gray-200 transition-colors"
                >
                  <div
                    className="flex items-center justify-between p-4 cursor-pointer"
                    onClick={() => toggleExpand(index)}
                  >
                    <div className="flex items-center">
                    <div className="flex items-center">
                    <img
                    className="gameimg w-10 h-10 rounded-[8px]"
                    alt=""
                      src={game.gameimgurl}
                    />
                      <div className="ml-4">
                        <h3 className="font-medium text-gray-900">{game.gamename}</h3>
                       </div>
                     </div>
                    </div>
                    <div className="flex items-center gap-2">
                  {game.igdb_url === '' ? (
                    <>
                    </>
                ) : (
                  <a
                  className='a'
                  onClick={(event) => {
                    event.stopPropagation(); // これを追加
                    handleClick(event, index);
                    setSelectedPost(another_PlayerGames[index])
                    setmenuDialogOpen(true)
                    
                  }}
                >
                  <Ellipsis />
                </a>
                )}
                      {isExpanded ? (
                        <ChevronUp className="w-5 h-5 text-gray-400" />
                      ) : (
                        <ChevronDown className="w-5 h-5 text-gray-400" />
                      )}
                    </div>
                  </div>
                  {isExpanded && (
                    <div className="px-4 pb-4 pt-0">
                      <div className="ml-12 space-y-2">
                        <div className="flex items-center justify-between">
                          <p className="text-sm text-gray-500">
                            {t('username')}: <span className="text-gray-700">{game.gameusername}</span>
                          </p>
                          <button
                            className="p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(game.gameusername, `username-${index}`);
                            }}
                          >
                            {copiedField === `username-${index}` ? (
                              <Check className="w-4 h-4 text-green-500" />
                            ) : (
                              <Copy className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-sm text-gray-500">
                            ID: <span className="text-gray-700">{game.gameuserid}</span>
                          </p>
                          <button
                            className="p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(game.gameuserid, `id-${index}`);
                            }}
                          >
                            {copiedField === `id-${index}` ? (
                              <Check className="w-4 h-4 text-green-500" />
                            ) : (
                              <Copy className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
              </>
            )
          }
        }

        }
        


    //自分以外のプロフィール＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    if(pk !== localuuid){
      return(
        <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <main className={`flex-1 ${user ? 'md:ml-64' : ''} p-4 md:p-8 pb-20 md:pb-8`}>

      {loading ? (
          <div className="text-center py-12">
            {/* <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div> */}
            <CircularProgress />

            <p className="mt-4 text-gray-600">Loading...</p>

            {/* <Anotheruser_profile/> */}

          </div>
        ) :  (
          <>
        <div className="max-w-3xl mx-auto">
          {/* プロフィールセクション */}
          <Anotheruser_profile/>
        </div>
       {menuDialogOpen && selectedPost && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={closeDialog}
          >
            <div 
              className="bg-white rounded-lg p-4 w-80"
              onClick={e => e.stopPropagation()}
            >
              <div className="border-b pb-3 mb-4">
                <p className="text-sm text-gray-600">{selectedPost.gamename}</p>
              </div>
              <a
                  className="w-full flex items-center gap-2 px-4 py-2 text-left hover:bg-gray-100 rounded-lg"
                  href={selectedPost.igdb_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <a
                className="space-y-2"

              >
                IGDB.comで表示する
              </a>
              </a>
              <button
                onClick={closeDialog}
                className="mt-4 w-full px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        )}
        </>
        )}
  </main>
  </div>
        
      )
    }

  return (
        <div className="min-h-screen bg-gray-100">
      <Sidebar />
      <main class="flex-1 md:ml-64 p-4 md:p-8 pb-20 md:pb-8">
    <div className="max-w-3xl mx-auto">
    {/* 自分のプロフィールセクション */}
          {UserInfo.map((user, index) => (


    <div className="bg-white rounded-lg shadow mb-6">
      <div className="relative">
        <div className="h-32 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-t-lg" />
        <img
          src={userImageUrl}
          alt={user.Username}
          className="absolute bottom-0 left-6 transform translate-y-1/2 w-24 h-24 rounded-full border-4 border-white object-cover"
        />
                 <Editprofilebutton
                 className='z-10'
                     imgurl={userImageUrl}
                     username={user.Username}
                     userid={user.UserID}
                     introduction={user.introduction}
                     sns_list={profile_sns}
                     handleImageChange={handleImageChange}  
                   />
      </div>
      <div className="pt-16 p-6">
        <h1 className="text-2xl font-bold text-gray-900">{user.Username}</h1>
        <p className="text-gray-500">@{user.UserID}</p>
        {/* <p className="mt-4 text-gray-700">{user.introduction}</p> */}
        <IntroductionComponent UserInfo={UserInfo} />
      </div>
      <div className='flex justify-center items-center pb-5'>
             {/* <SNS_2
           sns_list={profile_sns}
         /> */}
                                 <div className='sns' key={pk}>
                          {/* X-twitter */}
                          {profile_sns.X === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faXTwitter} />
                          ) : (
                            <a target="_blank" href={`https://twitter.com/${profile_sns.X}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon x-twitter' icon={faXTwitter} />
                            </a>
                          )}
                    
                          {profile_sns.Discord === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faDiscord} />
                          ) : (
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('copyed')}
                              >
                                <span>
                                  <FontAwesomeIcon className='icon discord' onClick={() => copyToClipboard(profile_sns.Discord)} icon={faDiscord} />
                                </span>
                              </Tooltip>
                            </ClickAwayListener>
                          )}
                    
                          {profile_sns.Twitch === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faTwitch} />
                          ) : (
                            <a target="_blank" href={`https://www.twitch.tv/${profile_sns.Twitch}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon twitch' icon={faTwitch} />
                            </a>
                          )}
                    
                          {profile_sns.Youtube === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faYoutube} />
                          ) : (
                            <a target="_blank" href={`https://www.youtube.com/channel/${profile_sns.Youtube}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon youtube' icon={faYoutube} />
                            </a>
                          )}
                    
                          {profile_sns.Tiktok === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faTiktok} />
                          ) : (
                            <a target="_blank" href={`https://www.tiktok.com/@${profile_sns.Tiktok}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon tiktok' icon={faTiktok} />
                            </a>
                          )}

                          {profile_sns.Instagram === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faInstagram} />
                          ) : (
                            <a target="_blank" href={`https://www.instagram.com/${profile_sns.Instagram}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon instagram' icon={faInstagram} />
                            </a>
                          )}

                          {/* {profile_sns.facebook === '' ? (
                            <FontAwesomeIcon className='icon gray' icon={faFacebook} />
                          ) : (
                            <a target="_blank" href={`https://www.instagram.com/${profile_sns.facebook}`} rel="noopener noreferrer">
                              <FontAwesomeIcon className='icon facebook' icon={faFacebook} />
                            </a>
                          )} */}
                        </div>
    </div>
    </div>
           ))}


    {/* ゲームIDリスト */}
    <div className="bg-white rounded-lg shadow">
      <div className="p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">{t('gamelist')}</h2>
        <Registbutton className='regist_button' suggest={suggest}/>
        {PlayerGames.length > 0 && (
            <button
              onClick={handleOpenDialog}
              className="mt-4 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 flex items-center gap-2"
            >
              <GripVertical size={20} />
              {t('sort')}
            </button>
          )}
        {PlayerGames.length === 0 && (
            <div className="text-center py-12 bg-white rounded-xl shadow-sm">
            <div className="text-gray-400 mb-4">
              <Gamepad2 className="h-12 w-12 mx-auto" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900">ゲームを登録してみましょう</h2>
            <p className="mt-2 text-gray-600">
            </p>
          </div>
          )}

        <div className="space-y-4">
          {PlayerGames.map((game,index) => {
            const Icon = game.icon;
            const isExpanded = expandedGame === index;

            return (
              <div
                key={game.gamename}
                className="border border-gray-100 rounded-lg hover:border-gray-200 transition-colors"
              >
                <div
                  className="flex items-center justify-between p-4 cursor-pointer"
                  onClick={() => toggleExpand(index)}
                >
                  <div className="flex items-center">
                  <div className="flex items-center">
                  <img
                    className="gameimg w-10 h-10 rounded-[8px]"
                    alt=""
                    src={game.gameimgurl}
                  />
                    <div className="ml-4">
                      <h3 className="font-medium text-gray-900">{game.gamename}</h3>
                     </div>
                   </div>
                  </div>
                  <div className="flex items-center gap-2">
                  <a
            className='a'
            // id={`button-${index}`}
            // aria-controls={openIndex === index ? `menu-${index}` : undefined}
            // aria-haspopup="true"
            // aria-expanded={openIndex === index ? 'true' : undefined}
            onClick={(event) => {
              event.stopPropagation(); // これを追加
              handleClick(event, index);
              setSelectedPost(PlayerGames[index])
              setmenuDialogOpen(true)
              
            }}
          >
            <Ellipsis />
          </a>
                    {isExpanded ? (
                      <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                  </div>
                </div>
                {isExpanded && (
                  <div className="px-4 pb-4 pt-0">
                    <div className="ml-12 space-y-2">
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-500">
                          {t('username')}： <span className="text-gray-700">{game.gameusername}</span>
                        </p>
                        <button
                          className="p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(game.gameusername, `username-${index}`);
                          }}
                        >
                          {copiedField === `username-${index}` ? (
                            <Check className="w-4 h-4 text-green-500" />
                          ) : (
                            <Copy className="w-4 h-4" />
                          )}
                        </button>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-500">
                          ID： <span className="text-gray-700">{game.gameuserid}</span>
                        </p>
                        <button
                          className="p-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(game.gameuserid, `id-${index}`);
                          }}
                        >
                          {copiedField === `id-${index}` ? (
                            <Check className="w-4 h-4 text-green-500" />
                          ) : (
                            <Copy className="w-4 h-4" />
                          )}
                        </button>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-500">
                          {t('memo')}： <span className="text-gray-700">{game.memo}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
  </main>
  {isDialogOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[85vh] overflow-y-auto">
                <h2 className="text-xl font-semibold mb-4">{t('sort')}</h2>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={tempTodos}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="space-y-2">
                      {tempTodos.map((todo) => (
                        <SortableItem key={todo.id} id={todo.id}>
                          <div className="p-3 bg-gray-50 rounded-lg flex items-center gap-2">
                            <GripVertical className="text-gray-400" size={20} />
                            <img
                              className="gameimg w-10 h-10 rounded-[8px]"
                              alt=""
                              src={todo.gameimgurl}
                            />
                            <span>{todo.gamename}</span>
                          </div>
                        </SortableItem>
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
                <div className="mt-4 flex gap-2">
                  <button
                    onClick={() => handleCloseDialog(false)}
                    className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    onClick={handleUpdate}
                    className="flex-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    {t('save')}
                  </button>
                </div>
              </div>
            </div>
          )}
{/* メニュー（編集・削除） */}
{menuDialogOpen && selectedPost && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={closeDialog}
          >
            <div 
              className="bg-white rounded-lg p-4 w-80"
              onClick={e => e.stopPropagation()}
            >
              <div className="border-b pb-3 mb-4">
                <p className="text-sm text-gray-600">{selectedPost.gamename}</p>
              </div>
              <div className="space-y-2">
              <Editbutton
              onClick={closeDialog}
                    document_id={selectedPost.id}
                    uid={localuuid}
                    profile_gamename={selectedPost.gamename}
                    profile_gameusername={selectedPost.gameusername}
                    profile_gameuserid={selectedPost.gameuserid}
                    gameimg={selectedPost.gameimgurl}
                    memo={selectedPost.memo}
                    closeDialog={closeDialog}
                  />
               <Deletegamebutton
                onClick={closeDialog}
                 uid={localuuid}
                 profile_gamename={selectedPost.gamename}
                 profile_gameusername={selectedPost.gameusername}
                 profile_gameuserid={selectedPost.gameuserid}
                 gameimg={selectedPost.gameimgurl}
                 memo={selectedPost.memo}
                 closeDialog={closeDialog}

               />

              {selectedPost.igdb_url === null ? (
                  <>
                  </>
              ) : (
                <a
                  className="w-full flex items-center gap-2 px-4 py-2 text-left hover:bg-gray-100 rounded-lg"
                  href={selectedPost.igdb_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                <a
                className="space-y-2"
              >
                IGDB.com
              </a>
              </a>
              )}

              </div>
              <button
                onClick={closeDialog}
                className="mt-4 w-full px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        )}
  </div>

  );
}

export default NewProfile