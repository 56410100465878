import React from 'react'
import rogo from '../IDnest.jpeg'
import '../Newcss/Riyoukiyaku.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Languages } from 'lucide-react';


function Privacypolicy() {
    const { t,i18n } = useTranslation();
    const toggleLanguage = () => {
      const newLanguage = i18n.language === 'en' ? 'ja' : 'en'
      i18n.changeLanguage(newLanguage)
      localStorage.setItem('language', newLanguage)
    }

    const language = localStorage.getItem('language')

  return (
    <>
        {language === 'ja' ? (
                <div className="min-h-screen bg-gray-50">
                {/* ヘッダー */}
                <header className="bg-white shadow-sm">
                  <div className="max-w-4xl mx-auto px-4 py-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <Link to="/">
                          <img className="profile_rogo" src={rogo} alt="ロゴ" />
                        </Link>
                        <h1 className="text-2xl font-bold text-gray-900">プライバシーポリシー</h1>
                      </div>
                      <button
                        onClick={toggleLanguage}
                        className="px-4 py-2 rounded-lg text-sm flex items-center gap-2 bg-white text-gray-700 hover:bg-gray-50 transition-all shadow-sm"
                        aria-label="Toggle language"
                      >
                        <Languages size={20} />
                        {language === 'en' ? '日本語' : 'English'}
                      </button>
                    </div>
                  </div>
                </header>
          
                {/* メインコンテンツ */}
                <main className="max-w-4xl mx-auto px-4 py-8">
                  <div className="bg-white rounded-lg shadow-md p-8">
                    <div className="space-y-8">
                      <section>
                        <p className="text-gray-700 leading-relaxed">
                          IDNectをご利用いただきありがとうございます。本プライバシーポリシーは、当アプリケーション（以下、「当アプリ」）がユーザーから収集する情報、情報の利用方法、および情報の共有について説明するものです。当アプリを利用することで、本ポリシーに同意されたものとみなします。
                        </p>
                      </section>
          
                      <section>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">1. 収集する情報</h2>
                        <div className="space-y-4">
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">1.1 直接提供される情報</h3>
                            <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                              <li>氏名、メールアドレス、などの個人情報。グーグルアカウントでアカウントを作成したときのみ氏名を取得します。</li>
                              <li>ユーザーがアカウント作成時に提供するその他の詳細</li>
                            </ul>
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">1.2 自動収集情報</h3>
                            <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                              <li>デバイス情報（デバイスの種類、識別子、オペレーティングシステムのバージョン、設定等）</li>
                              <li>ログ情報（IPアドレス、ブラウザの種類と設定、言語設定、接続日時等）</li>
                              {/* <li>位置情報（GPS情報、Wi-Fiアクセスポイントや基地局の情報等）</li> */}
                              <li>使用状況データ（クリック、ページアクセス、滞在時間等）</li>
                            </ul>
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">1.3 第三者からの情報</h3>
                            <p className="text-gray-700 leading-relaxed">
                              ソーシャルメディアやその他の第三者サービスを通じて提供される情報
                            </p>
                          </div>
                        </div>
                      </section>
          
                      <section>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">2. 情報の利用方法</h2>
                        <p className="text-gray-700 leading-relaxed mb-2">当アプリは収集した情報を以下の目的で利用します：</p>
                        <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                          <li>サービスの提供、運営および改善</li>
                          <li>カスタマーサポートの提供</li>
                          <li>ユーザーエクスペリエンスのパーソナライゼーション</li>
                          <li>マーケティングおよびプロモーション活動</li>
                          <li>データ分析および研究</li>
                        </ul>
                      </section>
          
                      <section>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">3. 情報の共有</h2>
                        <p className="text-gray-700 leading-relaxed mb-4">
                          当アプリは、収集した情報を以下の第三者と共有する場合があります：
                        </p>
                        <div className="space-y-4">
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">3.1 サービスプロバイダー</h3>
                            <p className="text-gray-700 leading-relaxed">
                              サービスの運営や改善を目的として、協力会社やパートナー企業に情報を提供します。
                            </p>
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">3.2 ビジネスパートナー</h3>
                            <p className="text-gray-700 leading-relaxed">
                              マーケティングや広告のために情報を共有します。
                            </p>
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">3.3 法的要求</h3>
                            <p className="text-gray-700 leading-relaxed">
                              法律および規制の要件を満たすために、政府機関や法執行機関に情報を提供する場合があります。
                            </p>
                          </div>
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">3.4 その他</h3>
                            <p className="text-gray-700 leading-relaxed">
                              合併、買収、資産売却、破産、その他の事業再編の際に情報を共有することがあります。
                            </p>
                          </div>
                        </div>
                      </section>
          
                      <section>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">4. データの安全性</h2>
                        <p className="text-gray-700 leading-relaxed">
                          当アプリは、収集した情報を保護するための技術的および組織的な措置を講じていますが、完全な安全性を保証することはできません。情報の提供は自己責任で行ってください。
                        </p>
                      </section>
          
                      <section>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">5. プライバシーポリシーの更新</h2>
                        <p className="text-gray-700 leading-relaxed">
                          当アプリは、必要に応じて本プライバシーポリシーを変更することがあります。変更後のポリシーは、当アプリ上に掲載された時点で効力を発生します。定期的にこのページを確認し、最新の情報をご確認ください。
                        </p>
                      </section>
          
                      <section>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">6. お問い合わせ</h2>
                        <p className="text-gray-700 leading-relaxed">
                          プライバシーポリシーについてご不明点がある場合は、以下の連絡先までご連絡ください：
                        </p>
                        <p className="text-gray-700 leading-relaxed mt-2">
                          <a href="mailto:kosukesh34@gmail.com" className="text-blue-600 hover:underline">
                            Eメール：kosukesh34@gmail.com
                          </a>
                        </p>
                      </section>
          
                      <p className="text-gray-700 leading-relaxed">
                        このプライバシーポリシーは、IDNectに適用されるものです。しかし、ユーザーのプライバシーの保護には万全を期すべきと考えており、常に改善に努めていきます。
                      </p>
                    </div>
          
                    {/* 最終更新日 */}
                    <div className="mt-12 pt-8 border-t border-gray-200">
                      <p className="text-sm text-gray-500 text-right">
                        最終更新日: 2024年6月5日
                      </p>
                    </div>
                  </div>
                </main>
              </div>

    ) : (
      <div className="min-h-screen bg-gray-50">
      {/* Header */}
      {/* <header className="bg-white shadow-sm">
        <div className="max-w-4xl mx-auto px-4 py-6">
          <div className="flex items-center gap-3">
          <Link to="/"><img className="profile_rogo" src={rogo} /></Link>
          <h1 className="text-2xl font-bold text-gray-900">Privacy Policy</h1>
          </div>
          <button
            onClick={toggleLanguage}
            className="px-4 py-2 rounded-lg text-sm flex items-center gap-2 bg-white text-gray-700 hover:bg-gray-50 transition-all shadow-sm"
            aria-label="Toggle language"
          >
            <Languages size={20} />
            {language === 'en' ? '日本語' : 'English'}
          </button>
        </div>
      </header> */}
                      <header className="bg-white shadow-sm">
                  <div className="max-w-4xl mx-auto px-4 py-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-3">
                        <Link to="/">
                          <img className="profile_rogo" src={rogo} alt="ロゴ" />
                        </Link>
                        <h1 className="text-2xl font-bold text-gray-900">Privacy Policy</h1>
                      </div>
                      <button
                        onClick={toggleLanguage}
                        className="px-4 py-2 rounded-lg text-sm flex items-center gap-2 bg-white text-gray-700 hover:bg-gray-50 transition-all shadow-sm"
                        aria-label="Toggle language"
                      >
                        <Languages size={20} />
                        {language === 'en' ? '日本語' : 'English'}
                      </button>
                    </div>
                  </div>
                </header>
  
      {/* Main Content */}
      <main className="max-w-4xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="space-y-8">
            <section>
              <p className="text-gray-700 leading-relaxed">
                Thank you for using IDNect. This Privacy Policy explains the information that our application ("the App") collects from users, how that information is used, and how it is shared. By using the App, you agree to this policy.
              </p>
            </section>
  
            <section>
              <h2 className="text-xl font-bold text-gray-900 mb-4">1. Information We Collect</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">1.1 Information You Provide Directly</h3>
                  <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                    <li>Personal information such as name, email address, phone number, and address</li>
                    <li>Details provided during account creation</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">1.2 Information Collected Automatically</h3>
                  <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                    <li>Device information (type, identifiers, OS version, settings, etc.)</li>
                    <li>Log information (IP address, browser type, settings, language preferences, connection timestamps, etc.)</li>
                    <li>Location information (GPS data, Wi-Fi access points, cellular tower information, etc.)</li>
                    <li>Usage data (clicks, page access, time spent, etc.)</li>
                  </ul>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">1.3 Information from Third Parties</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Information provided through social media or other third-party services
                  </p>
                </div>
              </div>
            </section>
  
            <section>
              <h2 className="text-xl font-bold text-gray-900 mb-4">2. How We Use Information</h2>
              <p className="text-gray-700 leading-relaxed mb-2">The App uses collected information for the following purposes:</p>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2 ml-4">
                <li>To provide, operate, and improve our services</li>
                <li>To provide customer support</li>
                <li>To personalize the user experience</li>
                <li>For marketing and promotional activities</li>
                <li>For data analysis and research</li>
              </ul>
            </section>
  
            <section>
              <h2 className="text-xl font-bold text-gray-900 mb-4">3. Sharing Information</h2>
              <p className="text-gray-700 leading-relaxed mb-4">
                The App may share collected information with the following third parties:
              </p>
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">3.1 Service Providers</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Information is shared with partner companies and collaborators to operate and improve services.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">3.2 Business Partners</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Information is shared for marketing and advertising purposes.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">3.3 Legal Requirements</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Information may be disclosed to government or law enforcement agencies to comply with legal or regulatory requirements.
                  </p>
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">3.4 Others</h3>
                  <p className="text-gray-700 leading-relaxed">
                    Information may be shared during mergers, acquisitions, asset sales, bankruptcies, or other corporate reorganizations.
                  </p>
                </div>
              </div>
            </section>
  
            <section>
              <h2 className="text-xl font-bold text-gray-900 mb-4">4. Data Security</h2>
              <p className="text-gray-700 leading-relaxed">
                The App takes technical and organizational measures to protect collected information. However, complete security cannot be guaranteed. Please provide information at your own risk.
              </p>
            </section>
  
            <section>
              <h2 className="text-xl font-bold text-gray-900 mb-4">5. Updates to the Privacy Policy</h2>
              <p className="text-gray-700 leading-relaxed">
                The App may update this Privacy Policy as necessary. Changes will take effect when posted on the App. Please review this page regularly to stay informed of updates.
              </p>
            </section>
  
            <section>
              <h2 className="text-xl font-bold text-gray-900 mb-4">6. Contact</h2>
              <p className="text-gray-700 leading-relaxed">
                If you have questions about this Privacy Policy, please contact us at:
              </p>
              <p className="text-gray-700 leading-relaxed mt-2">
                <a href="mailto:kosukesh34@gmail.com" className="text-blue-600 hover:underline">
                  Email: kosukesh34@gmail.com
                </a>
              </p>
            </section>
  
            <p className="text-gray-700 leading-relaxed">
              This Privacy Policy applies to IDNect. We are committed to continuously improving our practices to protect user privacy.
            </p>
          </div>
  
          {/* Last Updated */}
          <div className="mt-12 pt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500 text-right">
              Last Updated: June 5, 2024
            </p>
          </div>
        </div>
      </main>
    </div>
    )}
    </>


  );
}

export default Privacypolicy
