import { initializeApp } from "firebase/app";
import {GoogleAuthProvider, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import { getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { Navigate } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";

// const functions = require("firebase-functions");

// exports.seacret = functions
//   .runWith({ secrets: ["IDNECT_SECRET"] }) // シークレットを指定
//   .https.onRequest((req, res) => {
//     const secretValue = process.env.SECRET_NAME; // シークレットを取得
//     console.log(res.env)
//     res.send(`Your secret is: ${secretValue}`);
//   });



// const firebaseConfig = {
//   apiKey: "AIzaSyAgqsa6hKh--DZ9PyopolfZKAkcMu8qCzw",
//   authDomain: "fir-trial2-425ff.firebaseapp.com",
//   databaseURL: "https://fir-trial2-425ff-default-rtdb.firebaseio.com",
//   projectId: "fir-trial2-425ff",
//   storageBucket: "fir-trial2-425ff.appspot.com",
//   messagingSenderId: "196827584264",
//   appId: "1:196827584264:web:a25faae1c34185b02b7ebd",
//   measurementId: "G-1PG8VGEZB4"
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyCNwHNjtoNwCtSWs4CXya_LAIlpR5sjWvc",
//   authDomain: "idnect.com",
//   databaseURL: "https://idnect-com-default-rtdb.firebaseio.com",
//   projectId: "idnect-com",
//   storageBucket: "idnect-com.firebasestorage.app",
//   messagingSenderId: "743508604873",
//   appId: "1:743508604873:web:434a7f1eb9ddc8814d9a18",
//   measurementId: "G-QGTX7H9WG0"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

// const firebaseConfig = {
//   apiKey: "AIzaSyCylTqvZXj5JewUHn38aPSdWr6S5ZMFyV0",
//   authDomain: "idnect--com.firebaseapp.com",
//   databaseURL: "https://idnect--com-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "idnect--com",
//   storageBucket: "idnect--com.firebasestorage.app",
//   messagingSenderId: "998786812231",
//   appId: "1:998786812231:web:4b573798aed3f84bf36462"
// };


// 初期化やら各種設定
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();
const storageRef = ref(storage);
const provider = new GoogleAuthProvider();
provider.addScope("email");

//メールでの新規登録

//メールでのログイン
const SigninWithEmailAndPassword = async(email,password) => {
    const user = await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem('login',user.user.uid)
    alert('ログイン成功');
    window.location.reload();
};
export {firebaseConfig,app,auth,provider,db,storageRef,storage,SigninWithEmailAndPassword};

export const firebase_Signout = async() => {
  await signOut();
  };

//ログアウト
export function SignOutButton(){
    return (
        <button className='Logout' onClick={() => auth.signOut()}>
            <p>サインアウト</p>
        </button>
    )
  }