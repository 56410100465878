import { GamepadIcon } from 'lucide-react';
import rogo from '../../../img/IDnest.jpeg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Footer() {
    const { t,i18n } = useTranslation();
  
  return (
    <div className=" bg-gray-100 flex flex-col">
      {/* Main content would go here */}
      <footer className="bg-white border-t">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
            {/* Logo and Brand */}
            <div className="flex items-center space-x-2">
              <Link 
                to="/" 
                className="flex items-center space-x-2 text-gray-900 hover:text-gray-700 transition-colors"
              >
                        <img className='profile_rogo' src={rogo} alt="IDNect logo" />

                <span className="font-bold text-lg">IDNect</span>
              </Link>
            </div>

            {/* Links */}
            <div className="flex items-center space-x-6">
              <Link 
                to="/terms" 
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                利用規約
              </Link>
              <Link 
                to="/pp" 
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                プライバシーポリシー
              </Link>
            </div>

            {/* Copyright */}
            <div className="text-sm text-gray-600">
              © {new Date().getFullYear()} IDNect. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}